package com.crowpay.views.dialogs

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.SignalingList
import com.crowpay.views.components.sectionIndentCol
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.bind
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.lens
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.compact
import com.lightningkite.kiteui.views.dialog
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.MassModification
import com.lightningkite.lightningdb.condition
import com.lightningkite.lightningdb.inside
import com.lightningkite.lightningdb.modification
import com.lightningkite.now

class StartWorkItemsDialog(
    val project: Project,
    val items: List<LineItem>,
    val scopes: List<ScopeView>
): Screen {
    private sealed interface StartOption : HasDisplayName {
        val lineIds: List<UUID>
        data class Item(val lineItem: LineItem) : StartOption {
            override val displayName: String get() = lineItem.name
            override val lineIds: List<UUID> get() = listOf(lineItem._id)
        }
        data class Scope(
            val scopeSet: ScopeView,
            override val lineIds: List<UUID>
        ) : StartOption {
            override val displayName: String get() = scopeSet.scopeTitle
        }
    }

    private val options: List<StartOption> = run {
        val unusedScopes = scopes.associateByTo(HashMap()) { it._id }
        val options = ArrayList<StartOption>()

        for (item in items) {
            val scopeId = item.scopeView

            if (scopeId != null && item.scopeSet == true) {
                unusedScopes.remove(scopeId)?.let { scope ->
                    options.add(
                        StartOption.Scope(
                            scope,
                            items.filter { it.scopeView == scopeId }.map { it._id }
                        )
                    )
                }
            }
            else {
                options.add(StartOption.Item(item))
            }
        }

        options
    }

    private val selected = SignalingList(options)

    override fun ViewWriter.render() {
        dismissBackground {
            centered - dialog - stack {
                spacing = AppDimensions.fullIndent
                col {
                    title("Start Work Items?")
//                    body("Insert starting work item text here")

                    lightSection - sizeConstraints(minHeight = 10.rem, maxHeight = 50.rem, width = 35.rem) - stack {
                        spacing = AppDimensions.sectionIndent

                        scrolls - col {
                            spacing = 0.5.rem
                            for (option in options) {
                                row {
                                    spacing = 0.5.rem
                                    checkbox {
                                        checked bind selected.containsWritable(option)
                                    }
                                    if (option is StartOption.Scope) {
                                        val linesInScope = items.filter { it.scopeView == option.scopeSet._id }
                                        compact - col {
                                            body("${option.displayName} (${option.lineIds.size} items)")
                                            for (line in linesInScope) {
                                                body("- ${line.name}")
                                            }
                                        }
                                    }
                                    else {
                                        centered - body(option.displayName)
                                    }
                                }
                            }
                        }
                    }

                    row {
                        primaryButton - button {
                            specCenteredText("Start Work")
                            onClick {
                                val session = notNullSession()

                                session.lineItems.bulkModify(
                                    MassModification(
                                        condition { item -> item._id inside selected.flatMap { it.lineIds } },
                                        modification { it.started assign now() }
                                    )
                                )
//                                session.nonCached.project.forceRefreshProjectState(project._id)
//                                delay(500)
                                session.projects.totallyInvalidate()
                                dialogScreenNavigator.dismiss()
                            }
                        }
                        textButton - button {
                            specCenteredText("Go Back")
                            onClick {
                                dialogScreenNavigator.dismiss()
                            }
                        }
                    }
                }
            }
        }
    }
}