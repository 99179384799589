package com.crowpay.views.components.project.work.scope

import com.crowpay.LineItem
import com.crowpay.ScopeViewInterface
import com.crowpay.actuals.AppDimensions
import com.crowpay.threeGears
import com.crowpay.utils.AdjustedLineItem
import com.crowpay.utils.renderDollars
import com.crowpay.views.components.expandAction
import com.crowpay.views.components.expandIcon
import com.crowpay.views.components.renderPriceChange
import com.crowpay.views.components.scopeSetIcon
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon

private const val nameWeight = 5f
private const val originalWeight = 1.5f
private const val changeWeight = 1.5f
private const val statusWeight = 1.7f
private const val totalWeight = 1.5f

fun ViewWriter.workItemTableHeader(showNameHeader: Boolean) {
    sizeConstraints(height = 3.rem) - row {
        spacing = AppDimensions.buttonSpacing
        if (showNameHeader)
            weight(nameWeight) - centered - bodyBold("Work Item")
        else
            weight(nameWeight) - space()

        weight(originalWeight) - centered - bodyBold {
            align = Align.Center
            content = "Original"
        }
        weight(changeWeight) - centered - bodyBold {
            align = Align.Center
            content = "Change"
        }
        weight(statusWeight) - centered - bodyBold {
            align = Align.Center
            content = "Status"
        }
        weight(totalWeight) - centered - bodyBold {
            align = Align.Center
            content = "Total"
        }
    }
}

fun ViewWriter.workItemTableRow(
    expanded: Property<Boolean>,
    item: Readable<AdjustedLineItem>,
    topLevel: Boolean = true
): RView {
    expandButtonTheme
    return button {
        spacing = 0.px
//        if (topLevel) dynamicTheme {
//            if (expanded()) DarkBackground else null
//        }

        sizeConstraints(height = 3.rem) - row {
            spacing = AppDimensions.buttonSpacing
            weight(nameWeight) - row {
                spacing = AppDimensions.expandButtonSpace
                centered - expandIcon(expanded)
                centered - body {
                    dynamicTheme { if (item().cancelled) StrikeThroughSemantic else null }
                    ::content { item().name }
                }
                centered - smallBody { ::content { item().changeType?.postTense ?: "" } }
            }
            weight(originalWeight) - centered - body {
                align = Align.Center
                ::content { item().wraps.originalPrice.renderDollars() }
            }
            weight(changeWeight) - stack {
                centered - renderPriceChange { item().totalPriceChange }
            }
            weight(statusWeight) - centered - body {
                fun AdjustedLineItem.status() = if (scopeSet == true) null else state
                dynamicTheme { item().status()?.let(::LineItemStateSemantic) }
                align = Align.Center
                ::content { item().status()?.displayName ?: "" }
            }
            weight(totalWeight) - centered - body {
                align = Align.Center
                ::content { item().price.renderDollars() }
            }
        }
        action = expandAction(expanded)
    }
}

fun ViewWriter.workItemTableSummary(
    items: Readable<List<AdjustedLineItem>>,
    scopeView: Readable<ScopeViewInterface>,
) =
    sizeConstraints(height = 3.5.rem) - row {
        spacing = AppDimensions.buttonSpacing
        weight(nameWeight) - compact - row {
            centered - body {
                ::content {
                    if (scopeView().scopeSet) "Work Set Summary"
                    else "Work View Summary"
                }
            }
            colored(AppColors.secondary.main) - centered - scopeSetIcon {
                ::exists { scopeView().scopeSet }
            }
        }
        weight(originalWeight) - centered - body {
            align = Align.Center
            ::content { items().sumOf { it.wraps.originalPrice }.renderDollars() }
        }
        weight(changeWeight) - stack {
            centered - renderPriceChange { items().sumOf { it.totalPriceChange } }
        }
        weight(statusWeight) - centered - body {
            val status = shared {
                if (scopeView().scopeSet) items().firstOrNull()?.state
                else null
            }
            dynamicTheme { status()?.let(::LineItemStateSemantic) }
            align = Align.Center
            ::content { status()?.displayName ?: "" }
        }
        weight(totalWeight) - centered - body {
            align = Align.Center
            ::content { items().sumOf { it.price }.renderDollars() }
        }
    }
