package com.crowpay.views.components.project.work.payApps

import com.crowpay.LineItemInterface
import com.crowpay.PayAppItem
import com.crowpay.utils.AdjustedLineItem
import com.crowpay.views.theming.AppColors
import com.lightningkite.kiteui.models.Color
import kotlin.math.roundToLong

enum class PayAppStatus(val displayName: String, val color: Color) {
    Draft("Draft", AppColors.grey.main),
    Pending("Submitted", AppColors.primary.main),
    Approved("Paid", AppColors.secondary.main),
    Denied("Wait", AppColors.red.main),
    Voided("Voided", AppColors.red.main)
}

val PayAppItem.status: PayAppStatus get() = when {
    voided != null -> PayAppStatus.Voided
    denied != null -> PayAppStatus.Denied
    approved != null -> PayAppStatus.Approved
    submitted == null -> PayAppStatus.Draft
    else -> PayAppStatus.Pending
}

// Combines all the payment info for a line item, but also "rewinds" the line item and modifications to a certain instant
data class LineItemWithPaymentInfo(
    val lineItem: AdjustedLineItem,
    val payApp: PayAppItem?,
    val activePayApps: List<PayAppItem>,
) : LineItemInterface by lineItem {
    val previousPayments = activePayApps.sumOf { it.amount }
    val thisPayment = payApp?.amount
    val workCompleted = previousPayments + (thisPayment ?: 0)

    val percentComplete =
        if (price > 0) workCompleted.times(100)/price
        else 0

    fun retentionToDate(retention: Float) = (workCompleted * retention).roundToLong()
    fun contractorPaymentsToDate(retention: Float) = workCompleted - retentionToDate(retention)
}

fun List<LineItemWithPaymentInfo>.percentComplete(): Long {
    val total = sumOf { it.price }
    return if (total > 0) sumOf { it.workCompleted }.times(100) / total
    else 0
}
fun List<LineItemWithPaymentInfo>.retentionToDate(retention: Float): Long = sumOf { it.retentionToDate(retention) }

