package com.crowpay.utils

import com.lightningkite.UUID
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.reactive.Listenable
import com.lightningkite.kiteui.reactive.invokeAllSafe
import com.lightningkite.kiteui.reactive.onRemove
import com.lightningkite.kiteui.views.RView

// This is meant to help with scrolling to elements that might be in vastly different areas of the app, and also manage
// actions that need to be executed before scrolling.
object JumpTo {
    // A data class combining a view to scroll to, and an action to execute before scrolling. Typically used to expand
    // hidden elements before scrolling into view.
    data class Target(val view: RView, val action: ()->Unit) {
        fun go(horizontal: Align? = null, vertical: Align? = Align.Center, animate: Boolean = true) {
            action()
            view.scrollIntoView(horizontal, vertical, animate)
        }
    }

    // By the nature of rendering views, targets are late init. This handles the case where you try to navigate to a
    // target that is not rendered yet.
    abstract class TargetHandler : Listenable {
        // Listeners activate even if no target is currently available. They should ensure a target will render.
        private val listeners = ArrayList<()->Unit>()
        override fun addListener(listener: () -> Unit): () -> Unit {
            listeners.add(listener)
            return { listeners.remove(listener) }
        }

        private var target: Target? = null
        private var awaitingTarget: Triple<Align?, Align?, Boolean>? = null

        fun setTarget(view: RView, onScroll: () -> Unit = {}) {
            target = Target(view, onScroll)
            view.onRemove { target = null }
            awaitingTarget?.let { (horizontal, vertical, animate) ->
                target?.go(horizontal, vertical, animate)
                awaitingTarget = null
            }
        }

        operator fun invoke(horizontal: Align? = null, vertical: Align? = Align.Start, animate: Boolean = true) {
            listeners.invokeAllSafe()
            target
                ?.go(horizontal, vertical, animate)
                ?: run {
                    awaitingTarget = Triple(horizontal, vertical, animate)
                }
        }
    }

    // Same as above, but manages many targets under a single name
    abstract class TargetCategory : Listenable {
        private val listeners = ArrayList<()->Unit>()
        override fun addListener(listener: () -> Unit): () -> Unit {
            listeners.add(listener)
            return { listeners.remove(listener) }
        }

        private val targets = HashMap<UUID, Target>()
        private val awaitingTarget = HashMap<UUID, Triple<Align?, Align?, Boolean>>()

        fun newTarget(id: UUID, view: RView, onScroll: () -> Unit = {}) {
            val target = Target(view, onScroll)
            targets[id] = target
            view.onRemove { targets.remove(id) }

            awaitingTarget[id]?.let { (horizontal, vertical, animate) ->
                target.go(horizontal, vertical, animate)
                awaitingTarget.remove(id)
            }
        }

        operator fun invoke(id: UUID, horizontal: Align? = null, vertical: Align? = Align.Center, animate: Boolean = true) {
            listeners.invokeAllSafe()
            targets[id]
                ?.go(horizontal, vertical, animate)
                ?: run {
                    awaitingTarget[id] = Triple(horizontal, vertical, animate)
                }
        }
    }

    // Actual Navigators
    object ProjectDetails : TargetHandler()
    object ToDos : TargetHandler()

    object PendingChangeOrder : TargetCategory()
    object ChangeItem : TargetCategory()

    object LineItem : TargetCategory()
    object ScopeView : TargetCategory()
    object PayApp : TargetCategory()
}