package com.crowpay.views.components.project.work.changeOrders.tables

import com.crowpay.views.theming.AppColors
import com.crowpay.ChangeRequest
import com.crowpay.actuals.AppDimensions
import com.crowpay.linked
import com.crowpay.pending
import com.crowpay.utils.*
import com.crowpay.views.components.*
import com.crowpay.views.components.project.work.changeOrders.*
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import kotlinx.coroutines.launch

private class ColumnWeights(lens: ProjectLens) {
    // Column Ratios. Kept separate in this class for easier editing
    val nameWeight = if (lens == ProjectLens.Customer) 3.5f else 4f
    val linkedWeight = 1f
    val idWeight = if (lens == ProjectLens.Customer) 0.5f else 1f
    val submittedWeight = 1.5f
    val statusWeight = if (lens == ProjectLens.Customer) 2f else 2.5f
    val originalWeight = 1.3f
    val totalWeight = 1.5f
    val dateWeight = 1.5f

    val tableRowSpacing = 0.5.rem
    val checkboxIndent = 1.5.rem
}

fun ViewWriter.renderPendingItems(
    changeOrders: Readable<List<ChangeRequest>>,
    changeItems: Readable<List<ChangeRequestItemWrapper>>,
    lens: ProjectLens
) {
    val weights = ColumnWeights(lens)
    val selectedItems = Property<Set<UUID>>(setOf())
    val allSelected = shared {
        selectedItems().size == changeItems().filter { !it.cancelled }.size
    }
        .withWrite {
            if (it) selectedItems.value = changeItems().filter { !it.cancelled }.map { it._id }.toSet()
            else selectedItems.value = emptySet()
        }

    col {
        bold - subTitle("Work Items:")
        launch {
            for (change in changeOrders()) {
                JumpTo.PendingChangeOrder.newTarget(change._id, this@col)
            }
        }

        sectionIndentCol {
            spacing = 0.px
            section - sizeConstraints(height = 2.rem) - row {
                spacing = weights.tableRowSpacing
                fun header(content: String) = centered - bodyBold {
                    align = Align.Center
                    this.content = content
                }

                space(weights.checkboxIndent)
                weight(weights.nameWeight) - centered - bodyBold("Work Item")
                weight(weights.idWeight) - header("ID")
                weight(weights.submittedWeight) - header("Submitted")
                weight(weights.statusWeight) - header("Status")
                weight(weights.originalWeight) - header("Original")
                weight(weights.totalWeight) - header("Total")
                weight(weights.dateWeight) - header("Date")
            }
            if (lens == ProjectLens.Customer) sizeConstraints(height = 2.5.rem) - row {
                existsDefaultFalse { changeItems().isNotEmpty() }
                sizeConstraints(width = weights.checkboxIndent) - stack {
                    gravity(Align.Start, Align.Center) - checkbox {
                        checked bind allSelected
                    }
                }
                centered - expanding - bodyBold("Select All")
            }
            col {
                spacing = 0.px
                forEach(changeItems) { item ->
                    val itemExpanded = Property(false)
                    col {
                        spacing = 0.px
                        sizeConstraints(minHeight = 2.5.rem) - row {
                            spacing = weights.tableRowSpacing
                            val contents: ViewWriter.() -> RowOrCol = {
                                row {
                                    spacing = weights.tableRowSpacing
                                    weight(weights.nameWeight) - row {
                                        spacing = AppDimensions.expandButtonSpace
                                        centered - expandIcon(itemExpanded) {
                                            visible = !item.cancelled
                                        }
                                        centered - body(item.name)
                                        centered - smallBody(item.changeType.preTense)
                                    }
                                    weight(weights.idWeight) - centered - body {
                                        align = Align.Center
                                        content = item.id
                                    }
                                    weight(weights.submittedWeight) - stack {
                                        centered - renderPriceChange { item.priceChange }
                                    }
                                    weight(weights.statusWeight) - centered - colored(AppColors.primary.light1) - body {
                                        align = Align.Center
                                        content = "Pending Approval"
                                    }
                                    weight(weights.originalWeight) - centered - body {
                                        align = Align.Center
                                        content = item.originalPrice.renderDollars()
                                    }
                                    weight(weights.totalWeight) - centered - body {
                                        align = Align.Center
                                        content = item.totalPrice.renderDollars()
                                    }
                                    weight(weights.dateWeight) - centered - body {
                                        align = Align.Center
                                        ::content {
                                            val id = item.changeRequest
                                            changeOrders().find { it._id == id }?.published?.format(Formats.mmddyyyy)
                                                ?: "ERR"
                                        }
                                    }
                                }
                            }

                            sizeConstraints(width = weights.checkboxIndent) - stack {
                                visible = lens == ProjectLens.Customer && !item.linked
                                if (visible) gravity(Align.Start, Align.Center) - checkbox {
                                    checked bind selectedItems.contains(item._id)
                                }
                            }

                            if (item.cancelled) expanding - contents()
                            else expanding - button {
                                spacing = 0.px
                                contents()
                                action = expandAction(itemExpanded)
                            }
                        }
                        onlyWhen { itemExpanded() } - renderChangeRequestItemDetails(item)
                        row {
                            space(weights.checkboxIndent)
                            expanding - greySeparator()
                        }
                    }
                }
            }
            space()
            if (lens == ProjectLens.Customer) atEnd - row {
                primaryButton - button {
                    ::enabled {
                        selectedItems().isNotEmpty()
                    }
                    specCenteredText("Approve")
                    action = approveSelected {
                        val selected = selectedItems()
                        changeItems().filter { it._id in selected }
                    }
                }
                warningTextButton - button {
                    ::enabled {
                        selectedItems().isNotEmpty()
                    }
                    centered - body("Reject")
                    action = denySelected {
                        val selected = selectedItems()
                        changeItems().filter { it._id in selected }
                    }
                }
            }
            space()
        }
    }
}

fun ViewWriter.renderPendingChangeOrder(
    changeOrder: Readable<ChangeRequest>,
    changeItems: Readable<List<ChangeRequestItemWrapper>>,
    lens: ProjectLens
) {
    val expanded = Property(true)
    val pending = shared { changeItems().filter { it.pending } }
    val allPending = shared { changeItems().all { it.pending } }
    val total = shared { pending().sumOf { it.priceChange ?: 0 } }
    val selectedItems = Property<Set<UUID>>(setOf())
    val selectedLinkedItems = shared {
        selectedItems().containsAll(pending().filter { it.linked }.map { it._id })
    }
        .withWrite {
            if (it) selectedItems.value = selectedItems() + pending().filter { it.linked }.map { it._id }.toSet()
            else selectedItems.value = selectedItems() - pending().filter { it.linked }.map { it._id }.toSet()
        }
    val allSelected = shared {
        selectedItems().size == pending().filter { !it.cancelled }.size
    }
        .withWrite {
            if (it) selectedItems.value = pending().filter { !it.cancelled }.map { it._id }.toSet()
            else selectedItems.value = emptySet()
        }

    val weights = ColumnWeights(lens)
    col {
        launch {
            JumpTo.PendingChangeOrder.newTarget(changeOrder()._id, this@col) {
                expanded.value = true
            }
        }

        spacing = 0.px
        expandButtonTheme - button {
            row {
                spacing = AppDimensions.expandButtonSpace
                centered - expandIcon(expanded)
                subTitle {
                    ::content {
                        val c = changeOrder()
                        "Change #${c.number}: ${c.title}"
                    }
                }
            }
            onClick("Toggle Expanded") { expanded.toggle() }
        }

        onlyWhen { expanded() } - col {
            spacing = 0.px
            space(1.rem)
            body {
                ::content {
                    "Description: ${changeOrder().description}"
                }
            }
            space(1.rem)

            section - sizeConstraints(height = 2.5.rem) - row {
                spacing = weights.tableRowSpacing
                sizeConstraints(width = weights.checkboxIndent) - space()
                centered - expanding - row {
                    spacing = weights.tableRowSpacing
                    weight(weights.nameWeight) - space()
                    weight(weights.linkedWeight) - centered - bodyBold {
                        ::visible { changeOrder().linkItems }
                        align = Align.Center
                        content = ""
                    }
                    weight(weights.idWeight) - centered - bodyBold {
                        align = Align.Center
                        content = "ID"
                    }
                    weight(weights.submittedWeight) - centered - bodyBold {
                        align = Align.Center
                        content = "Submitted"
                    }
                    weight(weights.statusWeight) - centered - bodyBold {
                        align = Align.Center
                        content = "Status"
                    }
                    weight(weights.originalWeight) - centered - bodyBold {
                        align = Align.Center
                        content = "Original"
                    }
                    weight(weights.totalWeight) - centered - bodyBold {
                        align = Align.Center
                        content = "Total"
                    }
                    weight(weights.dateWeight) - centered - bodyBold {
                        align = Align.Center
                        content = "Date"
                    }
                }
            }

            fun RowOrCol.tableRow(
                showLinked: ReactiveContext.() -> Boolean,
                id: ReactiveContext.() -> String,
                submitted: ReactiveContext.() -> Long?,
                showStatus: ReactiveContext.() -> Boolean,
                showOriginal: ReactiveContext.() -> Boolean,
                showTotal: ReactiveContext.() -> Boolean,
                showInfo: ReactiveContext.() -> Boolean
            ) {
                spacing = weights.tableRowSpacing
                weight(weights.linkedWeight) - stack {
                    colored(AppColors.secondary.main) - centered - icon {
                        ::visible.invoke(showInfo)
                        ::exists.invoke(showLinked)
                        source = Icon.linked
                    }
                }
                weight(weights.idWeight) - centered - body {
                    align = Align.Center
                    ::visible.invoke(showInfo)
                    ::content.invoke(id)
                }
                weight(weights.submittedWeight) - stack {
                    centered - renderPriceChange(submitted)
                }
                weight(weights.statusWeight) - centered - body {
                    dynamicTheme {
                        if (showStatus()) ForegroundSemantic(ChangeStatus.PendingApproval.displayColor)
                        else null
                    }
                    ::visible.invoke(showInfo)
                    align = Align.Center
                    ::content { if (showStatus()) ChangeStatus.PendingApproval.displayName else "-" }
                }
                weight(weights.originalWeight) - centered - body {
                    align = Align.Center
                    ::visible.invoke(showInfo)
                    ::content { if (showOriginal()) "$0.00" else "-" }
                }
                weight(weights.totalWeight) - centered - body {
                    align = Align.Center
                    ::visible.invoke(showInfo)
                    ::content { if (showTotal()) submitted().renderDollars() else "-" }
                }
                weight(weights.dateWeight) - centered - body {
                    align = Align.Center
                    ::visible.invoke(showInfo)
                    ::content {
                        changeOrder().published?.format(Formats.mmddyyyy) ?: "-"
                    }
                }
            }

            col {
                spacing = 0.px
                sizeConstraints(minHeight = 3.rem) - row {
                    spacing = weights.tableRowSpacing
                    sizeConstraints(width = weights.checkboxIndent) - space()
                    expanding - row {
                        spacing = weights.tableRowSpacing
                        weight(weights.nameWeight) - centered - bodyBold("Proposed Change")
                        tableRow(
                            showLinked = { false },
                            id = { changeOrder().number },
                            submitted = { total() },
                            showStatus = { allPending() },
                            showOriginal = { allPending() },
                            showTotal = { allPending() },
                            showInfo = { true }
                        )
                    }
                }
            }
            if (lens == ProjectLens.Customer) sizeConstraints(height = 2.5.rem) - row {
                spacing = weights.tableRowSpacing
                existsDefaultFalse { pending().isNotEmpty() }
                sizeConstraints(width = weights.checkboxIndent) - stack {
                    gravity(Align.Start, Align.Center) - checkbox {
                        checked bind allSelected
                    }
                }
                centered - expanding - bodyBold("Select All")
            }
            if (lens == ProjectLens.Customer) {
                row {
                    spacing = weights.tableRowSpacing
                    existsDefaultFalse { pending().isNotEmpty() }
                    space(weights.checkboxIndent)
                    expanding - greySeparator()
                }
                row {
                    spacing = weights.tableRowSpacing
                    existsDefaultFalse { pending().any { it.linked } }
                    sizeConstraints(width = weights.checkboxIndent) - stack {
                        gravity(Align.Start, Align.Center) - checkbox {
                            checked bind selectedLinkedItems
                        }
                    }
                    lightSection - sizeConstraints(height = 2.5.rem) - expanding - row {
                        spacing = weights.tableRowSpacing
                        weight(weights.nameWeight) - centered - bodyBold("Linked Approval")
                        tableRow(
                            showLinked = { false },
                            id = { "" },
                            submitted = { pending().filter { it.linked }.sumOf { it.priceChange ?: 0 } },
                            showStatus = { false },
                            showOriginal = { false },
                            showTotal = { false },
                            showInfo = { false }
                        )
                    }
                }
                row {
                    spacing = weights.tableRowSpacing
                    existsDefaultFalse { pending().any { it.linked } }
                    space(weights.checkboxIndent)
                    expanding - greySeparator()
                }
            }
            col {
                spacing = 0.px
                forEach(pending) { item ->
                    val itemExpanded = Property(false)
                    col {
                        spacing = 0.px
                        sizeConstraints(minHeight = 2.5.rem) - row {
                            spacing = weights.tableRowSpacing

                            val contents: ViewWriter.() -> RowOrCol = {
                                row {
                                    spacing = weights.tableRowSpacing
                                    weight(weights.nameWeight) - row {
                                        spacing = AppDimensions.expandButtonSpace
                                        centered - expandIcon(itemExpanded) {
                                            visible = !item.cancelled
                                        }
                                        centered - body(item.name)
                                        centered - smallBody("New")
                                    }
                                    tableRow(
                                        showLinked = { item.linked },
                                        id = { item.itemNumber },
                                        submitted = { item.priceChange },
                                        showStatus = { !allPending() },
                                        showOriginal = { !allPending() },
                                        showTotal = { !allPending() },
                                        showInfo = { true }
                                    )
                                }
                            }

                            sizeConstraints(width = weights.checkboxIndent) - stack {
                                visible = lens == ProjectLens.Customer && !item.linked
                                if (visible) centered - checkbox {
                                    checked bind selectedItems.contains(item._id)
                                }
                            }

                            if (item.cancelled)
                                expanding - contents()
                            else
                                expanding - button {
                                    spacing = 0.px
                                    contents()
                                    action = expandAction(itemExpanded)
                                }
                        }
                        if (!item.cancelled) onlyWhen { itemExpanded() } - renderChangeRequestItemDetails(item)
                        row {
                            spacing = weights.tableRowSpacing
                            space(weights.checkboxIndent)
                            expanding - greySeparator()
                        }
                    }
                }
            }
            space()
            if (lens == ProjectLens.Customer) row {
                expanding - space()
                primaryButton - button {
                    ::enabled {
                        selectedItems().isNotEmpty()
                    }
                    specCenteredText("Approve")
                    action = approveSelected {
                        val selected = selectedItems()
                        pending().filter { it._id in selected }
                    }
                }
                warningTextButton - button {
                    ::enabled {
                        selectedItems().isNotEmpty()
                    }
                    centered - body("Reject")
                    action = denySelected {
                        val selected = selectedItems()
                        pending().filter { it._id in selected }
                    }
                }
            }
            space()
        }

        greySeparator { ::exists { !expanded() } }
    }
}