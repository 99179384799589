package com.crowpay.views.components.project.work.changeOrders

import com.crowpay.*
import com.crowpay.extensions.hash
import com.crowpay.sdk.notFoundError
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.Action
import com.crowpay.utils.renderDollars
import com.crowpay.views.dialogs.DenyChangeItemsDialog
import com.crowpay.views.dialogs.GenericConfirmationDialog
import com.crowpay.views.dialogs.MakePaymentDialog
import com.lightningkite.UUID
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.Action
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewWriter
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch

private suspend fun review(item: ChangeRequestItem, approved: Boolean) {
    when (item) {
        is ChangeRequestItemWrapper -> review(item.wrapped, approved)
        is PendingLineItem -> {
            val session = notNullSession()
            session.nonCached.pendingLineItem.run {
                if (approved) acceptPendingLineItem(item._id, item.hash())
                else denyPendingLineItem(item._id, item.hash())
            }
            delay(500)
            session.changeRequests.totallyInvalidate()
            session.pendingLineItems.totallyInvalidate()
            session.lineItems.totallyInvalidate()
            session.projects.totallyInvalidate()
        }
        is PendingItemChange -> {
            val session = notNullSession()
            session.nonCached.pendingItemChange.run {
                if (approved) acceptPendingItemChange(item._id, item.hash())
                else denyPendingItemChange(item._id, item.hash())
            }
            delay(500)
            session.changeRequests.totallyInvalidate()
            session.pendingItemChange.totallyInvalidate()
            session.itemChanges.totallyInvalidate()
            session.projects.totallyInvalidate()
        }
        is LineItemFromChangeRequest, is ItemChange -> {
            println("WARN: Attempted to approve already approved item")
            return
        }
        else -> throw IllegalArgumentException("Unsupported ChangeRequestItem type")
    }
}
suspend fun approve(item: ChangeRequestItem) = review(item, true)
suspend fun deny(item: ChangeRequestItem) = review(item, false)

private suspend fun reviewLinked(items: List<PendingLineItem>, approved: Boolean) {
    if (items.isEmpty()) throw IllegalArgumentException("Must provide pending line items to approve")

    val hash = items.hash()
    val changeRequest = items
        .map { it.changeRequest }
        .toSet()
        .singleOrNull()
        ?: throw IllegalArgumentException(
            "All line items must be from the same change request. Found IDs: ${items.map { it.changeRequest }}"
        )

    val session = notNullSession()
    session.nonCached.changeRequest.run {
        if (approved) approveLinkedItems(changeRequest, hash)
        else denyLinkedItems(changeRequest, hash)
    }

    delay(500)
    session.changeRequests.totallyInvalidate()
    session.pendingItemChange.totallyInvalidate()
    if (approved) session.lineItems.totallyInvalidate()
}
suspend fun approveLinkedItems(items: List<PendingLineItem>) = reviewLinked(items, true)
suspend fun denyLinkedItems(items: List<PendingLineItem>) = reviewLinked(items, false)

private suspend fun reviewSelected(items: List<ChangeRequestItem>, approved: Boolean) {
    if (items.isEmpty()) throw IllegalArgumentException("Must provide pending line items to approve")
    val session = notNullSession()

    val linkedItems = items.filter { it.linked }.mapNotNull { it.pendingLineItem }

    if (linkedItems.isNotEmpty()) {
        val hash = linkedItems.hash()
        val linkedChangeRequest = linkedItems
            .map { it.changeRequest }
            .toSet()
            .singleOrNull()
            ?: throw IllegalArgumentException(
                "All line items must be from the same change request. Found IDs: ${items.map { it.changeRequest }}"
            )

        session.nonCached.changeRequest.run {
            if (approved) approveLinkedItems(linkedChangeRequest, hash)
            else denyLinkedItems(linkedChangeRequest, hash)
        }
    }

    val nonLinkedItems = items.filter { !it.linked }.map { item ->
        when (item) {
            is ChangeRequestItemWrapper -> { item.wrapped }
            else -> { item }
        }
    }
    nonLinkedItems.forEach { item ->
        when (item) {
            is PendingLineItem -> {
                session.nonCached.pendingLineItem.run {
                    if (approved) acceptPendingLineItem(item._id, item.hash())
                    else denyPendingLineItem(item._id, item.hash())
                }
            }
            is PendingItemChange -> {
                session.nonCached.pendingItemChange.run {
                    if (approved) acceptPendingItemChange(item._id, item.hash())
                    else denyPendingItemChange(item._id, item.hash())
                }
            }
            is LineItemFromChangeRequest, is ItemChange -> {
                println("WARN: Attempted to approve already approved item")
                return
            }
            else -> throw IllegalArgumentException("Unsupported ChangeRequestItem type")
        }
    }

    delay(500)
    session.changeRequests.totallyInvalidate()
    session.pendingItemChange.totallyInvalidate()
    session.itemChanges.totallyInvalidate()
    session.projects.totallyInvalidate()
    if (approved) session.lineItems.totallyInvalidate()
}
suspend fun approveSelectedItems(items: List<ChangeRequestItem>) = reviewSelected(items, true)
suspend fun denySelectedItems(items: List<ChangeRequestItem>) = reviewSelected(items, false)

private val ChangeRequestItem.pendingLineItem: PendingLineItem?
    get() = when (this) {
        is ChangeRequestItemWrapper -> wrapped.pendingLineItem
        is PendingLineItem -> this
        is PendingItemChange -> null
        else -> throw IllegalArgumentException("Unsupported ChangeRequestItem type")
    }

private val ChangeRequestItem.project: UUID
    get() = when (this) {
        is ChangeRequestItemWrapper -> wrapped.project
        is PendingLineItem -> project
        is PendingItemChange -> project
        else -> throw IllegalArgumentException("Unsupported ChangeRequestItem type")
    }

fun ViewWriter.approveSingle(changeItem: ChangeRequestItem): Action =
    Action("Approve Change Item") {
        val priceChange = changeItem.priceChange ?: 0
        dialogScreenNavigator.navigate(
            GenericConfirmationDialog(
                header = "Approve Change Item?",
                message =
                    if (priceChange > 0) "You will need to add ${priceChange.renderDollars()} to the escrow"
                    else "This action cannot be undone.",
                confirmationText = "Approve",
                dismissOnConfirm = false
            ) { confirmed ->
                if (!confirmed) return@GenericConfirmationDialog
                approve(changeItem)
                if (priceChange > 0) {
                    val project = notNullSession().projects[changeItem.project]() ?: notFoundError<Project>()
                    dialogScreenNavigator.reset(
                        MakePaymentDialog(project, priceChange)
                    )
                } else {
                    dialogScreenNavigator.dismiss()
                }
            }
        )
    }

fun ViewWriter.approveLinked(changeItems: suspend () -> List<ChangeRequestItem>) =
    Action("Approve Linked Items") {
        val items = changeItems()
        if (items.isEmpty()) throw IllegalArgumentException("Cannot approve an empty list")
        if (!items.all { it.linked }) throw IllegalArgumentException("Items must be linked")
        val priceChange = items.sumOf { it.priceChange ?: 0 }

        dialogScreenNavigator.navigate(
            GenericConfirmationDialog(
                header = "Approved ${items.size} Linked Items?",
                message =
                    if (priceChange > 0) "You will need to add ${priceChange.renderDollars()} to the escrow"
                    else "This action cannot be undone.",
                confirmationText = "Approve Linked",
                dismissOnConfirm = false
            ) { confirmed ->
                if (!confirmed) return@GenericConfirmationDialog
                if (priceChange > 0) {
                    approveLinkedItems(items.mapNotNull { it.pendingLineItem })
                    val project = notNullSession().projects[items.first().project]() ?: notFoundError<Project>()
                    dialogScreenNavigator.reset(MakePaymentDialog(project, priceChange))
                }
                else {
                    dialogScreenNavigator.dismiss()
                }
            }
        )
    }

fun ViewWriter.approveSelected(changeItems: suspend () -> List<ChangeRequestItem>) =
    Action("Approve Selected Items") {
        val items = changeItems()
        if (items.isEmpty()) throw IllegalArgumentException("Cannot approve an empty list")
        val priceChange = items.sumOf { it.priceChange ?: 0 }

        dialogScreenNavigator.navigate(
            GenericConfirmationDialog(
                header = "Approved ${items.size} Items?",
                message =
                    if (priceChange > 0) "You will need to add ${priceChange.renderDollars()} to the escrow"
                    else "This action cannot be undone.",
                confirmationText = "Approve Selected",
                dismissOnConfirm = false
            ) { confirmed ->
                if (!confirmed) return@GenericConfirmationDialog
                if (priceChange > 0) {
                    approveSelectedItems(items)
                    val project = notNullSession().projects[items.first().project]() ?: notFoundError<Project>()
                    dialogScreenNavigator.reset(MakePaymentDialog(project, priceChange))
                }
                else {
                    dialogScreenNavigator.dismiss()
                }
            }
        )
    }

// Keeping these separate for now in case each one needs its own confirmation text in the future
fun ViewWriter.denySingle(changeItem: ChangeRequestItem): Action =
    Action("Deny Change Item") {
        dialogScreenNavigator.navigate(
            DenyChangeItemsDialog(listOf(changeItem), changeItem.project) { deny(changeItem) }
        )
    }

fun ViewWriter.denyLinked(changeItems: suspend () -> List<ChangeRequestItem>) =
    Action("Deny Linked Items") {
        val items = changeItems()
        if (!items.all { it.linked }) throw IllegalArgumentException("Items must be linked")

        dialogScreenNavigator.navigate(
            DenyChangeItemsDialog(items, items.first().project) { denied ->
                denyLinkedItems(denied.mapNotNull { it.pendingLineItem })
            }
        )
    }

fun ViewWriter.denySelected(changeItems: suspend () -> List<ChangeRequestItem>) =
    Action("Deny Selected Items") {
        val items = changeItems()

        dialogScreenNavigator.navigate(
            DenyChangeItemsDialog(items, items.first().project) { denied ->
                denySelectedItems(denied)
            }
        )
    }