package com.crowpay.views.components.project.work.scope

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.completionResponse.CompletionFlowPermissions
import com.crowpay.completionResponse.CompletionResponse
import com.crowpay.completionResponse.serializable
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.*
import com.crowpay.views.components.*
import com.crowpay.views.components.project.work.changeOrders.ChangeStatus
import com.crowpay.views.components.project.work.changeOrders.status
import com.crowpay.views.components.files.renderFiles
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.components.project.renderContractorNotes
import com.crowpay.views.components.project.work.punchList.renderPunchListRow
import com.crowpay.views.dialogs.*
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.now
import com.lightningkite.serialization.notNull
import kotlinx.coroutines.delay

fun ViewWriter.renderLineItemDetails(
    item: Readable<AdjustedLineItem>,
    logs: Readable<List<ContractorNote>>,
    punchLists: Readable<List<PunchListItem>>,
    payAppItems: Readable<List<PayAppItem>>,
    lineItemCompletionMessages: Readable<List<LineItemCompletionMessage>>,
    project: Readable<Project>,
    lens: ProjectLens,
) =
    sectionIndentCol {
        spacing = AppDimensions.sectionIndent * 1.5

        col {
            spacing = 2.px
            notifications(item, lineItemCompletionMessages, lens)
            space()

            keyValue("Description") { item().description }

            // Files
            label2("Files (images, Bids, Plans, Specs)") {
                val files = item.lens { it.files }
                existsDefaultFalse { files().isNotEmpty() }
                renderFiles(files, AppDimensions.smallImagePreview)
            }
        }


        tasks(item, punchLists, project, lens)

        itemNotes(item, logs, lens)

        changeLog(item)

        col {
            sizeConstraints(minHeight = AppDimensions.actionBarHeight) - darkSection - lineItemActionBar(
                item,
                punchLists,
                payAppItems,
                project,
                lineItemCompletionMessages,
                lens
            )

            space()
        }
    }



private fun ViewWriter.notifications(
    item: Readable<AdjustedLineItem>,
    messages: Readable<List<LineItemCompletionMessage>>,
    lens: ProjectLens,
) {
    col {
        val notificationExists = Property(false)
        existsDefaultFalse { notificationExists() }

        space(1.rem)
        col {
            spacing = 1.rem
            lazy(item.lens { it.state }) { state ->
                if (state == LineItemState.Cancelled)
                    notificationBar(AppColors.yellow) - row {
                        notificationExists.value = true
                        body("This item has been cancelled, you can view its information but not edit it.")
                    }

                if (lens == ProjectLens.Contractor && state == LineItemState.InProgress) {
                    notificationBar(AppColors.blue) - body("Refer to original scope and change logs here for history of this work item. Add notes and tasks for this work as desired.\n")
                    notificationBar(AppColors.yellow) - body("Don't request sign off before the item is completed. Doing so can build mistrust in your clients.")
                    notificationExists.value = true
                }

                if (lens == ProjectLens.Customer && state == LineItemState.InProgress) {
                    notificationBar(AppColors.blue) - body("Good communication ensures great results. Stay engaged with your contractor to clarify the work and ensure it aligns with your expectations. Asking questions now can prevent misunderstandings and keep the project on track.\n")
                    notificationExists.value = true
                }

                if (lens == ProjectLens.Customer && state == LineItemState.RequestingReview)
                    notificationBar(AppColors.yellow) - row {
                        notificationExists.value = true
                        sizeConstraints(height = 1.rem, width = 1.rem) - centered - icon(
                            Icon.notify,
                            "Disclose Dates"
                        )
                        body("If not approved funds will auto-release in 14 days.")
                    }

                if (lens == ProjectLens.Contractor && state == LineItemState.RequestingReview)
                    notificationBar(AppColors.yellow) - col {
                        notificationExists.value = true
                        body {
                            ::content { "Your request for sign-off was sent on ${messages().map { it.responseType }.filterIsInstance<CompletionResponse.RequestComplete>().lastOrNull()?.accessInfo?.timestamp}" }
                        }
                        body {
                            inlineKeyValue(
                                "Next Steps",
                                "Keep communication clear and timely to ensure concerns are resolved quickly and approval is secured."
                            )
                        }
                    }

                if (state == LineItemState.NotApproved && lens == ProjectLens.Customer)
                    notificationBar(AppColors.blue) - body {
                        notificationExists.value = true
                        content = """
                            Your feedback has been sent to the contractor.

                            The contractor will review your concerns and communicate with you about their resolution plan. They may adjust, complete outstanding items, or clarify why the feel it should be signed off.
                        """.trimIndent()
                    }

                if (state == LineItemState.NotApproved && lens == ProjectLens.Contractor) {
                    notificationExists.value = true
                    notificationBar(AppColors.blue) - body(
                        """
                            The timer for this item has been paused.

                            Use CrowPay’s messaging to communicate with the homeowner, address their feedback, and align expectations. This is your opportunity to clarify misunderstandings, resolve concerns, and gain approval. Clear and prompt communication here can prevent tensions from escalating into formal issues or disputes.
                        """.trimIndent()
                    )
                    notificationBar(AppColors.yellow) - body(
                        """
                            Review carefully and then address the homeowner’s feedback before resubmitting, doing more work if necessary. Focus on providing the quality and clarity needed for approval.

                            Resubmitting prematurely may delay progress and impact trust, which could lead to a formal issue or dispute.
                        """.trimIndent()
                    )
                    notificationBar(AppColors.red) - body {
                        ::content {
                            """
                                Your Client has left feedback. Make sure you address the client feedback before you request approval again.
                                
                                ${messages().lastOrNull { it.clientMessage }?.message}
                            """.trimIndent()
                        }
                    }
                }

                if (state == LineItemState.Complete && lens == ProjectLens.Customer)
                    notificationBar(AppColors.blue) - body {
                        notificationExists.value = true
                        ::content {
                            """
                                Great news! ${item().name} has been completed and approved.
                                
                                Your contractor will continue working on the project. Keep tracking updates in CrowPay, and remember to review and approve upcoming work items as sign-off is requested. Maintain open communication with your contractor to ensure a smooth process and avoid potential delays.
                            """.trimIndent()
                        }
                    }

                if (state == LineItemState.Complete && lens == ProjectLens.Contractor)
                    notificationBar(AppColors.blue) - body {
                        notificationExists.value = true
                        ::content {
                            """
                                Congratulations! ${item().name} has been completed and approved.
                                
                                If any loose items remain, add them to the Project Punch List to keep everything organized for final closeout. Maintain clear communication with the homeowner to ensure alignment, manage expectations, and keep the project on track. Proactive updates build trust and help avoid unnecessary delays.
                            """.trimIndent()
                        }
                    }
            }
        }
    }
}

private fun ViewWriter.tasks(
    item: Readable<AdjustedLineItem>,
    punchLists: Readable<List<PunchListItem>>,
    project: Readable<Project>,
    lens: ProjectLens,
) {
    col {
        existsDefaultFalse {
            project().state.newTasksAllowed() && item().state.newTasksAllowed()
        }

        renderPunchListRow(
            title = Constant("Tasks"),
            items = punchLists,
            project = project,
            lineItem = item,
            lens = lens,
        )
    }
}

private fun ViewWriter.itemNotes(
    item: Readable<AdjustedLineItem>,
    notes: Readable<List<ContractorNote>>,
    lens: ProjectLens,
) {
    col {
        spacing = 0.px

        val logsExpanded = Property(false)
        leftExpandButton(logsExpanded) { subTitle { ::content { "Notes:" } } }

        onlyWhen { logsExpanded() } - sectionIndentCol {
            spacing = 1.rem
            space()
            if (lens == ProjectLens.Contractor) {
                atStart - tertiaryButton - button {
                    ::exists { !item().cancelled }
                    ::enabled { item().state.newNotesAllowed() }
                    spacing = 0.3.rem
                    specCenteredText("+ Work Note")
                    onClick {
                        val it = item()
                        dialogScreenNavigator.navigate(CreateOrEditNote(it.project, it._id, null))
                    }
                }
                space()
            }

            renderContractorNotes(
                notes,
                groupName = { item().name },
                lens
            )
            space()
        }

        greySeparator()
    }
}


private val changeLogRowHeight = 3.rem
private fun ViewWriter.changeLog(
    item: Readable<AdjustedLineItem>,
) {
    col {
        ::exists { item().let { it.changes.isNotEmpty() || it.wraps.changeRequest != null } }

        val changes = item.lens { it.changes.sortedBy { it.changeAccepted } }
        spacing = 0.px
        val logExpanded = Property(false)

        leftExpandButton(logExpanded) { subTitle("Change Log") }

        onlyWhen { logExpanded() } - sectionIndentCol {
            space(1.rem)
            row {
                weight(3f) - bodyBold("Work Item")
                weight(1f) - bodyBold("ID") { align = Align.Center }
                weight(1f) - bodyBold("Amount") { align = Align.Center }
                weight(1f) - bodyBold("Status") { align = Align.Center }
                weight(1f) - bodyBold("Date") { align = Align.Center }
                weight(1f) - space()
            }
            col {
                spacing = 0.px
                greySeparator()
                sizeConstraints(height = changeLogRowHeight) - row {
                    weight(3f) - row {
                        spacing = AppDimensions.expandButtonSpace
                        centered - body { ::content { item().name } }
                        centered - smallBody {
                            ::content { if (item().wraps.changeRequest != null) "New" else "Original" }
                        }
                    }
                    weight(1f) - centered - body {
                        align = Align.Center
                        ::content {
                            val info = item().changeRequest
                            if (info == null) "-"
                            else
                                info.itemNumber

                        }
                    }
                    weight(1f) - centered - body {
                        align = Align.Center
                        ::content { item().wraps.originalPrice.renderDollars() }
                    }
                    weight(1f) - centered - body {
                        align = Align.Center
                        dynamicTheme {
                            if (item().wraps.changeRequest != null) ForegroundSemantic(ChangeStatus.Accepted.displayColor)
                            else null
                        }
                        ::content { if (item().wraps.changeRequest != null) "Accepted" else "-" }
                    }
                    weight(1f) - centered - body {
                        align = Align.Center
                        ::content { item().wraps.created.format(Formats.mmddyyyy) }
                    }
                    weight(1f) - space()
                }
                greySeparator()
                col {
                    spacing = 0.px
                    forEachUpdating(changes) { change ->
                        col {
                            spacing = 0.px
                            sizeConstraints(height = changeLogRowHeight) - row {
                                weight(3f) - centered - row {
                                    spacing = AppDimensions.expandButtonSpace
                                    centered - body { ::content { item().name } }
                                    centered - smallBody { ::content { change().changeType.postTense } }
                                }
                                weight(1f) - centered - body {
                                    ::content { change().itemNumber }
                                    align = Align.Center
                                }
                                weight(1f) - stack {
                                    centered - renderPriceChange { change().priceChange ?: 0 }
                                }
                                weight(1f) - centered - body {
                                    align = Align.Center
                                    dynamicTheme { ForegroundSemantic(change().status.displayColor) }
                                    ::content { change().status.displayName }
                                }
                                weight(1f) - centered - body {
                                    align = Align.Center
                                    ::content { change().changeAccepted.format(Formats.mmddyyyy) }
                                }
                                weight(1f) - stack {
                                    centered - secondaryButton - button {
                                        specCenteredText("View")
                                        onClick("View Change Details") {
                                            JumpTo.ChangeItem(change()._id)
                                        }
                                    }
                                }
                            }
                            greySeparator()
                        }
                    }
                }
            }
        }

        greySeparator { ::exists { !logExpanded() } }
    }
}

private fun ViewWriter.lineItemActionBar(
    item: Readable<AdjustedLineItem>,
    punchLists: Readable<List<PunchListItem>>,
    payAppItems: Readable<List<PayAppItem>>,
    project: Readable<Project>,
    lineItemCompletionMessages: Readable<List<LineItemCompletionMessage>>,
    lens: ProjectLens,
) =
    stack {
        spacing = AppDimensions.sectionIndent / 2
        val (primaryAction, secondaryAction, tertiaryAction) = lineItemActions(item, punchLists, payAppItems, project, lineItemCompletionMessages, lens)

        exists = false
        existsDefaultFalse { primaryAction() != null || secondaryAction() != null || tertiaryAction() != null }

        row {
            spacing = AppDimensions.buttonRowSpacing
            expanding - space()

            button {
                applyReactiveActionFormatting(tertiaryAction)
            }

            button {
                applyReactiveActionFormatting(secondaryAction)
            }

            button {
                applyReactiveActionFormatting(primaryAction)
            }

//            space(AppDimensions.sectionIndent / 2 - AppDimensions.cornerRadii)
        }
    }

private fun ViewWriter.lineItemActions(
    item: Readable<AdjustedLineItem>,
    punchLists: Readable<List<PunchListItem>>,
    payAppItems: Readable<List<PayAppItem>>,
    project: Readable<Project>,
    lineItemCompletionMessages: Readable<List<LineItemCompletionMessage>>,
    lens: ProjectLens,
): Triple<Readable<ButtonAction?>, Readable<ButtonAction?>, Readable<ButtonAction?>> {
    val primaryAction = shared {
        val project = project()
        val lineItem = item()

        val isCustomer = (lens != ProjectLens.Contractor)
        val isContractor = (lens == ProjectLens.Contractor)
        val notComplete = (lineItem.state < LineItemState.Complete)
        val started = (lineItem.started != null)
        val notOnHold = lineItem.state != LineItemState.OnHold
        val notInScopeSet = lineItem.scopeSet != true

        val permissions = CompletionFlowPermissions(lineItem.wraps, isCustomer, lineItemCompletionMessages())
        val previousMessage = permissions.previousResponse

        when {
            isContractor &&
                    notComplete &&
                    !started &&
                    notInScopeSet &&
                    project.state in setOf(ProjectState.PendingStart, ProjectState.InProgress) ->
                ButtonAction("Start Work") {
                    fun confirmation(
                        header: String,
                        message: String,
                        confirmationText: String,
                    ) = GenericConfirmationDialog(
                        header = header,
                        message = message,
                        confirmationText = confirmationText,
                        messageType = MessageType.Confirmation,
                        onSubmit = {
                            if (it) {
                                val session = notNullSession()

                                if (lineItem.scopeSet == true) {
                                    session.lineItems.bulkModify(
                                        MassModification(
                                            condition<LineItem> {
                                                if (lineItem.changeRequest != null) it.changeRequest.notNull.id eq lineItem.changeRequest!!.id
                                                else it.scopeView eq lineItem.scopeView
                                            } and condition { it.state eq lineItem.state },
                                            modification { it.started assign now() }
                                        )
                                    )
                                } else {
                                    session
                                        .lineItems[lineItem._id]
                                        .modify(modification { it.started assign now() })
                                }

                                session.projects[project._id].invalidate()
                            }
                        }
                    )

                    dialogScreenNavigator.navigate(
                        when {
                            project.started == null && lineItem.scopeSet == true -> confirmation(
                                "Start Items and Project?",
                                "Starting this work item will start the project and other items in the scope set as well",
                                "Start Items and Project"
                            )

                            project.started == null -> confirmation(
                                "Start Item and Project?",
                                "Starting this work item will start the project as well",
                                "Start Item and Project"
                            )

                            lineItem.scopeSet == true -> confirmation(
                                "Start Work Items?",
                                "Starting this work item will start the other items in the scope set as well",
                                "Start Work Items"
                            )

                            else -> confirmation(
                                "Start Work Item?",
                                "Confirm that you want to start this work item",
                                "Start Work Item"
                            )
                        }
                    )
                }

            isCustomer &&
                    notComplete &&
                    notOnHold &&
                    notInScopeSet &&
                    permissions.canAccept ->
                ButtonAction(
                    when (val prop = permissions.mostRecentProposal) {
                        is CompletionResponse.RequestComplete -> "Approve Work"
                        CompletionResponse.Handled -> "Approve Work"
                        CompletionResponse.ForceComplete -> "Accept Force Complete"
                        CompletionResponse.Appeal -> "Accept Appeal"
                        is CompletionResponse.Credit -> "Accept Credit (${prop.credit.renderDollars()})"
                        is CompletionResponse.Remedy -> "Accept Remedy Plan"
                        is CompletionResponse.Remedy.RemedyComplete -> "Approve Work"
                        CompletionResponse.RequestResolveLater -> "Resolve Later"
                        null -> ""
                    }
                ) {
                    fun confirmation(
                        header: String,
                        message: String,
                        confirmationText: String,
                    ) = GenericConfirmationDialog(
                        header = header,
                        message = message,
                        confirmationText = confirmationText,
                        messageType = MessageType.Confirmation,
                        onSubmit = { confirmed ->
                            if (confirmed) {
                                val session = notNullSession()
                                repeatForScopeSets(lineItem) { item ->
                                    val result = session.nonCached.lineItem.complete(item._id)
                                    session.lineItems.localSignalUpdate(
                                        matching = { it._id == result._id },
                                        modify = { result }
                                    )
                                }
                                delay(200)
                                session.projects[project._id].invalidate()
                            }
                        }
                    )

                    dialogScreenNavigator.navigate(
                        if (lineItem.scopeSet == true) confirmation(
                            header = "Approve & Pay Work Items?",
                            message = "Approving work items releases funds for the contractor as payment. This will happen for each item in the scope set.",
                            confirmationText = "Approve & Pay",
                        )
                        else confirmation(
                            header = "Approve & Pay ${lineItem.name}?",
                            message = "Approving this work item releases funds to the contractor as payment.",
                            confirmationText = "Approve & Pay",
                        )
                    )
                }

            isCustomer &&
                    notComplete &&
                    notOnHold &&
                    previousMessage?.responseType == CompletionResponse.RequestResolveLater ->
                ButtonAction("Resolve Later") {
                    dialogScreenNavigator.navigate(
                        GenericConfirmationDialog(
                            "Resolve Later?",
                            "Resolve later confirmation text.",
                            confirmationText = "Resolve Later"
                        ) {
                            if (it) {
                                val session = notNullSession()
                                val sent = session.nonCached.lineItemCompletionMessage.sendMessage(
                                    LineItemCompletionMessage(
                                        project = project._id,
                                        lineItem = lineItem._id,
                                        sender = session.userId,
                                        responseTypeSerializable = CompletionResponse.Accept(CompletionResponse.RequestResolveLater, session.currentAccess()).serializable(),
                                        message = "Accept Resolve Later",
                                        clientMessage = isCustomer
                                    )
                                )
                                session.lineItemCompletionMessages.localInsert(sent)
                                delay(50)
                                session.lineItems.totallyInvalidate()
                            }
                        }
                    )
                }

            isContractor &&
                    notComplete &&
                    project.state == ProjectState.InProgress &&
                    notInScopeSet &&
                    lineItem.state in setOf(LineItemState.InProgress, LineItemState.WorkingOnRemedy) ->
                ButtonAction(
                    "Request Sign Off",
                    enabled = { payAppItems().none { it.lineItem == lineItem._id && it.pending } }
                ) {
                    if (punchLists().any { it.required && it.complete == null })
                        dialogScreenNavigator.navigate(
                            GenericDialog(
                                message = "You still have required punch list items pending. You cannot request review until all required punch list items are complete.",
                            )
                        )
                    else {
                        dialogScreenNavigator.navigate(
                            GenericConfirmationDialog(
                                header = "Ready to Sign Off?",
                                message =
                                    buildString {
                                        // Good luck reading this.
                                        if (lineItem.scopeSet == true) {
                                            appendLine("Are ${lineItem.name}, and the other items in the scope set ready for sign off?")
                                        }
                                        else appendLine("Is ${lineItem.name} ready for sign off?")

                                        append('\n')

                                        if (lineItem.state == LineItemState.NotApproved)
                                            appendLine(
                                                """
                                                        Before resubmitting for approval, ensure the work is fully complete and meets the client’s expectations.

                                                        If the client does not approve again, they may escalate the issue, which could lead to further delays and formal resolution steps. Resubmit only if you’ve addressed all feedback and communicated clearly with the client.

                                                        Tips:
                                                        - Attach updated pictures or documentation to help the client approve your work faster.
                                                        - If small, low-value items remain (e.g., a sprinkler clock on backorder), communicate with the client and list them as punch list or warranty items. This reassures the client that loose ends will be resolved during project closeout.
                                                    """.trimIndent()
                                            )
                                        else
                                            appendLine(
                                                """
                                                        Submitting for sign-off will send an approval request to the client. Ensure ${if (lineItem.scopeSet == true) "all items are" else "the item is"} fully complete and the client is aware of your proposal.
            
                                                        If minor loose ends remain, notify the client they’ve been added to the punch list for project closeout.
                        
                                                        Tip: Attach pictures to help the client approve and release payment faster.
                                                    """.trimIndent()
                                            )
                                    },
                                question = null,
                                confirmationText = "Request Sign Off",
                                messageType = MessageType.Confirmation,
                                onSubmit = { confirmed ->
                                    if (confirmed) {
                                        val session = notNullSession()
                                        repeatForScopeSets(lineItem) { item ->
                                            val result = session.nonCached.lineItem.requestReview(item._id)
                                            session.lineItems.localSignalUpdate(
                                                matching = { it._id == result._id },
                                                modify = { result }
                                            )
                                        }
                                    }
                                }
                            )
                        )
                    }
                }

            lineItem.state == LineItemState.Complete ->
                ButtonAction("View Approval History") {
                    dialogScreenNavigator.navigate(
                        LineItemCompletionForm.createDialog(lineItem, project(), lens)
                    )
                }

            else -> null
        }
    }

    val secondaryAction = shared {
        val project = project()
        val lineItem = item()

        val isClient = (lens != ProjectLens.Contractor)
        val isContractor = (lens == ProjectLens.Contractor)
        val notComplete = (lineItem.state < LineItemState.Complete)
        val notDisputed = (project.state != ProjectState.Disputed)
        val notInScopeSet = lineItem.scopeSet != true

        val permissions = CompletionFlowPermissions(
            lineItem.wraps,
            lens != ProjectLens.Contractor,
            lineItemCompletionMessages()
        )

        when {
            isClient &&
                    notComplete &&
                    notDisputed &&
                    notInScopeSet &&
                    lineItem.state == LineItemState.RequestingReview ->
                ButtonAction("Not Ready", theme = SecondaryButtonSemantic) {
                    val messages = lineItemCompletionMessages.awaitSuspending()
                    if (messages.size <= 1)
                        dialogScreenNavigator navigateTo NotReadyConfirmation(lineItem, project) {
                            dialogScreenNavigator.reset(
                                LineItemCompletionForm.createDialog(lineItem, project, ProjectLens.Customer)
                            )
                        }
                    else
                        dialogScreenNavigator.navigate(LineItemCompletionForm.createDialog(lineItem, project, lens))
                }
            lineItem.state.responseAllowed() && permissions.mustFileDispute ->
                ButtonAction(
                    "File Dispute",
                    theme = DangerButtonSemantic
                ) {
                    dialogScreenNavigator.navigate(
                        DisputeWorkItemConfirmation(
                            dismiss = { dialogScreenNavigator.dismiss() }
                        ) { choice ->
                            dialogScreenNavigator.reset(
                                LineItemCompletionForm
                                    .createDialog(lineItem, project, lens)
                                    .apply { fileDispute.value = choice }
                            )
                        }
                    )
                }
            lineItem.state.responseAllowed() &&
                    lineItem.state > LineItemState.InProgress -> {
                ButtonAction(
                    when {
                        !permissions.awaitingResponse -> "View Completion Messages"
                        permissions.mustRaiseIssue -> "Raise Issue"
                        lineItem.state == LineItemState.Resolving -> "Review Resolution"

                        else -> "Respond to ${if (isContractor) "Homeowner" else "Contractor"}"
                    },
                    theme = when {
                        permissions.mustRaiseIssue -> DangerButtonSemantic
                        isClient -> SecondaryButtonSemantic
                        else -> if (primaryAction() == null) PrimaryButtonSemantic else SecondaryButtonSemantic
                    }
                ) {
                    dialogScreenNavigator.navigate(LineItemCompletionForm.createDialog(lineItem, project, lens))
                }
            }

            isClient &&
                    lineItem.issue == null &&
                    lineItem.state == LineItemState.ForceComplete ->
                ButtonAction("Raise Issue", theme = SecondaryButtonSemantic) {
                    dialogScreenNavigator.navigate(
                        LineItemCompletionForm.createDialog(lineItem, project, lens)
                    )
                }


            else -> null
        }
    }

    val tertiaryAction = shared {
        val project = project()
        val lineItem = item()

        val isCustomer = (lens != ProjectLens.Contractor)
        val notComplete = (lineItem.state < LineItemState.Complete)
        val notDisputed = (project.state != ProjectState.Disputed)
        val notInScopeSet = lineItem.scopeSet != true

        when {
            isCustomer &&
                    notComplete &&
                    notDisputed &&
                    notInScopeSet &&
                    lineItem.state == LineItemState.RequestingReview ->
                ButtonAction("Minor Adjustment", theme = SecondaryButtonSemantic) {
                    dialogScreenNavigator.navigate(
                        MinorAdjustmentsDialog(lineItem.wraps)
                    )
                }

            else -> null
        }
    }

    return Triple(primaryAction, secondaryAction, tertiaryAction)
}