package com.crowpay.views.components.project.activity

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.*
import com.crowpay.views.components.inlineKeyValue
import com.crowpay.views.components.space
import com.crowpay.views.dialogs.*
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.screens.common.ProjectView
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.Color
import com.lightningkite.kiteui.models.ThemeDerivation
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*

fun ProjectView.projectToDos(writer: ViewWriter) = with(writer) {
    stack {
        centered - col {
            existsDefaultFalse { todo() == 0 }
            colored(AppColors.secondary.light1) - title {
                content = "All Caught Up!"
                align = Align.Center
            }
            body {
                content = "You're all caught up with To-Do's right now. Yellow activity icon will display when you have pending To-Do's."
                align = Align.Center
            }
        }

        col {
            JumpTo.ToDos.setTarget(this)

            spacing = 0.dp

            existsDefaultFalse { todo() > 0 }

            space(AppDimensions.sectionIndent)

            row {
                spacing = 0.dp

                space(AppDimensions.backgroundIndent)
                expanding - col {

                    if (lens == ProjectLens.Preview)
                        toDoSection(Color.fromHexString(ProjectState.WaitingApproval.displayColor)) - stack {
                            spacing = AppDimensions.sectionIndent
                            existsDefaultFalse { canAcceptProject() }
                            col {

                                themeChoice = ThemeDerivation { appTheme.withoutBack }

                                notificationBar(AppColors.yellow) - body { ::content{ "Review all project details and scope carefully. If edits are needed ask your contractor to edit and resend invite when updated. Changes to work scope after project acceptance will need both parties approval." } }

                                subTitle {
                                    ::content{ "Accept Project: ${project().name} ${project().activePrice.renderDollars()}" }
                                }
                                row {
                                    spacing = AppDimensions.buttonSpacing

                                    primaryButton - button {
                                        specCenteredText("Accept Project")

                                        onClick("Accept Project") { onAccept() }
                                    }

                                    secondaryButton - button {
                                        specCenteredText("Review Project")

                                        onClick {
                                            JumpTo.ProjectDetails(vertical = Align.Start)
                                        }
                                    }
                                }
                            }
                        }


                    if (lens == ProjectLens.Customer) {
                        toDoSection(Color.fromHexString(ProjectState.AwaitingFunding.displayColor)) - stack {
                            spacing = AppDimensions.sectionIndent
                            existsDefaultFalse { lens == ProjectLens.Customer && project().state >= ProjectState.Accepted && needsPaymentMethods() }
                            col {
                                notificationBar(AppColors.yellow) - body("You need to connect your bank account before you can deposit money into the escrow.")

                                subTitle("Connect Bank Account")
                                gravity(Align.Start, Align.Start) - primaryButton - button {
                                    specCenteredText("Connect Bank Account")
                                    onClick {
                                        dialogScreenNavigator.navigate(SetupMethodDialog {
                                            println("Inside SetupMethodDialog onComplete")
                                            pullMethods.invokeAll()
                                        })
                                    }
                                }
                            }
                        }

                        toDoSection(Color.fromHexString(ProjectState.AwaitingFunding.displayColor)) - stack {
                            spacing = AppDimensions.sectionIndent
                            existsDefaultFalse { canMakePayment() }
                            col {
                                notificationBar(AppColors.yellow) - stack {
                                    spacing = AppDimensions.sectionIndent
                                    col {
                                        bold - body {
                                            ::content { "Project Escrow Amount to Deposit: ${project().fundingNeeded.renderDollars()}" }
                                        }
                                        body("Click the \"Deposit Escrow Funds\" button to deposit the full project amount into CrowPay's FDIC insured, escrow protection account. Allow 2-3 days for funds to settle.")
                                        body("You are in full control to release payment draws as outlined in the project scope as the contractor completes work items. Once funded a project cannot be modified or canceled without an agreed upon change order.")
                                    }
                                }

                                subTitle("Funds Requested")
                                row {
                                    primaryButton - button {
                                        specCenteredText("Deposit Escrow Funds")
                                        onClick {
                                            dialogScreenNavigator.navigate(MakePaymentDialog(project.await()))
                                        }
                                    }
                                    secondaryButton - button {
                                        specCenteredText("More Info")
                                        onClick {
                                            dialogScreenNavigator.navigate(
                                                GenericDialog(
                                                    """
                                                        Contractor will submit for payment on an individual work item basis. After 14 days, funds will automatically release to the contractor for each work item approval request if no response is taken by the client. In the event of a performance or acceptance disagreement, CrowPay will remain custodian of the funds until directed how to distribute from a joint resolution between homeowner and contractor, or instructed by a binding arbitration decision.
    
                                                        CrowPay advises clients to only accept change orders connected to the project when processed through CrowPay by the contractor. Communicating about change orders in real time prevents unexpected pay requests by contractor at project closeout.
    
                                                        CrowPay advises contractors to handle all cost changes through CrowPay, but will be processed at the contractor's discretion. Any changes issued through CrowPay that increase project costs may require additional funds for contractor to proceed with work. Changes resulting in a credit will remain in CrowPay until project is completed and if a net credit remains, the balance will be returned to the client.
                                                    """.trimIndent()
                                                )
                                            )
                                        }
                                    }
                                }
                            }
                        }


                        col {
                            existsDefaultFalse{ itemsRequiringReview().isNotEmpty() }
                            forEachUpdating(itemsRequiringReview) { line ->
                                toDoSection(Color.fromHexString(ProjectState.InProgress.displayColor)) - stack {
                                    spacing = AppDimensions.sectionIndent
                                    col {
                                        subTitle { ::content { "Approve Work Item \"${line().name}\"" } }
                                        gravity(Align.Start, Align.Start) - primaryButton - button {
                                            specCenteredText("View Work Item")
                                            onClick { JumpTo.LineItem(line()._id) }
                                        }
                                    }
                                }
                            }
                        }

                        col {
                            existsDefaultFalse { scopesRequiringReview().isNotEmpty() }
                            forEachUpdating(scopesRequiringReview) { scope ->
                                toDoSection(Color.fromHexString(ProjectState.InProgress.displayColor)) - stack {
                                    spacing = AppDimensions.sectionIndent
                                    col {
                                        subTitle { ::content { "Approve Scope \"${scope().scopeTitle}\"" } }
                                        gravity(Align.Start, Align.Start) - primaryButton - button {
                                            specCenteredText("View Scope")
                                            onClick { JumpTo.ScopeView(scope()._id) }
                                        }
                                    }
                                }
                            }
                        }

                        col {
                            existsDefaultFalse { pendingChanges().isNotEmpty() }
                            forEachUpdating (
                                shared {
                                    val pending = pendingChanges().map { it.changeRequest }.toSet()
                                    changeOrders().filter { it._id in pending }
                                }
                            ) { change ->
                                toDoSection(Color.fromHexString(ProjectState.InProgress.displayColor)) - stack {
                                    spacing = AppDimensions.sectionIndent
                                    col {
                                        subTitle { ::content {
                                            "Review Changes From \"${change().title}\""
                                        } }
                                        gravity(Align.Start, Align.Start) - primaryButton - button {
                                            specCenteredText("View Changes")
                                            onClick {
                                                JumpTo.PendingChangeOrder(change()._id)
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        col {
                            existsDefaultFalse { payAppItems().any { it.pending } }
                            forEachUpdating(
                                shared {
                                    val pending = payAppItems().filter { it.pending }.map { it.payApp }.toSet()
                                    payApps().filter { it._id in pending }
                                }
                            ) {
                                toDoSection(Color.fromHexString(ProjectState.InProgress.displayColor)) - stack {
                                    spacing = AppDimensions.sectionIndent
                                    col {
                                        subTitle {
                                            ::content { "Review Draw ${it().number}" }
                                        }
                                        atStart - primaryButton - button {
                                            specCenteredText("View Draw")
                                            onClick {
                                                JumpTo.PayApp(it()._id)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }


                    if (lens == ProjectLens.Contractor) {
                        toDoSection(Color.fromHexString(ProjectState.Accepted.displayColor)) - stack {
                            spacing = AppDimensions.sectionIndent
                            existsDefaultFalse{ canRequestFunding() }
                            col {
                                notificationBar(AppColors.yellow) - body {
                                    inlineKeyValue("Be Aware", "By requesting funds, you confirm readiness to start the project as outlined in the original scope. For any changes in cost or scope, ensure all updates are processed through CrowPay to avoid payment issues or disputes.")
                                }

                                subTitle("Funds Needed")
                                gravity(Align.Start, Align.Start) - primaryButton - button {
                                    specCenteredText("Request Funds")
                                    onClick("Request Funds") { dialogScreenNavigator.navigate(requestFundsDialog()) }
                                }
                            }
                        }

                        toDoSection(Color.fromHexString(ProjectState.PendingStart.displayColor)) - stack {
                            spacing = AppDimensions.sectionIndent
                            existsDefaultFalse{ project().state == ProjectState.PendingStart }
                            col {
                                notificationBar(AppColors.yellow) - body(
                                    """
                                        Starting within 10 days of receiving funds is highly advised. This sets a positive expectation for the homeowner, reinforcing to them that you are prompt and professional.
    
                                        Click "Start Work Item" to start work when ordering materials and/or arriving on site. If ordering materials without simultaneous site arrival is part of starting work, then communicate to the client clearly, so they are not confused with an In Progress project status or concerned you aren't performing.
                                    """.trimIndent()
                                )

                                gravity(Align.Start, Align.Start) - primaryButton - button {
                                    specCenteredText("Start Work Items")
                                    onClick {
                                        dialogScreenNavigator.navigate(
                                            StartWorkItemsDialog(
                                                project(),
                                                lineItems().filter { it.state == LineItemState.NotStarted }.map { it.wraps },
                                                scopes()
                                            )
                                        )
                                    }
                                }
                            }
                        }

                        col {
                            ::exists{ rejectedItems().isNotEmpty() }
                            forEachUpdating(rejectedItems) { line ->
                                toDoSection(Color.fromHexString(LineItemState.NotApproved.displayColor)) - stack {
                                    col {
                                        notificationBar(AppColors.red) - body {
                                            ::content {
                                                """
                                                    Client Message: 
                                                    ${lineItemCompletionMessages().lastOrNull { it.clientMessage }?.message}
                                                """.trimIndent()
                                            }
                                        }
                                        subTitle { ::content { "Rejected Work Item \"${line().name}\"" } }
                                        atStart - primaryButton - button {
                                            specCenteredText("View Work Item")
                                            onClick { JumpTo.LineItem(line()._id) }
                                        }
                                    }
                                }
                            }
                        }

                        toDoSection(Color.fromHexString(ProjectState.SubstantialCompletion.displayColor)) - stack {
                            spacing = AppDimensions.sectionIndent
                            existsDefaultFalse { project().state == ProjectState.SubstantialCompletion }
                            col {
                                notificationBar(AppColors.yellow) - body(
                                    """
                                        Before requesting final completion:
    
                                        $BulletPoint Ensure all work is finished, and any remaining tasks, including 'required' punch list items, are documented or addressed.
                                        $BulletPoint Fix property damage, if applicable, and leave the workspace clean and organized.
                                        $BulletPoint Communicate warranty details and any remaining retention items clearly with the homeowner.
                                        $BulletPoint Consider a final walk-through to resolve any outstanding concerns and build trust.
    
                                        Reminder: The homeowner can still file a dispute at this stage if concerns aren’t resolved, so be thorough and professional in your approach.
                                    """.trimIndent()
                                )
                                subTitle("Request Final Completion")
                                atStart - primaryButton - button {
                                    specCenteredText("Request Complete")
                                    onClick {
                                        if (punchListItems().any { it.required && it.complete == null })
                                            dialogScreenNavigator.navigate(
                                                GenericDialog(
                                                    """
                                                        Unable to proceed with final completion.
    
                                                        All 'required' punch list tasks must be completed before you can request final completion. Return to the Punch List to resolve outstanding items.
                                                    """.trimIndent()
                                                )
                                            )
                                        else
                                            dialogScreenNavigator.navigate(
                                                GenericConfirmationDialog(
                                                    "Submit for Project Completion?",
                                                    message = """
                                                        Requesting final completion will notify the homeowner to review and approve the project as complete.
        
                                                        Before submitting:
        
                                                        $BulletPoint Ensure all 'required' punch list tasks are completed.
                                                        $BulletPoint Confirm the workspace is clean and free of tools or materials.
                                                        $BulletPoint Address any remaining concerns or warranty items with the homeowner.
        
                                                        Once submitted, the project will await homeowner approval for final completion. Any retention funds held in escrow will be released upon the homeowner’s acceptance.
                                                    """.trimIndent(),
                                                    confirmationText = "Request Complete",
                                                    onSubmit = {
                                                        if (it) notNullSession().nonCached.project.requestComplete(project()._id)
                                                    }
                                                )
                                            )
                                    }
                                }
                            }
                        }
                    }
                }
                space(AppDimensions.backgroundIndent)
            }
        }
    }
}