package com.crowpay.views.components.project.work.payApps

import com.crowpay.PayApplication
import com.crowpay.Project
import com.crowpay.ScopeView
import com.crowpay.actuals.AppDimensions
import com.crowpay.utils.Formats
import com.crowpay.utils.format
import com.crowpay.utils.renderDollars
import com.crowpay.utils.tables.trackWeights
import com.crowpay.views.components.activityIcon
import com.crowpay.views.components.scopeSetIcon
import com.crowpay.views.components.space
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import kotlinx.datetime.Instant
import kotlin.math.roundToLong

class PayAppDetails(
    val payApp: PayApplication,
    val project: Project,
    val scopes: List<ScopeView>,
    val items: List<LineItemWithPaymentInfo>
) : Screen {

    val groupedItems = items
        .groupBy { it.lineItem.scopeView }
        .mapKeys { (id, _) -> scopes.find { it._id == id } }
        .entries.sortedBy { it.key?._id }

    override fun ViewWriter.render() {
        dismissBackground {
            spacing = AppDimensions.fullIndent
            scrolls - centered - dialog - stack {
                spacing = AppDimensions.fullIndent
                removeOutline - col {
                    spacing = AppDimensions.majorColumnSpacing

                    var timestamp: Instant? = null
                    for (item in items) {
                        item.payApp?.submittedOrReviewed?.let {
                            if (timestamp == null) timestamp = it
                            else if (it < timestamp!!) timestamp = it
                        }
                    }
                    if (timestamp == null) timestamp = payApp.created

                    title("Draw ${payApp.number}: ${timestamp?.format(Formats.mmddyyyy)}")

                    sizeConstraints(width = 65.rem) - lightSection - col {
                        trackWeights {
                            row {
                                fun header(text: String) = bodyBold {
                                    align = Align.Center
                                    content = text
                                }
                                setColWeight(2.5f) - atBottomCenter - bodyBold("Work Item")
                                setColWeight(1f) - atBottomCenter - header("Approved Amount")
                                setColWeight(1f) - atBottomCenter - header("Past Payments")
                                setColWeight(1f) - atBottomCenter - header("This Draw")
                                setColWeight(1f) - atBottomCenter - header("Total Completed")
                                setColWeight(1f) - atBottomCenter - header("% Completed")
                                setColWeight(1f) - atBottomCenter - header("Remaining")
                                setColWeight(1.1f) - atBottomCenter - header("Retainage (${project.safeRetention.times(100)}%)")
                            }
                            greySeparator()
                            for ((scope, lines) in groupedItems) {
                                if (scope != null) col {
                                    space(0.25)
                                    compact - lightSection - row {
                                        body(scope.scopeTitle)
                                        centered - scopeSetIcon(1.rem)
                                    }
                                }
                                for (line in lines) {
                                    row {
                                        if (line.lineItem.cancelled) themeChoice += GreyedOutSemantic

                                        fun money(amount: Long?) = body {
                                            align = Align.Center
                                            content = amount?.renderDollars() ?: "-"
                                        }

                                        getColWeight - row {
                                            spacing = 0.5.rem
                                            body(line.name)
                                            line.lineItem.changeType?.let {
                                                centered - smallBody(it.postTense)
                                            }
                                        }
                                        getColWeight - money(line.price)
                                        getColWeight - money(line.previousPayments)
                                        getColWeight - money(line.thisPayment)
                                        getColWeight - money(line.workCompleted)
                                        getColWeight - body {
                                            align = Align.Center
                                            content =
                                                if (line.lineItem.cancelled) "-"
                                                else "${line.percentComplete}%"
                                        }
                                        getColWeight - money(line.let { it.price - it.workCompleted })
                                        getColWeight - money(line.retentionToDate(project.safeRetention))
                                    }
                                    greySeparator()
                                }
                            }
                            row {
                                fun moneyBold(amount: Long) = bodyBold {
                                    align = Align.Center
                                    content = amount.renderDollars()
                                }
                                getColWeight - bodyBold("Totals")
                                getColWeight - moneyBold(items.sumOf { it.price })
                                getColWeight - moneyBold(items.sumOf { it.previousPayments })
                                getColWeight - moneyBold(items.sumOf { it.thisPayment ?: 0 })
                                getColWeight - moneyBold(items.sumOf { it.workCompleted })
                                getColWeight - body {
                                    align = Align.Center
                                    content = "${items.percentComplete()}%"
                                }
                                getColWeight - moneyBold(items.sumOf { it.price - it.workCompleted })
                                getColWeight - moneyBold(items.retentionToDate(project.safeRetention))
                            }
                        }
                    }

                    atStart - secondaryButton - button {
                        specCenteredText("Close")
                        onClick("Close") { dialogScreenNavigator.dismiss() }
                    }
                }
            }
        }
    }
}