package com.crowpay.views.screens.contractor

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.extensions.toFileData
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.*
import com.crowpay.utils.validation.Validator
import com.crowpay.views.components.*
import com.crowpay.views.components.files.*
import com.crowpay.views.components.project.work.scope.EditLineItem
import com.crowpay.views.components.project.work.scope.EditLineItems
import com.crowpay.views.dialogs.CreateOrEditNote
import com.crowpay.views.dialogs.GenericConfirmationDialog
import com.crowpay.views.dialogs.GenericDialog
import com.crowpay.views.dialogs.MessageType
import com.crowpay.views.screens.common.LandingScreen
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.render
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.suspendCoroutineCancellable
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.serialization.lensPath
import kotlinx.coroutines.*
import kotlinx.serialization.Serializable
import kotlin.coroutines.resume

@Serializable
enum class FormSection {
    Details,
    Scope,
//    SpecLibrary,
}

class CreateProjectForm() : ContractorScreen by ProjectForm(null)

@Routable("/contractor/project/{projectId}/edit")
class EditProject(val projectId: UUID) : ContractorScreen {
    @QueryParameter
    val selectedTab = Property(FormSection.Details)

    private val form = ProjectForm(projectId, selectedTab)

    override fun ViewWriter.renderMainContent() = form.run { render() }
}

private class ProjectForm(
    val projectId: UUID? = null,
    val selectedTab: Property<FormSection> = Property(FormSection.Details),
) : ContractorScreen {
    val safeProjectID = projectId ?: UUID.random()

    val saved = Property(projectId != null)

    val projectValidator = Validator()
    val clientValidator = Validator()
    val projectDraft = Draft {
        projectId?.let {
            notNullSession().projects[it]()
        } ?: Project(
            _id = safeProjectID,
            contractor = notNullContractor(),
            name = "",
            description = "",
            address = Address(),
            location = "",
        )
    }

    val notes = shared {
        projectId
            ?.let { id ->
                notNullSession().contractorNotes.query(
                    Query { it.project eq id }
                )()
            }
            ?.sortedBy { it.at }
            ?: emptyList()
    }

    val clientProp = LazyProperty {
        Client(
            contractor = projectDraft().contractor,
            name = "",
            email = "",
            phoneNumber = "",
            address = Address()
        )
    }

    val files = LazyProperty { projectDraft().files.toFileData() }

    val editLineItems = EditLineItems(
        safeProjectID,
        saved,
        onSave = { item ->
            if (!saved()) saveProject(false)
        }
    )

    val newClientItem = Client(
        SharedUtils.nullUUID,
        SharedUtils.nullUUID,
        name = "+ New Client",
        email = "",
        phoneNumber = "",
        address = Address()
    )
    val totalPrice = shared {
        editLineItems.forms()
            .sumOf { it.draft().originalPrice }
    }

    val clients = shared {
        notNullSession().clients
            .query(Query(condition { it.contractor eq projectDraft().contractor }))()
    }

    val selectedClient: Writable<Client?> = LazyProperty {
        val p = projectDraft().client ?: return@LazyProperty null
        val c = clients()
        c.find { it._id == p }
    }

    val sameAsClient = Property(false)

    val expanded = shared {
        listOf("projectInfo", "billingInfo").associateWith { Property(true) }
    }

    suspend fun saveProject(includeLineItems: Boolean) {
        coroutineScope {
            projectDraft.modify {
                it.copy(
                    files = files().resolveProjectAttachments(),
                    client = selectedClient()?._id?.takeUnless { it == SharedUtils.nullUUID },
                )
            }
            projectDraft.pushChanges(notNullSession().projects)     // Handles both insertion and modification
            saved.value = true

            if (includeLineItems) {
                editLineItems.forms.awaitOnce().forEach { form: EditLineItem ->
                    launch {
                        val f = form.files.awaitOnce()
                        val og = form.draft.awaitOnce()
                        if (form.draft.changesMade.await() ||
                            og.files.size != f.size ||
                            f.any { it.preview is FilePreview.LocalFile }
                        )
                            form.save()
                    }
                }
            }
        }
    }

    suspend fun ViewWriter.saveProject() {

        val includeLineItems = when {
            !saved() -> true
            editLineItems.hasUnsavedChanges() -> suspendCoroutineCancellable temp@{ cont ->
                dialogScreenNavigator.navigate(
                    GenericConfirmationDialog(
                        header = "Save Work Items",
                        message = "You have unsaved changes in your work items. Do you wish to save those now as well?",
                        confirmationText = "Include Work Items",
                        declineText = "Only Project",
                        messageType = MessageType.Confirmation,
                        onSubmit = { includeWorkItems ->
                            cont.resume(includeWorkItems)
                        }
                    ))
                return@temp {
                    dialogScreenNavigator.dismiss()
                    cont.resume(false)
                }
            }

            else -> false
        }

        saveProject(includeLineItems = includeLineItems)
    }

    override fun ViewWriter.renderMainContent() {

        fun ViewWriter.actionBar() {
            sizeConstraints(minHeight = AppDimensions.actionBarHeight) - darkSection - stack {
                spacing = AppDimensions.sectionIndent / 2

                val projectClient = projectDraft.lensPath { it.client }
                reactiveSuspending {
                    val selected = selectedClient()
                    val fromProject = projectClient()
                    if (selected?._id != fromProject && selected?._id != newClientItem._id)
                        projectClient.set(selected?._id)
                }

                row {
                    spacing = AppDimensions.cornerRadii

                    space(AppDimensions.sectionIndent / 2 - AppDimensions.cornerRadii)

                    primaryButton - button {
                        specCenteredText("Save")

                        onClick {
                            try {
                                saveProject()
                            } catch (e: Exception) {
                                dialogScreenNavigator.navigate(GenericDialog("Failed to save project"))
                            }
                        }
                    }

                    space(AppDimensions.buttonRowSpacing - AppDimensions.cornerRadii * 2)

                    secondaryButton - button {
                        specCenteredText("Save & Close")

                        onClick {
                            try {
                                saveProject()
                                screenNavigator.reset(ContractorProjects())
                            } catch (e: Exception) {
                                dialogScreenNavigator.navigate(GenericDialog("Failed to save project"))
                            }
                        }
                    }

                    space(AppDimensions.buttonRowSpacing - AppDimensions.cornerRadii * 2)

                    textButton - button {
                        specCenteredText("Discard")
                        onClick {
                            dialogScreenNavigator.navigate(
                                GenericConfirmationDialog(
                                    header = "Discard Changes?",
                                    message = "You have changes that have not been submitted. Discarded changes cannot be recovered. Discard changes and leave?",
                                    confirmationText = "Discard & Leave",
                                    messageType = MessageType.Confirmation,
                                    onSubmit = submit@{ confirmed ->
                                        if (!confirmed) return@submit

                                        screenNavigator.reset(ContractorProjects())
                                    }
                                )
                            )
                        }
                    }

                    space(AppDimensions.buttonRowSpacing - AppDimensions.cornerRadii * 2)

                    onlyWhen { saved() } - deleteButton - button {
                        centered - icon(Icon.trash.copy(width = 1.5.rem, height = 1.5.rem), "Delete Project")
                        onClick {
                            dialogScreenNavigator.navigate(
                                GenericConfirmationDialog(
                                    header = "Delete Draft?",
                                    message = "You are about to delete this project draft. Deleted drafts cannot be recovered. Delete this draft?",
                                    confirmationText = "Delete Draft",
                                    messageType = MessageType.Danger,
                                    onSubmit = { confirmed ->
                                        if (confirmed) {
                                            notNullSession().projects[safeProjectID].delete()
                                            screenNavigator.reset(ContractorProjects())

                                        }
                                    }
                                )
                            )
                        }
                    }

                    expanding - space()

                    buttonTheme - primaryButton - button {
                        specCenteredText("Publish")
                        onClick {
                            val p = projectDraft.awaitOnce()
                            val f = files.awaitOnce()
                            if (projectDraft.changesMade.awaitOnce() ||
                                selectedClient.awaitOnce() == newClientItem ||
                                p.files.size != f.size ||
                                files.awaitOnce().any { it.preview is FilePreview.LocalFile } ||
                                editLineItems.hasUnsavedChanges()
                            ) {
                                val confirmed = suspendCoroutineCancellable<Boolean> { cont ->

                                    dialogScreenNavigator.navigate(
                                        GenericConfirmationDialog(
                                            header = "Save Project?",
                                            message = "This project has unsaved changes. You must save the these changes before you can publish.",
                                            confirmationText = "Save Project",
                                            messageType = MessageType.Confirmation,
                                            onSubmit = { confirmed ->
                                                cont.resume(confirmed)
                                            },
                                        )
                                    )
                                    return@suspendCoroutineCancellable { dialogScreenNavigator.dismiss() }
                                }
                                if (confirmed) {
                                    saveProject(includeLineItems = true)
                                    delay(1000)
                                } else
                                    return@onClick
                            }

                            when {
                                editLineItems.forms.await().isEmpty() -> {
                                    dialogScreenNavigator.navigate(
                                        GenericDialog("You must include at least one Work Item in your project.")
                                    )
                                }

                                projectDraft().retention?.let { it < 0 || it > 0.1 } ?: false ->        // TODO: Make a better validation system with error messages
                                    dialogScreenNavigator.navigate(
                                        GenericDialog("Project retention must be between 0% and 10%. Enter a valid retention and try again.")
                                    )

                                projectValidator.allValid.await() && editLineItems.forms.awaitOnce()
                                    .all { form -> form.validator.allValid.await() } -> {

                                    dialogScreenNavigator.navigate(
                                        GenericConfirmationDialog(
                                            header = "Publish Project?",
                                            message = "Publishing a new project will make it available to view and be accepted by the homeowner. Please make sure all project details are correct before proceeding. Once the homeowner accepts, any changes will need mutual agreement.",
                                            confirmationText = "Publish Project",
                                            messageType = MessageType.Confirmation,
                                            onSubmit = {
                                                if (it) {
                                                    val session = notNullSession()
                                                    session.nonCached.project.publishProject(safeProjectID)
                                                    delay(500)
                                                    session.projects[safeProjectID].invalidate()
                                                    screenNavigator.replace(ContractorProject(safeProjectID))
                                                }
                                            }
                                        )
                                    )
                                }

                                else -> {
//                                    println("validation values")
//
//                                    projectValidator.validConditions.forEach {
//                                        println("${it()}")
//                                    }
//                                    println("${projectValidator.validConditions.count { !it() }}")
                                    dialogScreenNavigator.navigate(
                                        GenericDialog(
                                            "One or more of the projects fields is invalid. Resolve any issues and try again."
                                        )
                                    )
                                }
                            }
                        }
                    }

                    space(AppDimensions.sectionIndent / 2 - AppDimensions.cornerRadii)
                }
            }
        }

        fun ViewWriter.projectInfo() {
            col {
                spacing = 0.px
                button {
                    row {
                        centered - expandIcon { expanded()["projectInfo"]?.invoke() ?: false }
                        title {
                            content = "Project Information"
                        }
                    }
                    action = Action("Expand Project List") {
                        expanded()["projectInfo"]?.toggle()
                    }
                }
                onlyWhen{ expanded()["projectInfo"]?.invoke() ?: true } - col {
                    with(projectValidator) {
                        greySeparator()

                        requiredField("Project Name") {
                            val value = projectDraft
                                .lens(
                                    get = { it.name },
                                    modify = { og, it -> og.copy(name = it) }
                                )
                                .validate { it.isNotBlank() }
                            textInput {
                                hint = "Project Name"
                                content bind value
                            }
                        }

                        field2("Job#/PO (Optional)") {
                            textInput {
                                hint = "Project Name"
                                content bind projectDraft
                                    .lens(
                                        get = { it.jobNumber ?: "" },
                                        modify = { og, it -> og.copy(jobNumber = it.takeIf { it.isNotBlank() }) }
                                    )
                            }
                        }

                        space()

                        subTitle("Project Address")

                        row {
                            exists = false
                            ::exists {
                                selectedClient() != null
                            }
                            centered - checkbox { checked bind sameAsClient }
                            centered - body("Same As Client Address")
                            reactiveSuspending {
                                if (sameAsClient()) {
                                    val billing = selectedClient()?.address
                                    if (billing != null) {
                                        projectDraft.set(
                                            projectDraft().copy(
                                                address = billing
                                            )
                                        )
                                    }
                                }
                            }
                        }

                        onlyWhen { !sameAsClient() } - addressForm(
                            projectDraft.lensPath { it.address },
                            projectValidator,
                            AppDimensions.wideCollapseWidth,
                            forceValid = { sameAsClient() }
                        )

                        field2("Neighborhood/District (Optional)") {
                            textInput {
                                hint = "Neighborhood/District"
                                content bind projectDraft
                                    .lens(
                                        get = { it.location ?: "" },
                                        modify = { og, it -> og.copy(location = it.takeIf { it.isNotBlank() }) }
                                    )
                            }
                        }
                    }
                }
            }
        }

        fun ViewWriter.billingInfo() {
            col {
                spacing = 0.px
                button {
                    row {
                        centered - expandIcon { expanded()["billingInfo"]?.invoke() ?: false }
                        title {
                            content = "Billing Information"
                        }
                    }
                    action = Action("Expand Project List") {
                        expanded()["billingInfo"]?.toggle()
                    }
                }

                onlyWhen{ expanded()["billingInfo"]?.invoke() ?: true } - col {
                    with(projectValidator) {
                        greySeparator()

                        requiredField("Client") {
                            val value = selectedClient.validate { it != null }
                            select {
                                bind(
                                    value,
                                    shared {
                                        listOf(
                                            null,
                                            newClientItem
                                        ) + clients()
                                    }) {
                                    it?.name?.takeIf { it.isNotBlank() }
                                        ?: it?.email?.takeIf { it.isNotBlank() }
                                        ?: it?.phoneNumber?.takeIf { it.isNotBlank() }
                                            ?.let { PhoneNumberFormat.USA.format(it.filter { it.isDigit() }) }
                                        ?: "Select Client"
                                }
                            }
                        }
                    }


                    onlyWhen {
                        val s = selectedClient()
                        s != null && s !== newClientItem
                    } - col {
                        keyValue("Client Name") { selectedClient()?.name }
                        keyValue("Address") { selectedClient()?.address?.toString() }
                        keyValue("Email") { selectedClient()?.email }
                        keyValue("Phone") { selectedClient()?.phoneNumber?.let { PhoneNumberFormat.USA.format(it.filter { it.isDigit() }) } }
                    }

                    onlyWhen {
                        val s = selectedClient()
                        s === newClientItem
                    } - col {


                        with(clientValidator) {
                            subTitle {
                                content = "Basic Info"
                            }

                            requiredField("Name") {
                                val value = clientProp
                                    .lens(
                                        get = { it.name },
                                        modify = { og, it -> og.copy(name = it) }
                                    )
                                    .validate { it.isNotBlank() }
                                textInput {
                                    hint = "Name"
                                    content bind value
                                }
                            }
                            requiredField("Email") {
                                val value = clientProp
                                    .lens(
                                        get = { it.email },
                                        modify = { og, it -> og.copy(email = it) }
                                    )
                                    .validate { it.matches(SharedUtils.emailPattern) }
                                textInput {
                                    hint = "Email"
                                    content bind value
                                }
                            }
                            requiredField("Phone Number") {
                                val value = clientProp
                                    .lensPath { it.phoneNumber }
                                    .validate { it.length == 10 }
                                phoneNumberInput {
                                    hint = "Phone Number"
                                    content bind value
                                }
                            }

                            space()

                            subTitle {
                                content = "Address"
                            }

                            requiredField("Line 1") {
                                val value =
                                    clientProp
                                        .lens(
                                            get = { it.address.line1 },
                                            modify = { og, it -> og.copy(address = og.address.copy(line1 = it)) }
                                        )
                                        .validate { it.isNotBlank() }
                                textInput {
                                    hint = "Line 1"
                                    content bind value
                                }
                            }
                            field2("Line 2 (Optional)") {
                                textInput {
                                    hint = "Line 2"
                                    content bind clientProp
                                        .lens(
                                            get = { it.address.line2 ?: "" },
                                            modify = { og, it -> og.copy(address = og.address.copy(line2 = it.takeIf { it.isNotBlank() })) }
                                        )

                                }
                            }
                            requiredField("City") {
                                val value =
                                    clientProp
                                        .lens(
                                            get = { it.address.city },
                                            modify = { og, it -> og.copy(address = og.address.copy(city = it)) }
                                        )
                                        .validate { it.isNotBlank() }
                                textInput {
                                    hint = "City"
                                    content bind value
                                }
                            }
                            rowCollapsingToColumn(AppDimensions.pageWidth * 0.7) {
                                expanding - label2("State") {
                                    fieldTheme - select {
                                        bind(
                                            clientProp
                                                .lens(
                                                    get = { it.address.state },
                                                    modify = { og, it ->
                                                        og.copy(address = og.address.copy(state = it))
                                                    }
                                                ),
                                            Constant(State.entries)
                                        ) { it.display }
                                    }
                                }
                                expanding - requiredField("Zipcode") {
                                    val value =
                                        clientProp
                                            .lens(
                                                get = { it.address.zipcode },
                                                modify = { og, it ->
                                                    og.copy(
                                                        address = og.address.copy(
                                                            zipcode = it
                                                        )
                                                    )
                                                }
                                            )
                                            .validate { it.isNotBlank() }
                                    textInput {
                                        hint = "Zipcode"
                                        content bind value
                                    }
                                }
                            }
                            tertiaryButton - button {
                                ::enabled{ allValid() }
                                specCenteredText("Save Client")
                                onClick {
                                    val newClient = clientProp()
                                    selectedClient.set(newClient)
                                    notNullSession().clients.insert(newClient)
                                    selectedClient.set(newClient)
                                    clientProp.reset()
                                }
                            } in gravity(Align.End, Align.Center)
                        }
                    }
                }
            }
        }

        scrolls - col {
            spacing = 0.dp

            launch {
                val session = notNullSession.await()
                val p = projectDraft.published()
                if (selectedContractor.await() != p.contractor) {
                    val self = session.self.await()
                    if (self.membership != null && self.membership?.contractor == p.contractor)
                        selectedContractor.set(p.contractor)
                    else
                        screenNavigator.reset(LandingScreen())
                }
            }

            sizeConstraints(width = AppDimensions.pageWidth) - col {
                spacing = 0.dp

                title {
                    content = "Create Project"
                }

                space(AppDimensions.majorColumnSpacing)

                actionBar()

                space(AppDimensions.majorColumnSpacing)

                rowCollapsingToColumn(AppDimensions.normalCollapseWidth) {
                    expanding - projectInfo()
                    expanding - billingInfo()
                }

                space(AppDimensions.majorColumnSpacing)

                with(projectValidator) {
                    val value = projectDraft
                        .lensPath { it.retention }
                        .lens(
                            get = { it?.toDouble()?.times(100) },
                            set = { it?.toFloat()?.takeUnless { it == 0f }?.div(100) }
                        )
                        .validate {
                            if (it == null) true
                            else 0 <= it && it <= 10
                        }

                    field2("Retention (Percent)") {
                        numberInput {
                            content bind value
                            range = 0.0..10.0
                            hint = "0% to 10%"
                            keyboardHints = KeyboardHints.integer
                        }
                    }
                }

                space(AppDimensions.majorColumnSpacing)

                col {
                    label2("Total Price (Calculated from line items)") {
                        body {
                            ::content{ totalPrice().renderDollars() }
                        }
                    }
                    onlyWhen { projectDraft().retention != null } - col {
                        label2("Total Retention") {
                            body {
                                ::content content@{
                                    val t = totalPrice()
                                    val r = projectDraft().retention ?: 0f
                                    (t * r).toLong().renderDollars()
                                }
                            }
                        }
                    }
                }

                space(AppDimensions.majorColumnSpacing)

                workTabs - row {
                    spacing = 4.dp
                    expanding - buttonTheme - radioToggleButton {
                        checked bind selectedTab.equalTo(FormSection.Details)
                        body {
                            align = Align.Center
                            content = "Details"
                        }
                    }
                    expanding - buttonTheme - radioToggleButton {
                        checked bind selectedTab.equalTo(FormSection.Scope)
                        body {
                            align = Align.Center
                            content = "Scope"
                        }
                    }
                }

                col {
                    sizeConstraints(minHeight = 10.rem) - swapView {
                        swapping(
                            current = { selectedTab<FormSection>() },
                            views = { tab ->
                                when (tab) {
                                    FormSection.Details -> projectDetailForm()
                                    FormSection.Scope -> editLineItems.render(this@swapView)
                                }
                            }
                        )
                    }
                    space()
                }

                actionBar()
            }
        }
    }


    fun ViewWriter.projectDetailForm() {
        val description = projectDraft.lensPath { it.description }
        col {
            spacing = 1.rem
            space(AppDimensions.majorColumnSpacing - spacing!!)
            with(projectValidator) {
                requiredField("Project Description") {
                    val value = description.validate { it.isNotBlank() }
                    sizeConstraints(minHeight = 7.rem) - expanding - textArea {
                        hint = "Project Description"
                        content bind value
                    }
                }

                label2("Contractor Notes (Visible only to you)") {
                    fieldTheme - sizeConstraints(minHeight = 10.rem) - row {
                        expanding - stack {
                            centered - bold - subTitle {
                                ::exists { notes().isEmpty() }
                                content = "No Notes"
                            }
                            scrollsHorizontally - row {
                                spacing = 1.25.rem
                                exists = false
                                ::exists { notes().isNotEmpty() }

                                forEachUpdating(notes, 0) { note ->
                                    val index = shared { notes().indexOf(note()) + 1 }
                                    sizeConstraints(maxWidth = 25.rem) - padded - col {
                                        spacing = 1.rem
                                        row {
                                            spacing = AppDimensions.cornerRadii
                                            col {
                                                spacing = AppDimensions.cornerRadii
                                                subTitle {
                                                    ::content { "Private Note ${index()}" }
                                                }
                                                centered - smallBody {
                                                    ::content { note().at.formatLong() }
                                                }
                                            }
                                            atTop - row {
                                                spacing = 0.5.rem
                                                space()
                                                buttonTheme - button {
                                                    themeChoice += ThemeDerivation { it.copy(outlineWidth = 0.px).withoutBack }
                                                    spacing = 1.dp
                                                    centered - icon(
                                                        Icon.edit.copy(width = 1.5.rem, height = 1.5.rem),
                                                        "Edit"
                                                    )
                                                    onClick {
                                                        dialogScreenNavigator.navigate(
                                                            CreateOrEditNote(
                                                                note(),
                                                                "Add notes, pictures, or files that are for INTERNAL team only.  (ie. client or property considerations for managers and crews.)"
                                                            )
                                                        )
                                                    }
                                                }
                                                deleteButton - button {
                                                    spacing = 1.dp
                                                    centered - icon(
                                                        Icon.trash.copy(width = 1.5.rem, height = 1.5.rem),
                                                        "Delete"
                                                    )
                                                    onClick {
                                                        dialogScreenNavigator.navigate(
                                                            GenericConfirmationDialog(
                                                                "Delete Note",
                                                                "Are you sure you want to delete this note?",
                                                                messageType = MessageType.Danger,
                                                                confirmationText = "Delete"
                                                            ) {
                                                                notNullSession().contractorNotes[note()._id].delete()
                                                            }
                                                        )
                                                    }
                                                }
                                            }
                                        }

                                        sizeConstraints(maxHeight = 20.rem) - body {
                                            wraps = true
                                            ellipsis = true
                                            ::content{ note().message }
                                        }

                                        stack {
                                            val f = note.lens { it.files }
                                            ::exists { f().isNotEmpty() }
                                            label2("Files: ") {
                                                renderFiles(
                                                    f,
                                                    AppDimensions.smallImagePreview
                                                )
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        sizeConstraints(width = AppDimensions.largeImagePreview / 3) - tertiaryButton - button {
                            iconAndAction(Icon.add, "Add Note") {
                                dialogScreenNavigator.navigate(
                                    CreateOrEditNote(
                                        projectDraft()._id,
                                        null,
                                        subHeader = "Add notes, pictures, or files that are for INTERNAL team only.  (ie. client or property considerations for managers and crews.)"
                                    ) {
                                        println("Save")
                                        saveProject(includeLineItems = false)
                                    }
                                )
                            }
                        }
                    }
                }

                label2("Project Files (Images, Bids, Plans, Specs)") {
                    fieldTheme - fileManager(
                        files,
                        "No Files Uploaded"
                    ) { notNullSession().nonCached.project::uploadFileForRequest }
                }
            }
            space()
        }
    }
}