package com.crowpay.utils

import com.lightningkite.kiteui.reactive.BaseListenable
import com.lightningkite.kiteui.reactive.ImmediateWritable
import com.lightningkite.kiteui.reactive.Listenable
import com.lightningkite.kiteui.reactive.lens

/**
 * A wrapper around [ArrayList] that signals its listeners whenever it is mutated
 * */
class SignalingList<T>(private val list: ArrayList<T>): MutableList<T> by list, ImmediateWritable<List<T>>, BaseListenable() {
    constructor() : this(ArrayList<T>())
    constructor(vararg startingItems: T) : this(ArrayList(startingItems.toList()))
    constructor(elements: Collection<T>) : this(ArrayList(elements))

    override var value: List<T>
        get() = list
        set(value) {
            list.clear()
            list.addAll(value)
            invokeAllListeners()
        }

    private fun <V> signal(operation: MutableList<T>.()->V): V = list.operation().also { invokeAllListeners() }

    // Used for methods that return if the list was modified
    private fun signalChange(operation: MutableList<T>.()->Boolean): Boolean =
        list.operation().also {
            if (it) invokeAllListeners()
        }

    override fun clear() { if (list.isNotEmpty()) signal { clear() } }
    override fun removeAt(index: Int): T = signal { removeAt(index) }
    override fun set(index: Int, element: T): T = signal { set(index, element) }
    override fun retainAll(elements: Collection<T>): Boolean = signalChange { retainAll(elements) }
    override fun removeAll(elements: Collection<T>): Boolean = signalChange { removeAll(elements) }
    override fun addAll(elements: Collection<T>): Boolean = signalChange { addAll(elements) }
    override fun addAll(index: Int, elements: Collection<T>): Boolean = signalChange { addAll(index, elements) }
    override fun add(index: Int, element: T) = signal { add(index, element) }
    override fun add(element: T): Boolean = signal { add(element) }
    override fun remove(element: T): Boolean = signalChange { remove(element) }

    private inner class Contains(val element: T) : ImmediateWritable<Boolean>, Listenable by this {
        override var value: Boolean
            get() = element in list
            set(value) {
                if (value != element in list) {
                    if (value) add(element)
                    else remove(element)
                }
            }
    }

    fun containsWritable(element: T): ImmediateWritable<Boolean> = Contains(element)
}