package com.crowpay.views.components

import com.crowpay.views.theming.body
import com.crowpay.views.theming.lightSection
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import kotlinx.coroutines.launch

fun <Full, ID> ViewWriter.multiselect(
    query: suspend (input: String) -> List<Full>,
    pull: ReactiveContext.(ID) -> Full,
    getId: (Full) -> ID,
    hintText: String? = null,
    toString: (input: Full) -> String,
    render: ViewWriter.(input: Readable<Full>) -> Unit = { item ->
        gravity(Align.Start, Align.Center) - text {
            ::content { item.await().let(toString) }
        }
    },
    items: Writable<Set<ID>>,
    vertical: Boolean = true,
    enabled: ReactiveContext.() -> Boolean = { true },
    setup: RView.() -> Unit = {}
) {
    compact - row {
        dynamicTheme { if (enabled()) null else DisabledSemantic }
        if(vertical) {
            expanding - col {
                spacing = 0.25.rem
                forEachUpdating(shared { items.await().toList() }) { item ->
                    lightSection - row {
                        spacing = 0.25.rem
                        space(0.25)
                        expanding - gravity(Align.Start, Align.Center) - body {
                            ::content { pull(item.await()).let(toString) }
                        }
                        gravity(Align.Center, Align.Center) - button {
                            spacing = 0.25.rem
                            sizeConstraints(width = 1.rem, height = 1.rem) - icon({ Icon.close }, "remove option")
                            onClick {
                                items set items.await().minus(item.await())
                            }
                        }
                    }
                }
            }
        } else {
            expanding - scrollsHorizontally - row {
                forEachUpdating(shared { items.await().toList() }) { item ->
                    centered - card - row {
                        spacing = 0.25.rem
                        space(0.25)
                        gravity(Align.Start, Align.Center) - body {
                            ::content { pull(item.await()).let(toString) }
                        }
                        gravity(Align.Center, Align.Center) - button {
                            spacing = 0.25.rem
                            sizeConstraints(width = 1.rem, height = 1.rem) - icon({ Icon.close }, "remove option")
                            onClick {
                                items set items.await().minus(item.await())
                            }
                        }
                    }
                }
            }
        }
        centered - subtext {
            ::exists{ items.await().isEmpty() }
            content = hintText ?: "None selected"
        }
        compact - gravity(Align.Center, Align.Center) - menuButton {
            preferredDirection = PopoverPreferredDirection.belowLeft
            requireClick = true
            ::exists { enabled() }
            sizeConstraints(width = 1.rem, height = 1.rem) - icon({ Icon.add }, "Add item")
            this.opensMenu {
                val search = Property("")
                col {
                    centered - h2("Select")
                    fieldTheme - textField {
                        hint = "Search"
                        content bind search
                        requestFocus()
                        action = Action("Select", Icon.done) {
                            query(search.value).firstOrNull()?.let { v ->
                                val id = v.let(getId)
                                items.modify {
                                    if (it.contains(id)) it.minus(id) else it.plus(id)
                                }
                            }
                        }
                    }

                    sizeConstraints(
                        width = 20.rem,
                        height = 15.rem
                    ) - recyclerView {
                        children(
                            sharedSuspending { query(search.debounce(500).await()) }
                        ) { item ->
                            toggleButton {
                                spacing = 0.5.rem
                                render(item)
                                checked bind items.containsDynamic { item.await().let(getId) }
                            }
                        }
                    }
                }
            }
        }
        setup()
    }
}


fun <Full, ID> ViewWriter.multiselectItems(
    query: suspend (input: String) -> List<Full>,
    pull: ReactiveContext.(ID) -> Full,
    getId: (Full) -> ID,
    toString: (input: Full) -> String,
    render: ViewWriter.(input: Readable<Full>) -> Unit = { item ->
        gravity(Align.Start, Align.Center) - text {
            ::content { item.await().let(toString) }
        }
    },
    items: Writable<Set<Full>>,
    vertical: Boolean = true,
    enabled: ReactiveContext.() -> Boolean = { true },
    setup: RView.() -> Unit = {}
) {
    compact - row {
        dynamicTheme { if (enabled()) null else DisabledSemantic }
        if(vertical) {
            expanding - col {
                spacing = 0.25.rem
                forEachUpdating(shared { items.await().toList() }) { item ->
                    lightSection - row {
                        spacing = 0.25.rem
                        space(0.25)
                        expanding - centered - subtext {
                            ::content { item().let(toString) }
                        }
                        gravity(Align.Center, Align.Center) - button {
                            spacing = 0.25.rem
                            sizeConstraints(width = 1.rem, height = 1.rem) - icon({ Icon.close }, "remove option")
                            onClick {
                                items set items.await().minus(item.await())
                            }
                        }
                    }
                }
            }
        } else {
            expanding - scrollsHorizontally - row {
                forEachUpdating(shared { items.await().toList() }) { item ->
                    centered - important - row {
                        spacing = 0.25.rem
                        space(0.25)
                        gravity(Align.Start, Align.Center) - subtext {
                            ::content { item().let(toString) }
                        }
                        gravity(Align.Center, Align.Center) - button {
                            spacing = 0.25.rem
                            sizeConstraints(width = 1.rem, height = 1.rem) - icon({ Icon.close }, "remove option")
                            onClick {
                                items set items.await().minus(item.await())
                            }
                        }
                    }
                }
            }
        }
        centered - subtext {
            ::exists{ items.await().isEmpty() }
            content = "None selected"
        }
        compact - gravity(Align.Center, Align.Center) - menuButton {
            preferredDirection = PopoverPreferredDirection.belowLeft
            requireClick = true
            ::exists { enabled() }
            sizeConstraints(width = 1.rem, height = 1.rem) - icon({ Icon.add }, "Add item")
            this.opensMenu {
                val search = Property("")
                col {
                    centered - h2("Select")
                    fieldTheme - textField {
                        hint = "Search"
                        content bind search
                        requestFocus()
                        action = Action("Select", Icon.done) {
                            query(search.value).firstOrNull()?.let { v ->
                                val id = v.let(getId)
                                items.modify {
                                    val ids = it.map { getId(it) }
                                    if (ids.contains(id)) it.minus(v) else it.plus(v)
                                }
                            }
                        }
                    }

                    sizeConstraints(
                        width = 15.rem,
                        height = 20.rem
                    ) - recyclerView {
                        children(
                            sharedSuspending { query(search.debounce(500).await()) }
                        ) { item ->
                            toggleButton {
                                render(item)
                                checked bind items.containsDynamic { item() }
                            }
                        }
                    }
                }
            }
        }
        setup()
    }
}

fun <Full, ID> ViewWriter.searchSelect(
    query: suspend (input: String) -> List<Full>,
    pull: ReactiveContext.(ID) -> Full,
    getId: (Full) -> ID,
    toString: (input: Full) -> String,
    render: ViewWriter.(input: Readable<Full>) -> Unit = { item ->
        gravity(Align.Start, Align.Center) - text {
            ::content { item.await().let(toString) }
        }
    },
    selected: Writable<ID?>,
    enabled: ReactiveContext.() -> Boolean = { true },
    setup: RView.() -> Unit = {}
) {
    compact - row {
        dynamicTheme { if (enabled()) null else DisabledSemantic }
        expanding - centered - text {
            wraps = false
            ellipsis = true
            ::content {
                selected()?.let {
                    pull(it).let(toString)
                } ?: "None Selected"
            }
        }
        compact - gravity(Align.Center, Align.Center) - menuButton {
            preferredDirection = PopoverPreferredDirection.belowLeft
            requireClick = true
            ::exists { enabled() }
            sizeConstraints(width = 1.rem, height = 1.rem) - icon {
                ::source { if(selected() == null) Icon.add else Icon.close }
                ::description { if(selected() == null) "Pick" else "Remove" }
            }
            this.opensMenu {
                launch { selected set null }
                val search = Property("")
                col {
                    centered - h2("Select")
                    fieldTheme - textField {
                        hint = "Search"
                        content bind search
                        requestFocus()
                        action = Action("Select", Icon.done) {
                            query(search.value).firstOrNull()?.let { v ->
                               selected set v.let(getId)
                            }
                        }
                    }

                    sizeConstraints(
                        width = 15.rem,
                        height = 20.rem
                    ) - recyclerView {
                        children(
                            sharedSuspending { query(search.debounce(500).await()) }
                        ) { item ->
                            toggleButton {
                                render(item)
                                checked bind shared { item().let(getId) == selected() }
                                    .withWrite {
                                        if(it) selected set item().let(getId)
                                        else selected set null
                                    }
                            }
                        }
                    }
                }
            }
        }
        setup()
    }
}

infix fun <T> Writable<Set<T>>.containsDynamic(value: ReactiveContext.() -> T): Writable<Boolean> =
    shared {
        value() in this@containsDynamic()
    }.withWrite { on ->
        if (on) this@containsDynamic.set(this@containsDynamic.await() + value())
        else this@containsDynamic.set(this@containsDynamic.await() - value())
    }