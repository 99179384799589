package com.crowpay.views.dialogs

import com.crowpay.LineItemCompletionMessage
import com.crowpay.Project
import com.crowpay.actuals.AppDimensions
import com.crowpay.utils.AdjustedLineItem
import com.crowpay.views.components.project.work.scope.LineItemCompletionForm
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.dialog
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding

class NotReadyConfirmation(
    val lineItem: AdjustedLineItem,
    val project: Project,
    val onNotReady: suspend ()->Unit
) : Screen {
    override fun ViewWriter.render() {
        dismissBackground {
            centered - dialog - stack {
                spacing = AppDimensions.fullIndent
                col {
                    title("Are You Sure?")

                    body("You can request adjustment and add to punch list or let your contractor know it isn't ready.")

                    notificationBar(AppColors.blue) - col {
                        body {
                            content = "Examples of request adjustments"
                        }
                        body {
                            content = "Examples of Not Ready"
                        }
                    }

                    space(0.5)

                    body("What do you want to do?")

                    row {
                        secondaryButton - button {
                            specCenteredText("Request Adjustment")
                            onClick {
                                dialogScreenNavigator.reset(
                                    MinorAdjustmentsDialog(lineItem.wraps)
                                )
                            }
                        }
                        expanding - space()
                        dangerButton - button {
                            specCenteredText("Not Ready")
                            onClick {
                                onNotReady()
                            }
                        }
                        textButton - button {
                            specCenteredText("Go Back")
                            onClick { dialogScreenNavigator.dismiss() }
                        }
                    }
                }
            }
        }
    }
}