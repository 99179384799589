package com.crowpay.views.components.project.work.scope

import com.crowpay.*
import com.crowpay.sdk.json
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.*
import com.crowpay.views.components.files.FilePreview
import com.crowpay.views.components.scopeSetIcon
import com.crowpay.views.dialogs.EditScopeViewDialog
import com.crowpay.views.dialogs.GenericConfirmationDialog
import com.crowpay.views.dialogs.NewScopeViewDialog
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.HttpMethod
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.render
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.typed.BulkRequest
import kotlinx.serialization.encodeToString
import kotlin.collections.get


class EditLineItems(
    val projectId: UUID,
    val allowSave: Readable<Boolean> = Constant(true),
    val onSave: suspend (LineItem) -> Unit = {}
) : Screen {
    val scopes = shared {
        notNullSession().scopeViews.query(
            Query(
                condition { it.project eq projectId },
                limit = Int.MAX_VALUE
            )
        )()
    }

    val forms = LazyPropertySuspending {
        notNullSession().nonCached.lineItem
            .query(
                Query(
                    condition = condition<LineItem> { it.project eq projectId },
                    orderBy = sort { it.order.ascending() }
                ))
            .map { line -> createForm(line) }
    }

    val scopeGroups = shared {
        val allScopes = scopes().associateBy { it._id }
        forms()
            .groupBy { it.draft().scopeView }
            .mapKeys { (id, _) -> allScopes[id] }
            .toList()
            .sortedBy { it.first?.scopeTitle }
    }


    fun createForm(line: LineItem? = null): EditLineItem {
        return EditLineItem(
            ogLine = line,
            projectId = projectId,
            allowSave = allowSave,
            reorder = reorder@{ formId, direction ->
                val allForms = forms.value
                val currentIndex =
                    allForms
                        .indexOfFirst { it.draft()._id == formId }
                        .takeUnless { it < 0 }
                        ?: return@reorder
                forms.set(
                    when {
                        direction == ReorderDirection.UP && currentIndex != 0 -> {
                            val temp = allForms.toMutableList()
                            val toMove = temp.removeAt(currentIndex)
                            temp.add(currentIndex - 1, toMove)
                            temp
                        }

                        direction == ReorderDirection.DOWN && currentIndex != allForms.lastIndex -> {
                            val temp = allForms.toMutableList()
                            val toMove = temp.removeAt(currentIndex)
                            temp.add(currentIndex + 1, toMove)
                            temp
                        }

                        else -> {
                            allForms
                        }
                    }
                )

                orderEdited.invokeAll()
            },
            canMove = { formId ->
                forms().let { items ->
                    setOfNotNull(
                        if (items.lastOrNull()?.id != formId) ReorderDirection.DOWN else null,
                        if (items.firstOrNull()?.id != formId) ReorderDirection.UP else null
                    )
                }
            },
            onRemove = { formId ->
                forms.value = forms.value.filter { it.draft()._id != formId }
            },
            onSave = onSave
        )
    }

    val orderEdited = BasicListenable()
    var justAdded: UUID? = null

    suspend fun hasUnsavedChanges(): Boolean {
        return forms.awaitOnce().any { form ->
            val files = form.files.awaitOnce()
            val og = form.draft.awaitOnce()
            form.draft.changesMade.await() ||
                    og.files.size != files.size ||
                    files.any { it.preview is FilePreview.LocalFile }
        }
    }

    override fun ViewWriter.render() {
        col {
            var initialLoad = true
            reactiveSuspending {
                rerunOn(orderEdited.debounce(800))
                if (initialLoad) {
                    initialLoad = false
                    return@reactiveSuspending
                }
                notNullSession()
                    .bulkRequest2(
                        forms.value
                            .mapIndexed { index, form ->
                                val id = form.draft()._id
                                id.toString() to BulkRequest(
                                    "/line-items/rest/${id}",
                                    HttpMethod.PATCH.name,
                                    json.encodeToString(modification<LineItem> { it.order assign index })
                                )
                            }
                            .toMap()
                    )
            }
            space(0.2)
            atEnd - row {
                suspend fun createScopeView(scopeSet: Boolean) =
                    NewScopeViewDialog(
                        forms().mapNotNull {
                            val line = it.draft()
                            if (line.scopeView == null) AdjustedLineItem(line)
                            else null
                        },
                        scopeSet
                    ) { scope, selected ->
                        forms()
                            .filter { it.id in selected }
                            .forEach { form ->
                                form.draft.modify {
                                    it.copy(
                                        scopeView = scope._id,
                                        scopeSet = scope.scopeSet
                                    )
                                }
                            }
                    }

                secondaryButton - button {
                    specCenteredText("+ Scope Set")
                    onClick {
                        dialogScreenNavigator.navigate(createScopeView(true))
                    }
                }
                secondaryButton - button {
                    specCenteredText("+ Scope View")
                    onClick {
                        dialogScreenNavigator.navigate(createScopeView(false))
                    }
                }
                tertiaryButton - button {
                    specCenteredText("+ New Work Item")
                    onClick {
                        val newForm = createForm(null)
                        forms.value += newForm
                        justAdded = newForm.draft()._id
                        newForm.expanded.value = true
                    }
                }
            }
            space()

            col {
                spacing = theme.spacing * 1.2
                forEach(scopeGroups) { (scope, items) ->
                    fun ViewWriter.renderForms(): RowOrCol =
                        col {
                            spacing = 0.px
                            for (item in items) {
                                item.render(this)
                                greySeparator()
                            }
                        }

                    if (scope != null) {
                        compact - col {
                            lightSection - row {
                                bodyBold(scope.scopeTitle)
                                if (scope.scopeSet) centered - scopeSetIcon(1.rem)
                                expanding - body {
                                    align = Align.End
                                    ::content { items.sumOf { it.draft().price }.renderDollars() }
                                }
                                button {
                                    spacing = 2.dp
                                    icon(Icon.edit.resize(1.2.rem), "Edit Scope")
                                    onClick {
                                        val options = forms().mapNotNull { form ->
                                            val line = form.draft()
                                            if (line.scopeView.let { it == null || it == scope._id }) AdjustedLineItem(line)
                                            else null
                                        }

                                        dialogScreenNavigator.navigate(
                                            EditScopeViewDialog(
                                                options,
                                                scope
                                            ) { scope, selection ->

                                                forms()
                                                    .filter { form -> form.id in options.map { it._id } }
                                                    .forEach { form ->
                                                        form.draft.modify {
                                                            val inScope = it._id in selection
                                                            it.copy(
                                                                scopeView = if (inScope) scope._id else null,
                                                                scopeSet = if (inScope) scope.scopeSet else null
                                                            )
                                                        }
                                                    }
                                            }
                                        )
                                    }
                                }
                                button {
                                    spacing = 2.dp
                                    icon(Icon.trash.resize(1.2.rem), "Delete Scope")
                                    onClick {
                                        dialogScreenNavigator.navigate(
                                            GenericConfirmationDialog(
                                                "Delete ${scope.scopeTitle}?",
                                                "This cannot be undone.",
                                                confirmationText = "Delete"
                                            ) { confirmed ->
                                                if (!confirmed) return@GenericConfirmationDialog
                                                notNullSession().scopeViews[scope._id].delete()
                                                forms().forEach { form ->
                                                    val draft = form.draft()
                                                    if (draft.scopeView == scope._id) form.draft.modify {
                                                        it.copy(
                                                            scopeView = null,
                                                            scopeSet = null
                                                        )
                                                    }
                                                }

                                            }
                                        )
                                    }
                                }
                            }
                            if (!scope.description.isNullOrBlank()) body("Description: ${scope.description}")
                            renderForms()
                        }
                    } else {
                        renderForms()
                    }
                }
            }
        }
    }
}