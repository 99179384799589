package com.crowpay.views.components.project

import com.crowpay.ContractorNote
import com.crowpay.actuals.AppDimensions
import com.crowpay.edit
import com.crowpay.extensions.withSpacing
import com.crowpay.sdk.notNullSession
import com.crowpay.trash
import com.crowpay.utils.formatLong
import com.crowpay.views.components.files.renderFiles
import com.crowpay.views.components.label2
import com.crowpay.views.dialogs.CreateOrEditNote
import com.crowpay.views.dialogs.GenericConfirmationDialog
import com.crowpay.views.dialogs.MessageType
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon

fun ViewWriter.renderContractorNotes(
    notes: Readable<List<ContractorNote>>,
    groupName: ReactiveContext.() -> String,
    lens: ProjectLens
) {
    stack {
        stack {
            ::exists { notes().isEmpty() }
            sizeConstraints(height = 5.rem) - centered - colored(AppColors.grey.light1) - bold - subTitle("No Notes")
        }
        withSpacing(1.rem) - col {
            exists = false
            ::exists { notes().isNotEmpty() }

            forEachUpdating(notes, 0) { note ->
                col {
                    spacing = 1.rem
                    row {
                        subTitle { ::content { "${groupName()} Notes:" } }
                        centered - smallBody {
                            ::content { note().at.formatLong() }
                        }
                        if (lens == ProjectLens.Contractor) row {
                            spacing = 0.5.rem
                            space()
                            buttonTheme - button {
                                spacing = 1.dp
                                centered - icon(Icon.edit.copy(width = 1.5.rem, height = 1.5.rem), "Edit")
                                onClick {
                                    dialogScreenNavigator.navigate(CreateOrEditNote(note(), null))
                                }
                            }
                            deleteButton - button {
                                spacing = 1.dp
                                centered - icon(
                                    Icon.trash.copy(width = 1.5.rem, height = 1.5.rem),
                                    "Delete"
                                )
                                onClick {
                                    dialogScreenNavigator.navigate(
                                        GenericConfirmationDialog(
                                            "Delete Note",
                                            "Are you sure you want to delete this note?",
                                            messageType = MessageType.Danger,
                                            confirmationText = "Delete"
                                        ) {
                                            if (it) notNullSession().contractorNotes[note()._id].delete()
                                        }
                                    )
                                }
                            }
                        }
                    }

                    body { ::content{ note().message } }

                    stack {
                        val files = note.lens { it.files }
                        ::exists{ files().isNotEmpty() }
                        label2("Files: ") {
                            renderFiles(
                                files,
                                AppDimensions.smallImagePreview
                            )
                        }
                    }

                    greySeparator {
                        ::exists { note()._id != notes().lastOrNull()?._id }
                    }
                }
            }
        }
    }
}