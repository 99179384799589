package com.crowpay.views.dialogs

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.SignalingList
import com.crowpay.utils.resize
import com.crowpay.utils.shareFilter
import com.crowpay.utils.validation.validating
import com.crowpay.views.components.project.work.punchList.renderNewPunchListItems
import com.crowpay.views.components.requiredField
import com.crowpay.views.components.space
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.models.Action
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.bind
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.uuid


class MinorAdjustmentsDialog(
    val lineItems: List<LineItem>,
    val name: String,
    val dismiss: ViewWriter.()->Unit = { dialogScreenNavigator.dismiss() },
) : Screen {
    constructor(lineItem: LineItem) : this(listOf(lineItem), name = lineItem.name)

    val project = lineItems.map { it.project }.toSet().singleOrNull() ?: throw IllegalStateException(
        if (lineItems.isEmpty()) "MinorAdjustmentDialog cannot have an empty list of line items"
        else "MinorAdjustmentDialog cannot work on multiple projects"
    )
    val newItems = SignalingList(listOf(Property("")))

    override fun ViewWriter.render() {
        dismissBackground {
            dialog - centered - stack {
                spacing = AppDimensions.fullIndent
                removeOutline - sizeConstraints(width = 40.rem) - col {
                    spacing = AppDimensions.sectionIndent

                    title("Minor Adjustments")

                    body("""
                        Add any minor fixes/tasks you would like added to the project below.
                    """.trimIndent())

                    col {
                        row {
                            atBottom - expanding - subTitle("Tasks")
                            centered - tertiaryButton - button {
                                specCenteredText("+ Task")
                                onClick("Add Task") {
                                    newItems.add(Property(""))
                                }
                            }
                        }
                        greySeparator()
                        sizeConstraints(minHeight = 6.rem) - compact - col {
                            forEachUpdating(newItems) { task ->
                                lightSection - row {
                                    expanding - fieldTheme - textInput {
                                        content bind task.flatten()
                                    }
                                    compact - button {
                                        centered - deleteButton - icon(Icon.trash.resize(1.5.rem), "delete")
                                        onClick("remove task") {
                                            newItems.remove(task())
                                        }
                                    }
                                }
                            }
                        }
                    }

                    row {
                        primaryButton - button {
                            ::enabled { newItems().run { isNotEmpty() && none { it().isBlank() } } }
                            specCenteredText("Approve Work With Adjustments")
                            onClick {
                                val adjustments = newItems.map { it.value }
                                val tasks = adjustments.mapIndexed { idx, adjustment ->
                                    PunchListItem(
                                        project = project,
                                        content = adjustment,
                                        isMinorAdjustment = name,
                                        required = false
                                    )
                                }
                                val session = notNullSession()
                                session.punchLists.insert(tasks)
                                lineItems.forEach { line ->
                                    val updated = session.nonCached.lineItem.complete(line._id)
                                    session.lineItems.localSignalUpdate(
                                        matching = { it._id == updated._id },
                                        modify = { updated }
                                    )
                                }
                                dismiss()
                            }
                        }
                        textButton - button {
                            specCenteredText("Go Back")
                            onClick { dismiss() }
                        }
                    }
                }
            }
            onClick { dismiss() }
        }
    }
}
