package com.crowpay.views.dialogs

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.*
import com.crowpay.utils.validation.Validator
import com.crowpay.views.components.*
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.px
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.serialization.lensPath
import kotlinx.coroutines.delay

class EditScopeViewDialog(
    options: List<AdjustedLineItem>,
    val scope: ScopeView,
    val withUpdates: (suspend (ScopeView, List<UUID>)->Unit)? = null
) : Screen {

    val options = options
        .filter { (it.scopeView == scope._id || it.scopeView == null) && if (scope.scopeSet) it.state < LineItemState.Complete else true }
        .sortedBy { it.wraps.created }

    val selectedItems = SignalingList(options.filter { it.scopeView == scope._id })

    val draft = Draft(
        scope.copy()
    )
    val scopeTitle = draft.lensPath { it.scopeTitle }

    override fun ViewWriter.render() {
        dismissBackground {
            centered - dialog - stack {
                spacing = AppDimensions.fullIndent

                sizeConstraints(minWidth = 40.rem) - col {
                    spacing = AppDimensions.sectionIndent
                    title(if (scope.scopeSet) "Edit Scope Set" else "Edit Scope View")

                    requiredField("Scope Title") {
                        textInput {
                            hint = "Scope Title"
                            content bind scopeTitle
                        }
                    }

                    field2("Description (Optional)") {
                        val desc = draft.lensPath { it.description }
                        sizeConstraints(height = 7.rem) - textArea {
                            hint = "Description"
                            content bind desc.nullToBlank()
                        }
                    }

                    label2("Add and Remove Work Items") {
                        sizeConstraints(height = 17.rem) - scrolls - checkboxSelectLineItems(selectedItems, options, scope.scopeSet)
                    }

                    row {
                        secondaryButton - button {
                            specCenteredText("Discard")
                            onClick("Discard Scope View Changes") { dialogScreenNavigator.dismiss() }
                        }
                        primaryButton - button {
                            ::enabled { selectedItems().isNotEmpty() && scopeTitle().isNotBlank() }
                            specCenteredText {
                                val set = draft().scopeSet
                                "Save ${
                                    scopeTitle().ifBlank {
                                        if (set) "Scope Set" else "Scope View"
                                    }
                                }"
                            }

                            onClick(if (scope.scopeSet) "Save Scope Set" else "Save Scope View") {
                                val session = notNullSession()
                                val scope = draft.pushChanges(session.scopeViews)
                                val selected = selectedItems()

                                session.nonCached.scopeView.updateItems(
                                    scope._id,
                                    selected.map { it._id }
                                )

                                delay(500)
                                session.lineItems.totallyInvalidate()

                                withUpdates?.invoke(scope, selected.map { it._id })

                                dialogScreenNavigator.dismiss()
                            }
                        }
                    }
                }
            }
        }
    }
}