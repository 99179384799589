package com.crowpay.views.theming

import com.crowpay.DisplayableState
import com.crowpay.IncidentState
import com.crowpay.LineItemState
import com.crowpay.ProjectState
import com.lightningkite.kiteui.models.*

data class DisplayableStateSemantic(val state: DisplayableState): Semantic {
    override val key: String = "ds${state.displayName.replaceFirstChar { it.uppercaseChar() }.filter { it.isLetterOrDigit() }}"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        foreground = Color.fromHexString(state.displayColor)
    ).withoutBack
}

fun LineItemStateSemantic(state: LineItemState) = DisplayableStateSemantic(state)
fun IncidentStateSemantic(state: IncidentState) = DisplayableStateSemantic(state)

data class ProjectStateSemantic(val state: ProjectState): Semantic {
    override val key: String = "prj$state"
    val color = Color.fromHexString(state.displayColor)
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        font = theme.font.copy(bold = true),
        foreground = color,
        outline = color
    ).withoutBack
}

data object StrikeThroughSemantic : Semantic {
    override val key: String = "strk"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        font = theme.font.copy(strikethrough = true)
    ).withoutBack
}

// Basically disabled semantic but without back
data object GreyedOutSemantic : Semantic {
    override val key: String = "greyd"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        foreground = AppColors.grey.main,
        outline = AppColors.grey.main,
    ).withoutBack
}