package com.crowpay.utils

import com.crowpay.LineItem
import com.crowpay._id
import com.crowpay.scopeView
import com.crowpay.sdk.notNullSession
import com.crowpay.state
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.and
import com.lightningkite.lightningdb.eq
import com.lightningkite.lightningdb.neq
import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.launch

suspend fun repeatForScopeSets(
    item: AdjustedLineItem,
    action: suspend (item: LineItem)->Unit
) {
    if (item.scopeSet != true) {
        action(item.wraps)
        return
    }

    val otherItems = notNullSession().lineItems.query(
        Query(limit = Int.MAX_VALUE) {
            (it.scopeView eq item.scopeView) and (it.state eq item.state) and (it._id neq item._id)
        }
    )()

    coroutineScope {
        (otherItems + item.wraps)
            .chunked(5)
            .forEach { group ->
                group
                    .map { async { action(it) } }
                    .awaitAll()
            }
    }
}

