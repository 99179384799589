package com.crowpay.views.components.project.work.scope

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.completionResponse.CompletionFlowPermissions
import com.crowpay.completionResponse.CompletionResponse
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.*
import com.crowpay.views.components.expandAction
import com.crowpay.views.components.expandIcon
import com.crowpay.views.components.scopeSetIcon
import com.crowpay.views.components.sectionIndentCol
import com.crowpay.views.dialogs.*
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.screens.common.ProjectView
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.now
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch


fun ProjectView.renderScopeView(
    scope: Readable<ScopeView>,
    writer: ViewWriter
) = with(writer) {
    val expand = Property(false)
    val items = lineItems.shareFilter { it.scopeView == scope()._id }

    col {
        spacing = 0.px
        sizeConstraints(height = 3.rem) - row {
            launch {
                JumpTo.ScopeView.newTarget(scope()._id, this@row) {
                    expand.value = true
                }
            }
//            dynamicTheme {
//                if (expand()) DarkBackground else null
//            }

            expanding - button {
                spacing = 0.px
                row {
                    spacing = AppDimensions.expandButtonSpace
                    centered - expandIcon(expand)
                    expanding - compact - row {
                        centered - body { ::content { scope().scopeTitle } }
                        centered - scopeSetIcon(1.rem) {
                            ::exists { scope().scopeSet && !expand() }
                        }
                    }
                    weight(0.405f) - row {
                        ::exists { !expand() }
                        weight(1.2f) - centered - body {
                            ::visible { scope().scopeSet }
                            val status = shared { items().map { it.state }.firstOrNull() }
                            dynamicTheme { status()?.let(::LineItemStateSemantic) }
                            align = Align.Center
                            ::content { status()?.displayName ?: "" }
                        }
                        weight(1.1f) - centered - body {
                            align = Align.Center
                            ::content { items().sumOf { it.price }.renderDollars() }
                        }
                    }
                }
                action = expandAction(expand)
            }
            if (lens == ProjectLens.Contractor) onlyWhen { expand() } - row {
                spacing = 2.dp
                centered - buttonTheme - button {
                    icon(Icon.edit.resize(1.2.rem), "Edit Scope")
                    onClick {
                        dialogScreenNavigator.navigate(
                            EditScopeViewDialog(
                                options = lineItems(),
                                scope = scope()
                            )
                        )
                    }
                }
                centered - deleteButton - button {
                    icon(Icon.trash.resize(1.2.rem), "Delete Scope")
                    onClick {
                        dialogScreenNavigator.navigate(
                            GenericConfirmationDialog(
                                header = "Delete Scope ${if (scope().scopeSet) "Set" else "View"}?",
                                message = "You are about to delete this scope ${if (scope().scopeSet) "set" else "view"} This action cannot be undone. Are you sure?",
                                confirmationText = "Delete Scope",
                                messageType = MessageType.Danger,
                                onSubmit = {
                                    if (it) {
                                        val session = notNullSession()
                                        session.scopeViews[scope()._id].delete()

                                        delay(500)
                                        session.lineItems.totallyInvalidate()
                                    }
                                }
                            )
                        )
                    }
                }
            }
        }
        onlyWhen { expand() } - sectionIndentCol {
            spacing = 0.px

            compact - col {
                ::exists { !scope().description.isNullOrBlank() }
                space()
                body {
                    ::content { "Description: ${scope().description}" }
                }
            }

            workItemTableHeader(showNameHeader = false)

            greySeparator()

            workItemTableSummary(items, scope)

            greySeparator()

            col {
                spacing = 0.px
                forEachUpdating(items) { item ->
                    val expandItem = Property(false)
                    col {
                        spacing = 0.px
                        val row = workItemTableRow(expandItem, item, topLevel = false)

                        launch {
                            JumpTo.LineItem.newTarget(item()._id, view = row) {
                                expand.value = true
                                expandItem.value = true
                            }
                        }

                        onlyWhen { expandItem() } - renderLineItemDetails(
                            item,
                            contractorNotes.shareFilter { it.lineItem == item()._id },
                            punchListItems.shareFilter { it.lineItem == item()._id },
                            payAppItems,
                            lineItemCompletionMessages.shareFilter { it.lineItem == item()._id },
                            project,
                            lens
                        )
                        greySeparator()
                    }
                }
            }

            space(0.5)

            darkSection - row {
                val actions = scopeSetActions(this, scope, items)

                ::exists { scope().scopeSet && actions.any { it() != null } }
                spacing = AppDimensions.buttonRowSpacing

                expanding - space()

                row {
                    for (action in actions.reversed()) {
                        button { applyReactiveActionFormatting(action) }
                    }
                }
            }

            space(0.5)
        }
        greySeparator {
            ::exists { !expand() }
        }
    }
}

// This is a lot of selectively copied code from lineItemDetails.lineItemAction()
private fun ProjectView.scopeSetActions(
    writer: ViewWriter,
    scope: Readable<ScopeViewInterface>,
    items: Readable<List<AdjustedLineItem>>
): List<Readable<ButtonAction?>> = with(writer) {

    val itemMessages = shared {
        val exampleItem = items().firstOrNull() ?: return@shared emptyList()
        lineItemCompletionMessages().filter { it.lineItem == exampleItem._id }
    }

    val primary = shared {
        val scopeSet = scope()

        if (!scopeSet.scopeSet) return@shared null

        val project = project()
        val scopeItems = items()
        val lineItem = scopeItems.firstOrNull() ?: return@shared null

        val isCustomer = (lens != ProjectLens.Contractor)
        val isContractor = (lens == ProjectLens.Contractor)
        val notComplete = (lineItem.state < LineItemState.Complete)
        val started = (lineItem.started != null)
        val notOnHold = lineItem.state != LineItemState.OnHold
        val pendingDraws = payAppItems().count { pay -> pay.pending && pay.lineItem in scopeItems.map { it._id } }

        val permissions = CompletionFlowPermissions(lineItem.wraps, isCustomer, itemMessages())

        when {
            isContractor &&
                    notComplete &&
                    !started &&
                    project.state in setOf(ProjectState.PendingStart, ProjectState.InProgress) ->
                ButtonAction("Start Work") {
                    fun confirmation(
                        header: String,
                        message: String,
                        confirmationText: String,
                    ) = GenericConfirmationDialog(
                        header = header,
                        message = message,
                        confirmationText = confirmationText,
                        messageType = MessageType.Confirmation,
                        onSubmit = { confirmed ->
                            if (confirmed) {
                                val session = notNullSession()

                                session.lineItems.bulkModify(
                                    MassModification(
                                        condition<LineItem> {
                                            (it.scopeView eq scopeSet._id) and (it.state eq lineItem.state)
                                        },
                                        modification { it.started assign now() }
                                    )
                                )

                                session.projects[project._id].invalidate()
                            }
                        }
                    )

                    dialogScreenNavigator.navigate(
                        when {
                            project.started == null -> confirmation(
                                "Start Items and Project?",
                                "Starting this work item will start the project and all items in the scope set",
                                "Start Items and Project"
                            )

                            else -> confirmation(
                                "Start Work Items?",
                                "Starting this work item will start all items in the scope set",
                                "Start Work Items"
                            )
                        }
                    )
                }

            isContractor &&
                    notComplete &&
                    project.state == ProjectState.InProgress &&
                    pendingDraws == 0 &&
                    lineItem.state in setOf(LineItemState.InProgress, LineItemState.WorkingOnRemedy) ->
                ButtonAction(
                    "Request Sign Off",
                    enabled = { payAppItems().none { it.lineItem == lineItem._id && it.pending } }
                ) {
                    if (punchListItems().filter { it.lineItem == lineItem._id }.any { it.required && it.complete == null })
                        dialogScreenNavigator.navigate(
                            GenericDialog(
                                message = "You still have required punch list items pending. You cannot request review until all required punch list items are complete.",
                            )
                        )
                    else {
                        dialogScreenNavigator.navigate(
                            GenericConfirmationDialog(
                                header = "Ready to Sign Off?",
                                message = buildString {
                                    appendLine("Are ${lineItem.name}, and the other items in the scope set ready for sign off?")

                                    append('\n')

                                    if (lineItem.state == LineItemState.NotApproved)
                                        appendLine(
                                            """
                                            Before resubmitting for approval, ensure the work is fully complete and meets the client’s expectations.

                                            If the client does not approve again, they may escalate the issue, which could lead to further delays and formal resolution steps. Resubmit only if you’ve addressed all feedback and communicated clearly with the client.

                                            Tips:
                                            - Attach updated pictures or documentation to help the client approve your work faster.
                                            - If small, low-value items remain (e.g., a sprinkler clock on backorder), communicate with the client and list them as punch list or warranty items. This reassures the client that loose ends will be resolved during project closeout.
                                            """.trimIndent()
                                        )
                                    else
                                        appendLine(
                                            """
                                            Submitting for sign-off will send an approval request to the client. Ensure all items are fully complete and the client is aware of your proposal.

                                            If minor loose ends remain, notify the client they’ve been added to the punch list for project closeout.
            
                                            Tip: Attach pictures to help the client approve and release payment faster.
                                            """.trimIndent()
                                        )
                                },
                                confirmationText = "Request Sign Off",
                                messageType = MessageType.Confirmation,
                                onSubmit = { confirmed ->
                                    if (confirmed) {
                                        val session = notNullSession()
                                        repeatForScopeSets(lineItem) { item ->
                                            val result = session.nonCached.lineItem.requestReview(item._id)
                                            session.lineItems.localSignalUpdate(
                                                matching = { it._id == result._id },
                                                modify = { result }
                                            )
                                        }
                                    }
                                }
                            )
                        )
                    }
                }

            isCustomer &&
                    notComplete &&
                    notOnHold &&
                    permissions.mostRecentProposal is CompletionResponse.RequestComplete ->
                ButtonAction("Approve Work") {
                    dialogScreenNavigator navigateTo GenericConfirmationDialog(
                        header = "Approve & Pay Work Items?",
                        message = "Approving work items releases funds to the contractor as payment. This will happen for each item in the scope set.",
                        confirmationText = "Approve & Pay",
                        messageType = MessageType.Confirmation,
                        onSubmit = { confirmed ->
                            if (confirmed) {
                                val session = notNullSession()
                                repeatForScopeSets(lineItem) { item ->
                                    val result = session.nonCached.lineItem.complete(item._id)
                                    session.lineItems.localSignalUpdate(
                                        matching = { it._id == result._id },
                                        modify = { result }
                                    )
                                }
                                delay(200)
                                session.projects[project._id].invalidate()
                            }
                        }
                    )
                }


            else -> null
        }

    }

    val secondary = shared {
        val scopeSet = scope()

        if (!scopeSet.scopeSet) return@shared null

        val project = project()
        val lineItems = items()
        val lineItem = lineItems.firstOrNull() ?: return@shared null

        val isClient = (lens != ProjectLens.Contractor)
        val notComplete = (lineItem.state < LineItemState.Complete)
        val notDisputed = (project.state != ProjectState.Disputed)

        when {
            isClient &&
                    notComplete &&
                    notDisputed &&
                    lineItem.state == LineItemState.RequestingReview ->
                ButtonAction("Not Ready", theme = DangerButtonSemantic) {
                    dialogScreenNavigator.navigate(ScopeSetNotReadyDialog(lineItems, scope()))
                }

            else -> null
        }
    }

    val tertiary = shared {
        val scopeSet = scope()

        if (!scopeSet.scopeSet) return@shared null

        val project = project()
        val lineItems = items()
        val lineItem = lineItems.firstOrNull() ?: return@shared null

        val isCustomer = (lens != ProjectLens.Contractor)
        val notComplete = (lineItem.state < LineItemState.Complete)
        val notDisputed = (project.state != ProjectState.Disputed)

        when {
            isCustomer &&
                    notComplete &&
                    notDisputed &&
                    lineItem.state == LineItemState.RequestingReview ->
                ButtonAction("Minor Adjustment", theme = SecondaryButtonSemantic) {
                    dialogScreenNavigator.navigate(
                        MinorAdjustmentsDialog(lineItems.map { it.wraps }, name = scopeSet.scopeTitle)
                    )
                }

            else -> null
        }
    }

    listOf(primary, secondary)
}