package com.crowpay.views.components.project.work.changeOrders.tables

import com.crowpay.ChangeRequest
import com.crowpay.actuals.AppDimensions
import com.crowpay.edit
import com.crowpay.sdk.notNullSession
import com.crowpay.trash
import com.crowpay.utils.existsDefaultFalse
import com.crowpay.views.components.project.work.changeOrders.ChangeOrderForm2
import com.crowpay.views.components.project.work.changeOrders.ChangeRequestItemWrapper
import com.crowpay.views.components.expandAction
import com.crowpay.views.components.expandIcon
import com.crowpay.views.components.sectionIndentCol
import com.crowpay.views.dialogs.GenericConfirmationDialog
import com.crowpay.views.dialogs.MessageType
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.px
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon

fun ViewWriter.renderDraftChangeOrder(
    changeOrder: Readable<ChangeRequest>,
    changeItems: Readable<List<ChangeRequestItemWrapper>>
) {
    val expanded = Property(false)
    col {
        spacing = 0.px
        row {
            spacing = 0.px
            expanding - expandButtonTheme - button {
                row {
                    spacing = AppDimensions.expandButtonSpace
                    centered - expandIcon(expanded)
                    subTitle {
                        ::content {
                            val c = changeOrder()
                            "Change #${c.number}: ${c.title}"
                        }
                    }
                }
                action = expandAction(expanded)
            }
            row {
                spacing = 0.rem
                sizeConstraints(width = 5.rem) - buttonTheme - link {
                    spacing = 0.25.rem
                    ::to { { ChangeOrderForm2(changeOrder()._id) } }
                    centered - icon(Icon.edit, "Edit Change Order")
                }
                deleteButton - button {
                    existsDefaultFalse {
                        changeOrder().published == null
                    }
                    centered - icon(Icon.trash.copy(width = 1.5.rem, height = 1.5.rem), "Delete Change Order Draft")
                    onClick {
                        dialogScreenNavigator.navigate(
                            GenericConfirmationDialog(
                                header = "Delete Draft?",
                                message = "You are about to delete this change order draft. Deleted drafts cannot be recovered. Delete this draft?",
                                confirmationText = "Delete Draft",
                                messageType = MessageType.Danger,
                                onSubmit = { confirmed ->
                                    if (confirmed) {
                                        notNullSession().changeRequests[changeOrder()._id].delete()
                                    }
                                }
                            )
                        )
                    }
                }
            }
        }

        onlyWhen { expanded() } - sectionIndentCol {
            spacing = 0.px
            body {
                ::content {
                    "Description: ${changeOrder().description}"
                }
            }

            row {
                weight(6f) - space()
                weight(1f) - centered - bodyBold {
                    align = Align.Center
                    content = "ID"
                }
                weight(1f) - centered - bodyBold {
                    align = Align.Center
                    content = "Status"
                }
                weight(1f) - centered - bodyBold {
                    align = Align.Center
                    content = "Date"
                }
            }
            greySeparator()
            col {
                spacing = 0.px
                forEach(changeItems) { item ->
                    changeRequestItemButton(item) {
                        row {
                            spacing = 1.rem
                            weight(6f) - centered - row {
                                spacing = AppDimensions.expandButtonSpace
                                centered - expandIcon(it)
                                centered - body(item.name)
                                centered - smallBody(item.changeType.preTense)
                            }
                            weight(1f) - centered - body {
                                align = Align.Center
                                ::content { item.itemNumber }
                            }
                            weight(1f) - centered - body {
                                align = Align.Center
                                content = "Draft"
                            }
                            weight(1f) - centered - body {
                                align = Align.Center
                                content = "-"
                            }
                        }
                    }
                }
            }
            space()
        }

        greySeparator { ::exists { !expanded() } }
    }
}
