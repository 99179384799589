@file: UseContextualSerialization(UUID::class, ServerFile::class, Instant::class)

package com.crowpay

import com.crowpay.completionResponse.*
import com.crowpay.incidentResponse.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Clock.System
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.UseContextualSerialization
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days


@Serializable
@GenerateDataClassPaths

data class ChangeRequest(
    override val _id: UUID = UUID.random(),
    @References(Project::class) val project: UUID,
    val title: String = "",
    override val description: String,
    val projectDescription: String? = null,     // TODO: Need to think of a way to accept project description changes
    val published: Instant? = null,
    val cardinalChange: Boolean = false,
    val linkItems: Boolean = false,
    override val scopeSet: Boolean = false,
    val fullyLinked: Boolean = false,
    @Denormalized val pending: Int = 0,     // Number of items that are pending
    val number: String,
    val created: Instant = now(),
) : HasId<UUID>, ScopeViewInterface {
    override val scopeTitle: String get() = "Change #$number: $title"
}

@Serializable
@GenerateDataClassPaths
data class ClientTermsAndConditionsAgreement(
    override val _id: UUID = UUID.random(),
    val accessInfo: AccessInfo,
    @References(ClientTermsAndConditionsVersion::class) val version: Instant,
) : HasId<UUID>

@Serializable
@GenerateDataClassPaths
data class ClientTermsAndConditionsVersion(
    override val _id: Instant = now(),
    val establishedAt: Instant = now(),
    val contents: String,
) : HasId<Instant>

@Serializable
@GenerateDataClassPaths
data class Client(
    override val _id: UUID = UUID.random(),
    @References(Contractor::class) val contractor: UUID,
    override val name: String,
    override val email: String,
    override val phoneNumber: String,
    override val address: Address,
//    val archived: Boolean = false,
) : HasId<UUID>, Contact {
    override val image: ServerFile? get() = null
}

@Serializable
@GenerateDataClassPaths
data class Contractor(
    override val _id: UUID = UUID.random(),
    override val name: String,
    override val email: String,
    override val phoneNumber: String,
    override val address: Address,
    override val image: ServerFile? = null,
    val preferredTitle: PreferredTitle = PreferredTitle.Contractor,
    val trade: String? = null,

    val contactFirstName: String,
    val contactLastName: String,
    val contactEmail: String,
    val contactPhoneNumber: String,
    val contactAddress: Address,

    val stateEntityNumber: String,
    val ein: String,

    val status: ContractorStatus = ContractorStatus.Registering,

    @Denormalized val lastAgreement: ContractorTermsAndConditionsAgreement? = null,
) : HasId<UUID>, Contact


@Serializable
@GenerateDataClassPaths
data class ContractorDocument(
    override val _id: UUID = UUID.random(),
    @References(Contractor::class) val contractor: UUID,
    override val name: String,
    override val fileType: String,
    override val file: ServerFile,
    override val preview: ServerFile? = null,
) : HasId<UUID>, Attachment

@Serializable
@GenerateDataClassPaths
data class ContractorNote(
    override val _id: UUID = UUID.random(),
    @References(Project::class) val project: UUID,
    @References(LineItem::class) val lineItem: UUID? = null,    // If null then this is a private project note, if not null then the customer can see this on a line item
    val message: String,
    val files: List<ProjectAttachment> = emptyList(),
    val at: Instant = now(),
) : HasId<UUID>

@Serializable
@GenerateDataClassPaths
data class ContractorTermsAndConditionsAgreement(
    override val _id: UUID = UUID.random(),
    @References(Contractor::class) val contractor: UUID,
    val accessInfo: AccessInfo,
    @References(ContractorTermsAndConditionsVersion::class) val version: Instant,
) : HasId<UUID>

@Serializable
@GenerateDataClassPaths
data class ContractorTermsAndConditionsVersion(
    override val _id: Instant = now(),
    val establishedAt: Instant = now(),
    val contents: String,
) : HasId<Instant>

@Serializable
@GenerateDataClassPaths
data class ItemChange(
    override val _id: UUID = UUID.random(),
    @References(Project::class) val project: UUID,
    @References(ChangeRequest::class) override val changeRequest: UUID,
    @References(LineItem::class) override val itemId: UUID,
    override val linked: Boolean = false,
    override val updatedDescription: String? = null,
    override val priceChange: Long? = null,
    override val cancelled: Boolean = false,
    val created: Instant = now(),
    override val itemNumber: String = "1",
) : HasId<UUID>, ItemChangeInterface, ChangeRequestItem {
    override val changeAccepted: Instant get() = created
    override val changeRejected: Instant? get() = null

    override val changeType: ChangeType get() = if (cancelled) ChangeType.Remove else ChangeType.Modify
}


@Serializable
@GenerateDataClassPaths
data class License(
    override val _id: UUID = UUID.random(),
    @References(Contractor::class) val contractor: UUID,
    val number: String,
    val city: String? = null,
    val state: State,
    override val file: ServerFile,
    override val fileType: String,
    override val preview: ServerFile? = null,
    val type: LicenseType,
) : HasId<UUID>, Attachment {

    override val name: String
        get() = number
}

@Serializable
@GenerateDataClassPaths
data class LineItem(
    override val _id: UUID = UUID.random(),
    @References(Project::class) val project: UUID,
    val changeRequest: ChangeRequestInfo? = null, // The Change Request this was created from.
    @References(ScopeView::class) val scopeView: UUID? = null,
    @Denormalized val scopeSet: Boolean? = false,
    override val name: String = "",
    override val description: String = "",
    @IntegerRange(0L, Long.MAX_VALUE) val originalPrice: Long = 0L,
    val order: Int = 0,
    override val files: List<ProjectAttachment> = listOf(),
    val started: Instant? = null,

    @Denormalized val state: LineItemState = LineItemState.NotStarted,
    @Denormalized val acceptedPriceChange: Long = 0L,

    @Denormalized val allowRaiseIssue: Boolean = false,
    @Denormalized val allowFileDispute: Boolean = false,
    @Denormalized val allowLockProject: Boolean = false,
    val issue: Issue? = null,

    val credits: List<Credit> = emptyList(),

    val complete: AccessInfo? = null,
    @MultipleReferences(ProjectLedgerItem::class) val ledgerItems: Set<UUID> = emptySet(), // This is a redundancy field. We need to guarantee money works. If the post Change fails we can use this to start again.
    val cancelled: Instant? = null,
    val created: Instant = now(),
    @Denormalized val contractorDeposits: Long = 0L
) : HasId<UUID>, Comparable<LineItem>, LineItemInterface {
    val lineCredits get() = credits.filter { it.group == null }
    val projectCredits get() = credits.filter { it.group != null }

    @Transient
    override val price: Long = originalPrice + acceptedPriceChange - lineCredits.sumOf { it.amount }

    // How much money is left to draw from the line item.
    // price: The active price of the line item (original price + accepted item changes)
    // contractorDeposits: The amount drawn and given to contractor in pay apps or at completion
    // credits: Funds paid back to the homeowner
    val remainingFunds: Long get() = originalPrice + acceptedPriceChange - contractorDeposits - credits.sumOf { it.amount }
    fun remainingFundsWithRetention(retention: Float?) =
        if (retention == null) remainingFunds
        else remainingFunds - (price*retention).roundToLong()

    override fun compareTo(other: LineItem): Int = comparator.compare(this, other)

    companion object {
        val comparator = compareBy<LineItem> { it.order }.thenBy { it.created }
    }

    val grouped: Boolean get() = scopeView != null
    fun changeItem(): LineItemFromChangeRequest? {
        if (changeRequest == null) return null

        return LineItemFromChangeRequest(
            name = name,
            _id = _id,
            itemId = _id,
            changeType = ChangeType.New,
            changeRequest = changeRequest.id,
            itemNumber = changeRequest.itemNumber,
            changeAccepted = created,
            changeRejected = null,
            priceChange = originalPrice,
            description = description,
            price = originalPrice,
            files = files
        )
    }
}

@Serializable
@GenerateDataClassPaths
data class PayApplication(
    override val _id: UUID = UUID.random(),
    @References(Project::class) val project: UUID,
    @Denormalized val number: Int, // 1-indexed identifier based on number of pay apps created for the project
    val created: Instant = now(),
    val submitted: Instant? = null
) : HasId<UUID>

// The amount drawn cannot exceed [original price of line item] - [approved draws] - [retention]
// Ex. Line item is $10,000. Retention is 10% ($1,000). $2,000 was paid in a previous pay app. The max allowed to request is 10,000-2,000-1,000 = $7,000
@Serializable
@GenerateDataClassPaths
data class PayAppItem(
    override val _id: UUID = UUID.random(),
    @References(PayApplication::class) val payApp: UUID,
    @References(LineItem::class) val lineItem: UUID,
    @IntegerRange(0, Long.MAX_VALUE) val amount: Long,
    val approved: AccessInfo? = null,
    val denied: AccessInfo? = null,
    val voided: AccessInfo? = null,     // Cancelled by contractor
    @Denormalized val project: UUID,
    @Denormalized val submitted: Instant? = null,
    @Denormalized val accepted: Boolean? = null     // for querying purposes. true = approved != null, false = voided != null
) : HasId<UUID> {
    val pending: Boolean get() = (submitted != null) and (approved == null) and (voided == null) and (denied == null)

    //    val accepted: Boolean? get() = when {
//        denied != null -> false
//        approved != null -> true
//        else -> null
//    }
    val reviewedTimestamp: Instant? get() = voided?.timestamp ?: approved?.timestamp

    val submittedOrReviewed: Instant? get() = submitted ?: reviewedTimestamp
}

@Serializable
@GenerateDataClassPaths
data class Payout(
    override val _id: Instant = System.now(),
    val nachaFile: ServerFile,
    val totalOut: Int,
    val onePercent: Int,
) : HasId<Instant> {
    companion object {
        const val filePath = "payouts"
    }
}


@Serializable
@GenerateDataClassPaths
data class PendingItemChange(
    override val _id: UUID = UUID.random(),
    @References(Project::class) val project: UUID,
    @References(ChangeRequest::class) override val changeRequest: UUID,
    @References(LineItem::class) override val itemId: UUID,
    override val updatedDescription: String? = null,
    override val priceChange: Long? = null, // This should be null if cancelled is true
//    override val linked: Boolean = false,
    override val cancelled: Boolean = false,
    val accepted: AccessInfo? = null,
    val approved: Boolean? = null, // This is here because approved cannot be queried on due to masking rules.
    val denied: AccessInfo? = null,
    val created: Instant = now(),
    override val itemNumber: String,
) : HasId<UUID>, ItemChangeInterface, ChangeRequestItem {
    override val changeAccepted: Instant? get() = accepted?.timestamp
    override val changeRejected: Instant? get() = denied?.timestamp
    override val linked: Boolean get() = false

    override val changeType: ChangeType get() = if (cancelled) ChangeType.Remove else ChangeType.Modify
}

@Serializable
@GenerateDataClassPaths
data class PendingLineItem(
    override val _id: UUID = UUID.random(),
    @References(Project::class) val project: UUID,
    @References(ChangeRequest::class) override val changeRequest: UUID,
    override val name: String = "",
    override val description: String = "",
    @IntegerRange(0L, Long.MAX_VALUE) override val price: Long = 0L,
    override val files: List<ProjectAttachment> = listOf(),
    override val linked: Boolean = false,
    val accepted: AccessInfo? = null,
    val denied: AccessInfo? = null,
    val approved: Boolean? = null, // This is here because approved cannot be queried on due to masking rules.
    val created: Instant = now(),
    override val itemNumber: String,
) : HasId<UUID>, LineItemInterface, ChangeRequestItem {
    override val itemId: UUID get() = _id
    override val changeAccepted: Instant? get() = accepted?.timestamp
    override val changeRejected: Instant? get() = denied?.timestamp
    override val priceChange: Long get() = price

    override val changeType: ChangeType get() = ChangeType.New
}


@Serializable
@GenerateDataClassPaths
data class Project(
    override val _id: UUID = UUID.random(),
    @References(Contractor::class) val contractor: UUID,
    @References(User::class) val customer: UUID? = null,

    // Manager Editable during creation
    @References(Client::class) val client: UUID? = null,
    val name: String,
    val description: String,
    val address: Address,
    val location: String? = null,
    val jobNumber: String? = null,
    val files: List<ProjectAttachment> = listOf(),
    @FloatRange(0.0, 1.0) val retention: Float? = null,
    val published: Instant? = null,

    // User editable during acceptance and completion
    val feedback: ClientFeedback? = null,

    // Events that determine State Non User Editable
    @AdminViewOnly val created: Instant = now(),
    @AdminViewOnly val cancelled: AccessInfo? = null,
    @AdminViewOnly val abandoned: AccessInfo? = null,
    @AdminViewOnly val archived: AccessInfo? = null,
    @AdminViewOnly val viewedByCustomer: Instant? = null,
    @AdminViewOnly val approved: AccessInfo? = null,
    @AdminViewOnly val fullFundingRequested: AccessInfo? = null,
    @AdminViewOnly val terminated: Instant? = null,
    @AdminViewOnly val complete: CompletionRequest? = null,

    // Denormalized values Non User Editable
    @Index @Denormalized val isArchived: Boolean = false,
    @Index @Denormalized val state: ProjectState = ProjectState.Creating,
    @Denormalized val originalPrice: Long = 0,
    @Denormalized val activePrice: Long = 0,
    @Denormalized val totalCredits: Long = 0,
    @Denormalized val acceptedChangeAmount: Long = 0,
    @Denormalized val fundingNeeded: Long = 0L,
    @Denormalized val clientDeposits: Long = 0L,
    @Denormalized val pendingClientDeposits: Long = 0L,
    @Denormalized val contractorPayments: Long = 0L,
    @Denormalized val funded: Instant? = null,
    @Denormalized val started: Instant? = null,
    @Denormalized val substantialCompletion: Instant? = null,
) : HasId<UUID> {
    val balance: Long get() = clientDeposits - contractorPayments
    val safeRetention: Float get() = retention?.takeUnless { it < BASE_RETENTION } ?: BASE_RETENTION

    val remainingFunds: Long get() {
        val remaining = activePrice - contractorPayments
        return if (retention != null) (remaining * (1-retention)).roundToLong()
        else remaining
    }

    companion object {
        const val BASE_RETENTION = 0.05f
    }
}

/*
DANGEROUS CASES:

- Pay more out of CrowPay than the value of the project
- Pay out to the wrong person
- Payment is not recorded
 */
//@Suppress("RUNTIME_ANNOTATION_NOT_SUPPORTED")
@Serializable
@GenerateDataClassPaths
data class ProjectLedgerItem(
    override val _id: UUID = UUID.random(),
    @References(Project::class, reverseName = "Ledger Items") val project: UUID,
    @References(LineItem::class) val lineItem: UUID? = null,
    val contractor: Boolean = false,
    @Description("Positive indicates a payment into CrowPay, negative indicates a payment out.")
    val amount: Long = 0,
    val intentId: IntentId? = null,
    val state: PaymentState,
    val message: String? = null,
    val lastStateChange: Instant = now(),
    val created: Instant = now(),
) : HasId<UUID>

@Serializable
@GenerateDataClassPaths
data class PunchListItem(
    override val _id: UUID = UUID.random(),
    @References(Project::class) val project: UUID,
    @References(LineItem::class) val lineItem: UUID? = null,
    val order: Double = 0.5,
    val content: String,
    val required: Boolean,
    val complete: AccessInfo? = null,
    val isMinorAdjustment: String? = null   // The name of the line item this is a minor adjustment for. Used for rendering in the punch list.
) : HasId<UUID>

@Serializable
@GenerateDataClassPaths
data class ScopeView(
    override val _id: UUID = UUID.random(),
    @References(Project::class) val project: UUID,
    override val scopeTitle: String,
    override val description: String? = null,
    override val scopeSet: Boolean = false,
    val fromChangeRequest: Boolean = false,
) : HasId<UUID>, ScopeViewInterface

@Serializable
@GenerateDataClassPaths
data class Trade(
    override val _id: String,
) : HasId<String>

@Serializable
@GenerateDataClassPaths
data class User(
    override val _id: UUID = UUID.random(),
    @Unique val email: String,
    val phoneNumber: String,
    val firstName: String,
    val lastName: String,
    val address: Address,
    val membership: UserMembership? = null,
    val paymentId: String? = null,
    val role: UserRole,

    val receivesNewContractorEmails: Boolean = false,    // Only applies to admins, only editable in admin
//    val phoneMfaEnabled: Boolean = false,
//    val phoneVerified: Boolean = false,

    @Denormalized val lastAgreement: ClientTermsAndConditionsAgreement? = null,
) : HasId<UUID>


//@Serializable
//@GenerateDataClassPaths
//data class MessageThread(
//    override val _id: UUID = UUID.random(),
//    @References(Project::class) val project: UUID,
//    @References(LineItem::class) val lineItem: UUID? = null,
//) : HasId<UUID>

interface FlowMessage {
    val message: String
    val clientMessage: Boolean
    val type: HasDisplayName
    val created: Instant
}

@Serializable
@GenerateDataClassPaths
data class LineItemCompletionMessage(
    override val _id: UUID = UUID.random(),
    @Denormalized @References(Project::class) val project: UUID,
    @References(LineItem::class) val lineItem: UUID,
    val sender: UUID,
    @Denormalized override val clientMessage: Boolean,
    @AdminViewOnly val responseTypeSerializable: CompletionResponseSerializable,
    override val message: String,
    val attachments: List<ProjectAttachment> = emptyList(),
    override val created: Instant = now()
) : HasId<UUID>, FlowMessage {
    val responseType: CompletionResponse by lazy {
        // println("Attempting to parse $responseTypeSerializable")
        responseTypeSerializable.parseResponse()
    }
    override val type: HasDisplayName get() = responseType


    fun handleParsingError(handler: (CompletionResponseParseError)->Unit) {
        try {
            responseType
        } catch (e: CompletionResponseParseError) {
            handler(e)
        }
    }
}


@Serializable
@GenerateDataClassPaths
data class CompletionMessageComment(
    override val _id: UUID = UUID.random(),
    @References(LineItemCompletionMessage::class) val completionMessage: UUID,
    @Denormalized @References(Project::class) val project: UUID,
    @Denormalized @References(LineItem::class) val lineItem: UUID,
    val sender: UUID,
    @Denormalized val clientMessage: Boolean,
    val message: String,
    val created: Instant = now()
) : HasId<UUID>

@Serializable
@GenerateDataClassPaths
data class ProjectTimer(
    override val _id: UUID,
    @References(Project::class) val project: UUID,
    @References(LineItem::class) val lineItem: UUID? = null,
    @References(Incident::class) val incident: UUID? = null,
    val durationLeft: Duration? = null,
    val executesAt: Instant?,
    val action: TimerAction,
    val businessDaysOnly: Boolean = false,
    val minimumTimeWhenResumed: Duration? = null,
    @Denormalized val executed: Instant? = null,
) : HasId<UUID> {
    /*
    * While running, durationSecondsLeft will be null, and executesAt will have an instant.
    * When paused, the difference between now and executesAt will be stored in durationSecondsLeft
    * When resumed the stored remaining time will be added to now() to get the new execution time, and the time left will be set back to null
    * */

    constructor(
        project: UUID,
        lineItem: UUID? = null,
        incident: UUID? = null,
        duration: Duration,
        action: TimerAction,
        businessDaysOnly: Boolean = false,
        minimumTimeWhenResumed: Duration? = null
    ) : this(
        _id = UUID.random(),
        project = project,
        lineItem = lineItem,
        incident = incident,
        executesAt = (now() + duration).let {
            var execute = it
            if (businessDaysOnly) {
                val dayOfExecution = execute.toLocalDateTime(TimeZone.currentSystemDefault()).dayOfWeek
                when (dayOfExecution) {
                    DayOfWeek.SUNDAY -> { execute += 1.days }
                    DayOfWeek.SATURDAY -> { execute += 2.days }
                    else -> {}
                }
            }
            execute
        },
        action = action,
        businessDaysOnly = businessDaysOnly,
        minimumTimeWhenResumed = minimumTimeWhenResumed
    )

    val expired get() =
        if (executed != null) true
        else executesAt?.let { now() >= it } ?: false

    val paused get() = executesAt == null

    companion object
}

@Serializable
enum class DisputeState {
    Ongoing,
    ProjectLocked,
    Resolved
}

@Serializable
@GenerateDataClassPaths
data class Dispute(
    override val _id: UUID = UUID.random(),
    @References(Project::class) val project: UUID,
    val lineItem: LineItemDispute? = null,
    @References(Incident::class) val incident: UUID? = null,
    val filed: AccessInfo,
    val lockProject: AccessInfo? = null,
    val filedForArbitration: FiledForArbitration? = null,
    val resolved: AccessInfo? = null,
    @Denormalized val state: DisputeState = DisputeState.Ongoing    // Cannot query by access info
) : HasId<UUID>

@Serializable
@GenerateDataClassPaths
data class Incident(
    override val _id: UUID = UUID.random(),
    @References(Project::class) val project: UUID,
    val filed: AccessInfo,
    val issue: Issue? = null,
    val resolved: AccessInfo? = null,
    @Denormalized val concern: ClientConcern = ClientConcern.Other,

    @Denormalized val state: IncidentState = IncidentState.Filed,
    @Denormalized val canFileDispute: Boolean = false,
    @Denormalized val canLockProject: Boolean = false,
) : HasId<UUID>

@Serializable
@GenerateDataClassPaths
data class IncidentMessage(
    override val _id: UUID = UUID.random(),
    @References(Incident::class) val incident: UUID,
    @References(Project::class) val project: UUID,
    override val clientMessage: Boolean,
    @AdminViewOnly val typeSerializable: IncidentResponseTypeSerializable,
    override val message: String,
    val attachments: List<ProjectAttachment> = emptyList(),
    override val created: Instant = now()
) : HasId<UUID>, FlowMessage {
    override val type: IncidentResponseType by lazy { typeSerializable.parseResponse() }

    fun handleParsingError(handler: (IncidentResponseParseError)->Unit) {
        try {
            type
        } catch (e: IncidentResponseParseError) {
            handler(e)
        }
    }
}

@Serializable
@GenerateDataClassPaths
data class IncidentMessageComment(
    override val _id: UUID = UUID.random(),
    @References(IncidentMessage::class) val incidentMessage: UUID,
    @Denormalized @References(Project::class) val project: UUID,
    @Denormalized @References(Incident::class) val incident: UUID,
    val sender: UUID,
    @Denormalized val clientMessage: Boolean,
    val message: String,
    val created: Instant = now()
) : HasId<UUID>



@Serializable
@GenerateDataClassPaths
data class ProjectMessage(
    override val _id: UUID = UUID.random(),
    @References(Project::class) val project: UUID,
    @References(User::class) val sender: UUID,
    @Denormalized val clientMessage: Boolean,
    val sent: Instant = now(),
    val message: String,
    @AdminViewOnly val replyingToMessage: MessageReply? = null,
    @AdminViewOnly val referencesChangeRequest: ChangeRequestMessageReference? = null,
    @AdminViewOnly val referencesPayApp: PayAppMessageReference? = null
) : HasId<UUID>

@Serializable
data class MessageReply(
    @References(ProjectMessage::class) val id: UUID,
    val message: String
) {
    constructor(message: ProjectMessage) : this(message._id, message.message)
}

@Serializable
data class ChangeRequestMessageReference(
    @References(ChangeRequest::class) val id: UUID,
    val reason: String,
)

@Serializable
data class PayAppMessageReference(
    @References(PayApplication::class) val id: UUID,
    val reason: String
)
