package com.crowpay

// Confusing language, but it's a shorter and more clear list, in my opinion.
val ProjectState.Companion.nonContractorEditableStates get() = setOf(
    ProjectState.Disputed,
    ProjectState.Locked,
    ProjectState.TransferringFunds,
    ProjectState.Complete,
    ProjectState.Warranty,
    ProjectState.Terminated,
    ProjectState.Cancelled,
)

fun ProjectState.isProjectDeletable(): Boolean = this in setOf(
    ProjectState.Creating,
    ProjectState.Invited,
    ProjectState.WaitingApproval
)

fun ProjectState.isProjectLocked(): Boolean = this in setOf(
    ProjectState.Invited,
    ProjectState.WaitingApproval
)

fun ProjectState.canFileIncidents(): Boolean = this >= ProjectState.PendingStart && this < ProjectState.Locked

val ProjectState.Companion.inactiveStates get() = setOf(
    ProjectState.Complete,
    ProjectState.Warranty,
    ProjectState.Terminated,
    ProjectState.Cancelled,
    ProjectState.Abandoned,
)

val ProjectState.Companion.disputedStates get() = setOf(ProjectState.Disputed, ProjectState.Locked)

fun ProjectState.isContractorEditable(): Boolean = this !in ProjectState.nonContractorEditableStates

fun ProjectState.newTasksAllowed() = isContractorEditable() && !isProjectLocked()

fun LineItemState.newTasksAllowed() = this !in setOf(LineItemState.Cancelled, LineItemState.ForceComplete, LineItemState.Complete)

fun LineItemState.newRequiredTasksAllowed() =
    newTasksAllowed() && this != LineItemState.RequestingReview

fun LineItemState.responseAllowed() = this !in setOf(
    LineItemState.NotStarted,
    LineItemState.Complete,
    LineItemState.OnHold,
    LineItemState.ResolveLater,
    LineItemState.Resolved,
    LineItemState.Cancelled
)

fun LineItemState.changesAllowed() = this !in setOf(
    LineItemState.RequestingReview,
    LineItemState.ForceComplete,
    LineItemState.Issue,
    LineItemState.OnHold,
    LineItemState.Disputed,
    LineItemState.Cancelled,
    LineItemState.Resolving,
    LineItemState.WorkingOnRemedy,
    LineItemState.Resolved,
    LineItemState.Complete
)

fun ProjectState.newNotesAllowed() = isContractorEditable() && !isProjectLocked()

fun LineItemState.newNotesAllowed() = this !in setOf(
    LineItemState.ForceComplete,
    LineItemState.OnHold,
    LineItemState.Disputed,
    LineItemState.Cancelled,
    LineItemState.Resolved,
    LineItemState.ResolveLater,
    LineItemState.Complete
)

fun LineItemState.canRequestReview() = this in setOf(
    LineItemState.InProgress,
    LineItemState.WorkingOnRemedy
)

// All possible states that issues can be raised
fun LineItemState.canRaiseIssue() = this in setOf(
    LineItemState.ForceComplete,
    LineItemState.RequestingReview,
    LineItemState.NotApproved,
)

// All possible states that disputes could be filed
fun LineItemState.canFileDispute() = this in setOf(
    LineItemState.Issue,
    LineItemState.ForceComplete,
    LineItemState.NotApproved,
    LineItemState.Resolving,
    LineItemState.RequestingReview
)

fun LineItemState.canProposeResolution() = this in setOf(
    LineItemState.Disputed,
    LineItemState.Resolving,
    LineItemState.NotApproved,
)