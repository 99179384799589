package com.crowpay.views.dialogs

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.*
import com.crowpay.utils.validation.Validator
import com.crowpay.views.components.*
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.px
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.serialization.lensPath
import kotlinx.coroutines.delay

fun ViewWriter.checkboxSelectLineItems(selectedItems: SignalingList<AdjustedLineItem>, items: List<AdjustedLineItem>, scopeSet: Boolean): RowOrCol {
    val sharedState = shared {
        selectedItems().map { it.state }.firstOrNull()
    }

    return col {
        spacing = 0.px
        for (item in items) {
            val select = selectedItems.containsWritable(item)
            RemoveToggleSemantic.onNext - toggleButton {
                spacing = AppDimensions.buttonSpacing
                if (scopeSet) ::enabled {
                    val s = sharedState()
                    s == null || s == item.state
                }
                compact - row {
                    sizeConstraints(width = 1.5.rem, height = 1.5.rem) - centered - checkbox {
                        if (scopeSet) ::enabled {
                            val s = sharedState()
                            s == null || s == item.state
                        }
                        checked bind select
                    }
                    expanding - centered - body(item.name)
                    weight(0.8f) - centered - row {
                        weight(1f) - LineItemStateSemantic(item.state).onNext - body {
                            content = item.state.displayName
                            align = Align.Center
                        }
                        weight(0.8f) - body {
                            content = item.price.renderDollars()
                            align = Align.Center
                        }
                    }
                }
                checked bind select
            }
        }
    }
}

class NewScopeViewDialog(
    options: List<AdjustedLineItem>,
    val scopeSet: Boolean,
    val withSelection: (suspend (ScopeView, List<UUID>) -> Unit)? = null
) : Screen {
    val options = options
        .filter { !it.grouped && if (scopeSet) it.state < LineItemState.Complete else true }
        .sortedBy { it.wraps.created }

    val selectedItems = SignalingList<AdjustedLineItem>()

    val draft = Draft(
        ScopeView(
            project = options
                .map { it.project }
                .toSet()
                .singleOrNull() ?: throw IllegalArgumentException("All options must be from the same project"),
            scopeTitle = "",
            scopeSet = scopeSet
        )
    )
    val scopeTitle = draft.lensPath { it.scopeTitle }

    override fun ViewWriter.render() {
        dismissBackground {
            centered - dialog - stack {
                spacing = AppDimensions.fullIndent

                sizeConstraints(minWidth = 40.rem) - col {
                    spacing = AppDimensions.sectionIndent
                    title(if (scopeSet) "New Scope Set" else "New Scope View")

                    requiredField("Scope Title") {
                        textInput {
                            hint = "Scope Title"
                            content bind scopeTitle
                        }
                    }

                    field2("Description (Optional)") {
                        val desc = draft.lensPath { it.description }
                        sizeConstraints(height = 7.rem) - textArea {
                            hint = "Description"
                            content bind desc.nullToBlank()
                        }
                    }

                    label2("Add Work Items") {
                        sizeConstraints(height = 17.rem) - scrolls - checkboxSelectLineItems(selectedItems, options, scopeSet)
                    }

                    row {
                        secondaryButton - button {
                            specCenteredText("Discard")
                            onClick("Discard Scope View") { dialogScreenNavigator.dismiss() }
                        }
                        primaryButton - button {
                            ::enabled { selectedItems().isNotEmpty() && scopeTitle().isNotBlank() }
                            specCenteredText {
                                "Create ${scopeTitle().ifBlank { 
                                    if (scopeSet) "Scope Set" else "Scope View" 
                                }}"
                            }

                            onClick(if (scopeSet) "Create Scope Set" else "Create Scope View") {
                                val session = notNullSession()
                                val scope = draft.pushChanges(session.scopeViews)
                                val selected = selectedItems()

                                session.nonCached.scopeView.addItems(
                                    scope._id,
                                    selected.map { it._id }
                                )
                                delay(500)
                                session.lineItems.totallyInvalidate()

                                withSelection?.invoke(scope, selected.map { it._id })

                                dialogScreenNavigator.dismiss()
                            }
                        }
                    }
                }
            }
        }
    }
}