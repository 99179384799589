package com.crowpay.utils

import com.crowpay.*
import com.crowpay.sdk.notNullSession
import com.lightningkite.UUID
import com.lightningkite.kiteui.reactive.ReactiveContext
import com.lightningkite.lightningdb.HasId
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.eq
import kotlinx.datetime.Instant

class AdjustedLineItem(
    val wraps: LineItem,
    changes: List<ItemChange>,
) : LineItemInterface, HasId<UUID> {
    constructor(wraps: LineItem) : this(wraps, emptyList())

    val original: LineItemInterface = object : LineItemInterface by wraps {
        override val price: Long = wraps.originalPrice
    }

    val changes = changes.sortedByDescending { it.created }

    override val _id: UUID = wraps._id
    override val name: String = wraps.name
    override val files: List<ProjectAttachment> = wraps.files

    override val description: String =
        changes.firstOrNull { it.updatedDescription != null }?.updatedDescription ?: wraps.description

    val totalPriceChange =
        if (wraps.cancelled != null)
            -wraps.originalPrice
        else
            changes.sumOf { it.priceChange ?: 0 }.coerceAtLeast(-wraps.originalPrice)

    override val price: Long = wraps.originalPrice + totalPriceChange

    val cancelled = wraps.cancelled != null || changes.any { it.cancelled }

    val project get() = wraps.project
    val state get() = wraps.state
    val started get() = wraps.started
    val complete get() = wraps.complete
    val scopeView get() = wraps.scopeView
    val scopeSet get() = wraps.scopeSet
    val changeRequest get() = wraps.changeRequest
    val grouped get() = wraps.grouped
    val contractorDeposits get() = wraps.contractorDeposits
    val issue get() = wraps.issue
    val allowRaiseIssue get() = wraps.allowRaiseIssue
    val allowRaiseDispute get() = wraps.allowFileDispute

    val changeType: ChangeType?
        get() = when {
            cancelled -> ChangeType.Remove
            changes.isNotEmpty() -> ChangeType.Modify
            wraps.changeRequest != null -> ChangeType.New
            else -> null
        }

    fun copy(
        state: LineItemState = this.state,
    ): AdjustedLineItem = AdjustedLineItem(wraps.copy(state = state), changes)

    fun excludeChangesAfter(time: Instant): AdjustedLineItem? =
        if (wraps.created > time) null
        else AdjustedLineItem(wraps, changes.filter { it.created < time })
}

fun ReactiveContext.applyItemChanges(item: LineItem): AdjustedLineItem {
    val changes = notNullSession().itemChanges        // Easier to cache
        .query(
            Query(limit = Int.MAX_VALUE) { it.project eq item.project }
        )()

    return AdjustedLineItem(item, changes.filter { it.itemId == item._id })
}