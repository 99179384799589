package com.crowpay.views.dialogs

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.completionResponse.CompletionResponse
import com.crowpay.completionResponse.LineItemConcern
import com.crowpay.completionResponse.serializable
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.AdjustedLineItem
import com.crowpay.utils.SignalingList
import com.crowpay.utils.SignalingSet
import com.crowpay.utils.modify2
import com.crowpay.views.components.requiredField
import com.crowpay.views.components.requiredLabel
import com.crowpay.views.screens.common.DialogWithDialog
import com.crowpay.views.screens.common.secondDialog
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.bind
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.toggle
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.delay

class ScopeSetNotReadyDialog(
    val items: List<AdjustedLineItem>,
    val scope: ScopeViewInterface
) : DialogWithDialog {
    val selectedItems = SignalingList<AdjustedLineItem>()
    val reasons = SignalingList<LineItemConcern>()
    val message = Property("")

    val minChars = 50

    override fun ViewWriter.renderPage(): ViewModifiable = dismissBackground {
        centered - dialog - stack {
            spacing = AppDimensions.fullIndent
            sizeConstraints(width = AppDimensions.pageWidth - AppDimensions.fullIndent*2) - removeOutline - col {
                spacing = AppDimensions.majorColumnSpacing

                title("Not Ready: ${scope.scopeTitle}")

                col {
                    notificationBar(AppColors.blue) - body(
                        "Items marked 'Not Ready' will have their funds held until the issue is resolved by the contractor."
                    )
                    notificationBar(AppColors.yellow) - body(
                        "Unchecked items will be approved and funds released, so make sure to mark every item you think is not ready. If no items are ready, consider filing a project-wide issue instead."
                    )
                }

                requiredField("Not Ready Items") {
                    sizeConstraints(maxHeight = 15.rem) - scrolls - checkboxSelectLineItems(selectedItems, items, true)
                }

                col {
                    requiredField("Concerns") {
                        menuButton {
                            requireClick = true
                            preferredDirection = PopoverPreferredDirection.belowCenter
                            row {
                                expanding - centered - body {
                                    ::content {
                                        reasons()
                                            .takeUnless { it.isEmpty() }
                                            ?.joinToString { it.displayName }
                                            ?: "Select a Response"
                                    }
                                }
                                centered - icon(Icon.chevronDown, "Select Responses")
                            }

                            opensMenu {
                                sizeConstraints(width = AppDimensions.pageWidth - AppDimensions.fullIndent*2 - theme.spacing*2) - withSpacing(0.2) - col {
                                    for (concern in LineItemConcern.entries) {
                                        val container = reasons.containsWritable(concern)
                                        button {
                                            row {
                                                centered - checkbox {
                                                    checked bind container
                                                }
                                                centered - body(concern.displayName)
                                            }
                                            onClick { container.toggle() }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    col {
                        spacing = theme.spacing / 2
                        requiredField("Reason") {
                            sizeConstraints(minHeight = 8.rem) - textArea {
                                content bind message
                                hint = "Minimum $minChars characters"
                            }
                        }
                        atEnd - smallBody {
                            ::content {
                                val diff = minChars - message().length
                                if (diff > 0) "$diff characters remaining" else ""
                            }
                        }
                    }
                }

                row {
                    primaryButton - button {
                        ::enabled { selectedItems().isNotEmpty() && reasons().isNotEmpty() && message().isNotBlank() }
                        specCenteredText {
                            when (selectedItems().size) {
                                0 -> "Not Ready"
                                1 -> "Mark '${selectedItems.first().name}' Not Ready"
                                else -> "Mark (${selectedItems.size}) Items Not Ready"
                            }
                        }
                        onClick("Not Ready") {
                            val name = when (selectedItems.size) {
                                1 -> "'${selectedItems.first().name}'"
                                else -> "${selectedItems.size} Items"
                            }
                            secondDialog.value = GenericConfirmationDialog(
                                "Submit Not Ready?",
                                "Are you sure you want to mark $name as not ready? This will release payment for any unmarked items.",
                                confirmationText = "Submit Not Ready",
                                dismiss = { dismissSecondDialog() }
                            ) { confirmed ->
                                if (!confirmed) return@GenericConfirmationDialog
                                val session = notNullSession()

                                try {
                                    session.scopeViews[scope._id].modify2 {
                                        it.scopeSet assign false
                                    }
                                } catch (e: Exception) {
                                    println("Exception thrown by removing scope set")
                                    throw e
                                }

                                for (item in selectedItems) {
                                    val sent = session.nonCached.lineItemCompletionMessage.sendMessage(
                                        LineItemCompletionMessage(
                                            project = item.project,
                                            lineItem = item._id,
                                            sender = session.userId,
                                            clientMessage = true,
                                            message = message.value,
                                            responseTypeSerializable = CompletionResponse.ClientResponse(reasons.toSet()).serializable(),
                                        )
                                    )
                                    session.lineItemCompletionMessages.localInsert(sent)
                                }

                                coroutineScope {
                                    (items - selectedItems)
                                        .map {
                                            async { session.nonCached.lineItem.complete(it._id) }
                                        }
                                        .awaitAll()
                                }

                                delay(500)
                                session.lineItems.totallyInvalidate()

                                dialogScreenNavigator.dismiss()
                            }
                        }
                    }
                    secondaryButton - button {
                        specCenteredText("Go Back")
                        onClick("Exit") { dialogScreenNavigator.dismiss() }
                    }
                }
            }
        }
    }
}