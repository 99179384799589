package com.crowpay.views.theming

import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.views.ViewModifierDsl3
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.themeFromLast


data object SectionSemantic : Semantic {
    override val key = "sctn"
    override fun default(theme: Theme): ThemeAndBack {
        val currentIsDark = theme.background.closestColor().toHSP().brightness < 0.95
        return theme.copy(
            id = key,
            background = if (currentIsDark) Color.white else AppColors.grey.light2,
        ).withBack
    }
}
@ViewModifierDsl3
val ViewWriter.section: ViewWrapper get() = SectionSemantic.onNext

data object LightSection : Semantic {
    override val key: String = "lgtsctn"
    override fun default(theme: Theme): ThemeAndBack {
        val backIsLight = theme.background.closestColor() == AppColors.grey.light3

        return if (backIsLight) theme.copy(
            id = "${key}2",
            background = AppColors.grey.light2
        ).withBack
        else theme.copy(
            id = key,
            background = AppColors.grey.light3,
        ).withBack
    }
}
@ViewModifierDsl3
val ViewWriter.lightSection: ViewWrapper get() = LightSection.onNext

data object DarkSection : Semantic {
    override val key: String = "drksctn"
    override fun default(theme: Theme): ThemeAndBack {
        val currentIsDark = theme.background.closestColor().toHSP().brightness < 0.95
        return theme.copy(
            id = key,
            background = if (currentIsDark) Color.white else AppColors.grey.light1,
        ).withBack
    }
}

@ViewModifierDsl3
val ViewWriter.darkSection: ViewWrapper get() = DarkSection.onNext

data object DarkBackground : Semantic {
    override val key: String = "drkbck"
    override fun default(theme: Theme): ThemeAndBack {
        return theme.copy(
            id = key,
            background = theme.background.darken(0.3f),
        ).withBackNoPadding
    }
}

data object PurpleSection : Semantic {
    override val key: String = "prpsctn"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        foreground = Color.white,
        background = AppColors.primary.main
    ).withBack
}

data object GreenSection : Semantic {
    override val key: String = "grnsctn"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        foreground = Color.white,
        background = AppColors.secondary.main
    ).withBack
}

fun ColorSet.asList(): List<Color> = listOf(dark, main, light1, light2, light3)

data class MessageSemantic(val mine: Boolean, val colors: ColorSet): Semantic {
    constructor(mine: Boolean, isClient: Boolean) : this(
        mine,
        if (isClient) AppColors.blue
        else AppColors.purple
    )

    override val key: String = "msg"

    override fun default(theme: Theme): ThemeAndBack {
        val currentBackBrightness = theme.background.closestColor().perceivedBrightness
        val newBack = colors
            .asList()
            .reversed()
            .firstOrNull {
                it.perceivedBrightness < currentBackBrightness
            } ?: colors.main

        return theme.copy(
            id = buildString {
                append(if (mine) "my" else "other")
                append("msg")
                append(newBack.toWeb().filter { it.isLetterOrDigit() })
            },
            background = newBack,
            cornerRadii = CornerRadii.PerCorner(
                value = 10.dp,
                topLeft = true,
                topRight = true,
                bottomLeft = mine,
                bottomRight = !mine
            ),
            revert = true
        ).withBack
    }
}