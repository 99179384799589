package com.crowpay.views.components.project.work.changeOrders

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.extensions.toFileData
import com.crowpay.extensions.withSpacing
import com.crowpay.sdk.*
import com.crowpay.utils.*
import com.crowpay.utils.validation.Validator
import com.crowpay.utils.validation.interceptWrite
import com.crowpay.views.components.*
import com.crowpay.views.dialogs.GenericConfirmationDialog
import com.crowpay.views.dialogs.GenericDialog
import com.crowpay.views.dialogs.MessageType
import com.crowpay.views.dialogs.MultiselectFromItemsDialog
import com.crowpay.views.screens.contractor.ContractorDashboard
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.async
import com.lightningkite.kiteui.locale.renderToString
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.eq
import com.lightningkite.lightningdb.modification
import com.lightningkite.serialization.lensPath
import kotlinx.coroutines.awaitAll
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch

@Routable("contractor/change-order/edit/{changeOrderId}")
class ChangeOrderForm2(
    val changeOrderId: UUID,
) : Screen, Validator() {
    val changeOrderDraft = Draft {
        notNullSession().changeRequests[changeOrderId]() ?: notFoundError<ChangeRequest>()
    }
    val changeOrderNumber = changeOrderDraft.published.lens { it.number }
    val published = changeOrderDraft.published.lens { it.published != null }

    val scopeApproach = changeOrderDraft.lensPath { it.scopeSet }
    val linkItems = changeOrderDraft
        .lensPath { it.linkItems }
        .interceptWrite { link ->
            newItems().forEach { it.linked set link }
            set(link)
        }

    val cardinalChange = changeOrderDraft
        .lensPath { it.cardinalChange }
        .interceptWrite { cardinal ->
            if (!cardinal) {
                linkItems set false
                scopeApproach set false
            }
            set(cardinal)
        }

    val project = shared {
        notNullSession().projects[changeOrderDraft.published().project]() ?: notFoundError<Project>()
    }
    private val lineItems = shared {
        notNullSession().lineItems
            .query(Query(limit = Int.MAX_VALUE) { it.project eq changeOrderDraft.published().project })
    }
    private val itemChanges = shared {
        notNullSession().itemChanges
            .query(Query(limit = Int.MAX_VALUE) { it.project eq changeOrderDraft.published().project })
    }
    val existingAdjustedItems = shared {
        val items = lineItems()()
        val changes = itemChanges()()

        items.map { item ->
            AdjustedLineItem(item, changes.filter { it.itemId == item._id })
        }
    }

    // Sub-forms
    val newItems = sharedSuspending {
        notNullSession().pendingLineItems
            .query(Query(limit = Int.MAX_VALUE) { it.changeRequest eq changeOrderId })
            .invoke()
            .map { NewLineItemForm.newOrUpdated(it, this@ChangeOrderForm2) }
    }
    val changedItems = sharedSuspending {
        notNullSession().pendingItemChange
            .query(Query(limit = Int.MAX_VALUE) { it.changeRequest eq changeOrderId })
            .invoke()
            .map { ChangeLineItemForm.newOrUpdated(it, this@ChangeOrderForm2) }
    }
    val subForms: Readable<List<ChangeItemForm>> = shared { (newItems() + changedItems()).sortedBy { it.itemNumber() } }

    val totalPriceChange = shared {
        val originals = existingAdjustedItems()
        newItems().sumOf { it.draft().price } +
                changedItems().sumOf {
                    val d = it.draft()
                    if (d.cancelled)
                        -(originals.find { it.wraps._id == d.itemId }?.price ?: 0L)
                    else
                        it.draft().priceChange ?: 0
                }
    }
    val allItemsLinked = shared {
        val items = newItems()
        linkItems() and items.all { it.draft().linked } and items.isNotEmpty()
    }

    suspend fun notifyDeleting(form: ChangeItemForm) {
        val number = form.itemNumber()
        val outerNumber = changeOrderNumber()
        subForms().forEachIndexed { index, it ->
            val itemNumber = it.itemNumber()
            if (itemNumber > number) it.itemNumber set "$outerNumber.${index + 1}"
        }
    }

    // Actions
    suspend fun save(includeItems: Boolean = true) = coroutineScope {
        changeOrderDraft.modify {
            it.copy(
                fullyLinked = allItemsLinked()
            )
        }
        changeOrderDraft.pushChanges(notNullSession().changeRequests)
        if (!includeItems) return@coroutineScope
        newItems().map { async { it.save() } }.awaitAll()
        changedItems().map { async { it.save() } }.awaitAll()
    }

    suspend fun delete() = coroutineScope {
        notNullSession().changeRequests[changeOrderId].delete()
    }

    suspend fun publish() {
        save()
        val session = notNullSession()
        session.nonCached.changeRequest.publishChangeRequest(changeOrderId)
        delay(500)
        session.changeRequests.totallyInvalidate()
    }

    suspend fun unpublish() {
        val session = notNullSession()
        session.nonCached.changeRequest.unPublishChangeRequest(changeOrderId)
        delay(500)
        session.changeRequests.totallyInvalidate()
    }

    // Views
    fun ViewWriter.actionBar() {
        section - row {
            secondaryButton - button {
                specCenteredText("Save")
                onClick("Save") { save() }
            }
            secondaryButton - button {
                specCenteredText("Save & Close")
                onClick("Save & Close") {
                    save()
                    screenNavigator.dismiss()
                }
            }
            textButton - button {
                centered - body("Discard")
                onClick("Discard") {
                    dialogScreenNavigator.navigate(
                        GenericConfirmationDialog(
                            header = "Discard Changes?",
                            message = "You have changes that have not been submitted. Discarded changes cannot be recovered. Discard changes and leave?",
                            confirmationText = "Discard & Leave",
                            messageType = MessageType.Confirmation,
                            onSubmit = { confirmed -> if (confirmed) screenNavigator.dismiss() }
                        )
                    )
                }
            }
            deleteButton - button {
                existsDefaultFalse {
                    changeOrderDraft().published == null
                }
                centered - icon(Icon.trash.copy(width = 1.5.rem, height = 1.5.rem), "Delete Change Order Draft")
                onClick {
                    dialogScreenNavigator.navigate(
                        GenericConfirmationDialog(
                            header = "Delete Draft?",
                            message = "You are about to delete this change order draft. Deleted drafts cannot be recovered. Delete this draft?",
                            confirmationText = "Delete Draft",
                            messageType = MessageType.Danger,
                            onSubmit = { confirmed ->
                                if (confirmed) {
                                    screenNavigator.dismiss().also { delay(500); delete() }
                                }
                            }
                        )
                    )
                }
            }
            expanding - space()
            button {
                ::enabled {
                    if (published()) true
                    else allValid() && (newItems().isNotEmpty() or changedItems().isNotEmpty())
                }
                dynamicTheme {
                    if (published()) DangerButtonSemantic
                    else PrimaryButtonSemantic
                }
                specCenteredText { if (published()) "Unpublish" else "Submit Change" }
                ::action {
                    if (published())
                        Action("Unpublish") {
                            dialogScreenNavigator.navigate(
                                GenericConfirmationDialog(
                                    header = "Unpublish Change Request?",
                                    message = "Unpublishing will make it unavailable for approval or denial. ",
                                    confirmationText = "Unpublish Change Request",
                                    messageType = MessageType.Danger,
                                    onSubmit = {
                                        if (it) unpublish()
                                    }
                                )
                            )
                        }
                    else
                        Action("Submit") {
                            if (allValid.once()) {
                                dialogScreenNavigator.navigate(
                                    GenericConfirmationDialog(
                                        header = "Submit Change Request?",
                                        message = "Submitting this change request will make it available to view and be accepted by the homeowner. Please make sure all project details are correct before proceeding. Once the homeowner accepts or denys any item, no changes can be made.",
                                        confirmationText = "Submit Change Request",
                                        messageType = MessageType.Confirmation,
                                        onSubmit = {
                                            if (it) {
                                                publish()
                                                screenNavigator.dismiss()
                                            }
                                        }
                                    )
                                )
                            } else {
                                dialogScreenNavigator.navigate(
                                    GenericDialog(
                                        "One or more required items is missing or invalid"
                                    )
                                )
                            }
                        }
                }
            }
        }
    }

    override fun ViewWriter.render() {
        scrolls - stack {
            sizeConstraints(width = AppDimensions.pageWidth) - col {
                spacing = AppDimensions.majorColumnSpacing

                this += RedirectOnNotFound(ChangeRequest::class) { ContractorDashboard() }

                launch {
                    if (newItems().any { it.draft.published().approved != null }) {
                        screenNavigator.dismiss()
                        return@launch
                    }

                    if (changedItems().any { it.draft.published().approved != null })
                        screenNavigator.dismiss()
                }

                title {
                    ::content { "${project().name} Change Order" }
                }

                actionBar()

                col {
                    spacing = AppDimensions.sectionIndent
                    row {
                        bodyBold { ::content { "Change Order ${changeOrderNumber()}" } }
                        centered - smallBody {
                            ::content { changeOrderDraft().created.renderToString(includeYear = false) }
                        }
                    }
                    row {
                        val title = changeOrderDraft.lensPath { it.title }.validateNotBlank()
                        row {
                            spacing = AppDimensions.cornerRadii
                            centered - bodyBold("Change Title:")
                            colored(AppColors.red.main) - bodyBold("*")
                        }
                        fieldTheme - textInput {
                            spacing = 0.35.rem
                            content bind title
                        }
                    }
                    row {
                        bodyBold("Total Net Change:")
                        body {
                            ::content { totalPriceChange().renderDollars() }
                        }
                    }
                    requiredField("Description") {
                        val description = changeOrderDraft.lensPath { it.description }.validateNotBlank()
                        sizeConstraints(minHeight = 8.rem) - textArea {
                            content bind description
                        }
                    }

                    row {
                        dynamicTheme {
                            if (changedItems().isNotEmpty()) DisabledSemantic
                            else null
                        }
                        checkbox {
                            ::enabled { changedItems().isEmpty() }
                            checked bind cardinalChange
                        }
                        col {
                            row {
                                bodyBold("Cardinal Change?")
                                body {
                                    setBasicHtmlContent(
                                        "Select to<u>display</u> <b>new</b>work items as a change group. Approve items independently."
                                    )
                                }
                            }
                            smallBody("(If left unchecked, single or multiple items display independently for approval and with original items for scope work.  If checked items display like a mini-project.)")
                        }
                    }
                    onlyWhen { cardinalChange() } - col {
                        spacing = AppDimensions.sectionIndent
                        row {
                            checkbox {
                                checked bind linkItems
                            }
                            col {
                                row {
                                    bodyBold("Link Items for Approval?")
                                    body("Select to allow for linking change items.")
                                }
                                smallBody("(Change work items each display within the group and be approved/rejected as a whole.)")
                            }
                        }
//                        row {
//                            checkbox {
//                                checked bind scopeApproach
//                            }
//                            col {
//                                row {
//                                    bodyBold("Scope Approach?")
//                                    body("Select to work approved items as one set of scope.")
//                                }
//                                smallBody("(Start Work, Request Signoff, and Get Paid as a group.)")
//                            }
//                        }
                    }
                }

                actionBar()

                rowCollapsingToColumn(AppDimensions.pageWidth) {
                    val allowedStates =
                        setOf(LineItemState.NotStarted, LineItemState.InProgress, LineItemState.NotApproved)
                    val disallowedStates = setOf(LineItemState.InProgress)
                    val availableOptions = shared {
                        val modified = changedItems().map { it.itemId }
                        existingAdjustedItems().filter { it._id !in modified && it.state in allowedStates }
                    }

                    expanding - space()
                    tertiaryButton - button {
                        ::enabled { !cardinalChange() && availableOptions().isNotEmpty() }
                        specCenteredContent {
                            row {
                                icon(Icon.close, "Remove Work Item")
                                centered - body("Remove Work Item")
                            }
                        }
                        onClick {
                            dialogScreenNavigator.navigate(
                                ChangeLineItemForm.createRemoval(
                                    changeOrder = changeOrderDraft(),
                                    orderNumber = changeOrderNumber(),
                                    subOrderNumber = subForms().size + 1,
                                    choices = availableOptions().filter { it.state !in disallowedStates }
                                )
                            )
                        }
                    }
                    tertiaryButton - button {
                        ::enabled { !cardinalChange() && availableOptions().isNotEmpty() }
                        specCenteredContent {
                            row {
                                icon(Icon.edit.copy(width = 1.5.rem, height = 1.5.rem), "Modify Work Item")
                                centered - body("Modify Work Item")
                            }
                        }
                        onClick {
                            dialogScreenNavigator.navigate(
                                ChangeLineItemForm.createModification(
                                    changeOrder = changeOrderDraft(),
                                    orderNumber = changeOrderNumber(),
                                    subOrderNumber = subForms().size + 1,
                                    choices = availableOptions()
                                )
                            )
                        }
                    }
                    tertiaryButton - button {
                        specCenteredContent {
                            row {
                                icon(Icon.add, "New Work Item")
                                centered - body("New Work Item")
                            }
                        }
                        onClick {
                            notNullSession().pendingLineItems.insert(
                                PendingLineItem(
                                    project = project()._id,
                                    changeRequest = changeOrderId,
                                    itemNumber = "${changeOrderNumber()}.${subForms().size + 1}",
                                    linked = changeOrderDraft().linkItems
                                )
                            )
                        }
                    }
                }

                col {
                    spacing = 0.px
                    sizeConstraints(height = 3.rem) - subTitle { ::content { "Change Order ${changeOrderNumber()}: ${changeOrderDraft().title}" } }

                    sizeConstraints(height = 2.rem) - row {
                        spacing = 0.px
                        weight(4f) - body {
                            ::visible { !cardinalChange() }
                            content = "Work Item"
                        }
                        weight(1f) - body {
                            ::visible { linkItems() }
                            align = Align.Center
                            content = "Linked?"
                        }
                        weight(1f) - body {
                            align = Align.Center
                            content = "ID"
                        }
                        weight(1f) - body {
                            align = Align.Center
                            content = "Original"
                        }
                        weight(1f) - body {
                            align = Align.Center
                            content = "Change"
                        }
                        weight(1f) - body {
                            align = Align.Center
                            content = "Scope Total"
                        }
                    }

                    greySeparator()

                    onlyWhen { cardinalChange() } - col {
                        spacing = 0.px
                        sizeConstraints(height = 3.rem) - row {
                            spacing = 0.px
                            weight(4f) - centered - bodyBold("Group Summary")
                            weight(1f) - stack {
                                colored(AppColors.secondary.main) - centered - icon {
                                    ::exists { allItemsLinked() }
                                    source = Icon.linked
                                }
                            }
                            weight(1f) - centered - body {
                                align = Align.Center
                                ::content { changeOrderNumber() }
                            }
                            weight(1f) - centered - body {
                                align = Align.Center
                                content = "$0.00"
                            }
                            val total = newItems.share { items -> items.sumOf { it.draft().price } }
                            weight(1f) - stack {
                                centered - renderPriceChange { total() }
                            }
                            weight(1f) - centered - body {
                                align = Align.Center
                                ::content { total().renderDollars() }
                            }
                        }
                        greySeparator()
                    }

                    col {
                        spacing = 0.px
                        forEach(subForms) { with(it) { renderForm() } }
                    }
                }

                space(AppDimensions.fullIndent)
            }
        }
    }

    // Sub-forms
    interface ChangeItemForm {
        val itemNumber: Writable<String>
        fun ViewWriter.renderForm()
    }

    class ChangeLineItemForm private constructor(
        start: PendingItemChange,
        val parentForm: ChangeOrderForm2,
    ) : ChangeItemForm, Validator(parentForm) {
        val removingItem = start.cancelled
        val _id = start._id
        val itemId = start.itemId
        val draft = Draft(start)
        val currentItem =
            shared {
                val item = notNullSession().lineItems[start.itemId]() ?: notFoundError<LineItem>()
                applyItemChanges(item)
            }

        override val itemNumber: Writable<String> = draft.published
            .lens { it.itemNumber }
            .withWrite { num ->
                if (num == awaitOnce()) return@withWrite
                notNullSession().pendingItemChange[_id].modify(modification { it.itemNumber assign num })
            }

        val expanded = Property(true)
        fun ViewWriter.header() {
            sizeConstraints(height = 4.rem) - row {
                spacing = 0.px
                weight(4f) - centered - row {
                    spacing = 0.6.rem
                    centered - expandIcon(expanded)
                    centered - subTitle { ::content { currentItem().name } }
                    centered - smallBody { ::content { draft().changeType.preTense } }
                }
                weight(1f) - centered - stack { }
                weight(1f) - centered - body {
                    align = Align.Center
                    ::content { itemNumber() }
                }
                weight(1f) - centered - body {
                    align = Align.Center
                    ::content { currentItem().price.renderDollars() }
                }
                weight(1f) - centered - stack {
                    centered - renderPriceChange {
                        val d = draft()
                        if (d.cancelled)
                            -currentItem().wraps.originalPrice
                        else
                            d.priceChange ?: 0
                    }
                }
                weight(1f) - centered - body {
                    align = Align.Center
                    ::content {
                        val d = draft()
                        (if (d.cancelled) 0
                        else currentItem().price + (d.priceChange ?: 0))
                            .renderDollars()
                    }
                }
            }
        }

        override fun ViewWriter.renderForm() {
            col {
                spacing = 0.px

                button {
                    spacing = 0.px
                    header()
                    action = expandAction(expanded)
                }
                onlyWhen { expanded() } - sectionIndentCol {
                    spacing = AppDimensions.sectionIndent
                    space()
                    if (!removingItem) {
                        requiredField("Price Change (Dollars)") {
                            val priceChange = draft
                                .lensPath { it.priceChange }
                                .validate { it != null }
                                .toNullableDouble()
                            numberInput {
                                keyboardHints = KeyboardHints.integer
                                hint = "Price Change"
                                content bind priceChange
                            }
                        }
                        field2("Updated Description") {
                            val desc = draft.lensPath { it.updatedDescription }
                            textArea {
                                hint = "Updated Description"
                                content bind desc.nullToBlank()
                            } in sizeConstraints(minHeight = 7.rem)
                        }
                    }
                    section - row {
                        deleteButton - button {
                            specCenteredContent {
                                row {
                                    centered - icon(
                                        Icon.trash.copy(width = 1.5.rem, height = 1.5.rem),
                                        "Remove"
                                    )
                                    centered - body("Delete")
                                }
                            }
                            onClick {
                                dialogScreenNavigator.navigate(
                                    GenericConfirmationDialog(
                                        "Delete Change Item?",
                                        "This cannot be undone.",
                                        messageType = MessageType.Danger,
                                        confirmationText = "Delete Item"
                                    ) {
                                        if (it) delete()
                                    }
                                )
                            }
                        }
                        expanding - space()
                        secondaryButton - button {
                            specCenteredText("Discard")
                            onClick { draft.cancel() }
                        }
                        primaryButton - button {
                            ::enabled { allValid() }
                            specCenteredText("Save")
                            onClick {
                                save()
                                expanded.value = false
                            }
                        }
                    }
                    space()
                }
                greySeparator()
            }
        }

        fun remove() {
            cache.remove(_id)
            removeFromParent()
        }

        suspend fun delete() {
            notNullSession().pendingItemChange[_id].delete()
            parentForm.notifyDeleting(this)
            remove()
        }

        suspend fun save() {
            draft.pushChanges(notNullSession.awaitOnce().pendingItemChange)
        }

        companion object {
            private val cache = HashMap<UUID, ChangeLineItemForm>()
            suspend fun newOrUpdated(
                itemChange: PendingItemChange,
                parentForm: ChangeOrderForm2,
            ): ChangeLineItemForm =
                cache[itemChange._id]
                    ?.apply { draft.updatePublished(itemChange) }
                    ?: ChangeLineItemForm(
                        itemChange,
                        parentForm
                    ).also { cache[itemChange._id] = it }

            fun createModification(
                changeOrder: ChangeRequest,
                orderNumber: String,
                subOrderNumber: Int,
                choices: List<AdjustedLineItem>,
            ): Screen =
                MultiselectFromItemsDialog(
                    choices,
                    header = "Modify Work Item(s)",
                    confirmText = { if (it == 0) "Select Items" else "Modify ($it) Items" }
                ) { selected ->
                    notNullSession().pendingItemChange.insert(
                        selected.withIndex().map { (index, item) ->
                            PendingItemChange(
                                project = changeOrder.project,
                                changeRequest = changeOrder._id,
                                itemId = item._id,
                                itemNumber = "${orderNumber}.${subOrderNumber + index}"
                            )
                        }
                    )
                }

            fun createRemoval(
                changeOrder: ChangeRequest,
                orderNumber: String,
                subOrderNumber: Int,
                choices: List<AdjustedLineItem>,
            ): Screen =
                MultiselectFromItemsDialog(
                    choices,
                    header = "Remove Line Item(s)",
                    confirmText = { if (it == 0) "Select Items" else "Remove ($it) Items" }
                ) { selected ->
                    notNullSession().pendingItemChange.insert(
                        selected.withIndex().map { (index, itemToRemove) ->
                            PendingItemChange(
                                project = changeOrder.project,
                                changeRequest = changeOrder._id,
                                itemId = itemToRemove._id,
                                itemNumber = "${orderNumber}.${subOrderNumber + index}",
                                priceChange = null,
                                cancelled = true
                            )
                        }
                    )
                }
        }
    }

    class NewLineItemForm(
        start: PendingLineItem,
        val parentForm: ChangeOrderForm2,
    ) : ChangeItemForm, Validator(parentForm) {
        val _id = start._id
        val draft = Draft {
            start.copy(linked = parentForm.linkItems())
        }
        val files = Property(start.files.toFileData())
        val form = LineItemForm(draft, files, parentForm.published.lens { !it })

        init {
            addChild(form)
        }

        override val itemNumber: Writable<String> = draft.published
            .lens { it.itemNumber }
            .withWrite { num ->
                if (num == awaitOnce()) return@withWrite
                notNullSession().pendingLineItems[_id].modify(modification { it.itemNumber assign num })
            }
        val linked: Writable<Boolean> = draft.lensPath { it.linked }

        val expanded = Property(true)
        override fun ViewWriter.renderForm() {
            col {
                spacing = 0.px

                withSpacing(0.dp) - row {
                    expanding - buttonTheme - button {
                        spacing = 0.px
                        sizeConstraints(height = 4.rem) - row {
                            spacing = 0.px
                            weight(4f) - centered - row {
                                centered - expandIcon(expanded)
                                centered - subTitle { ::content { form.name().ifBlank { "New Item" } } }
                                centered - smallBody("New")
                            }
                            weight(1f) - centered - stack {
                                colored(AppColors.secondary.main) - centered - icon {
                                    ::visible { linked() and !parentForm.allItemsLinked() }
                                    source = Icon.linked
                                }
                            }
                            weight(1f) - centered - body {
                                align = Align.Center
                                ::content { itemNumber() }
                            }
                            weight(1f) - centered - body {
                                align = Align.Center
                                content = "$0.00"
                            }
                            weight(1f) - centered - stack {
                                centered - renderPriceChange { draft().price }
                            }
                            weight(1f) - centered - body {
                                align = Align.Center
                                ::content { (draft().price).renderDollars() }
                            }
                        }
                        action = expandAction(expanded)
                    }
                }

                onlyWhen { expanded() } - sectionIndentCol {
                    space()

                    row {
                        ::exists { parentForm.linkItems() }
                        checkbox {
                            ::enabled { !parentForm.published() }
                            checked bind linked
                        }
                        centered - body("Linked")
                    }

                    form.render(this)

                    section - row {
                        deleteButton - button {
                            specCenteredContent {
                                row {
                                    centered - icon(
                                        Icon.trash.copy(width = 1.5.rem, height = 1.5.rem),
                                        "Remove"
                                    )
                                    centered - body("Delete")
                                }
                            }
                            onClick {
                                dialogScreenNavigator.navigate(
                                    GenericConfirmationDialog(
                                        "Delete Change Item?",
                                        "This cannot be undone.",
                                        messageType = MessageType.Danger,
                                        confirmationText = "Delete Item"
                                    ) {
                                        if (it) delete()
                                    }
                                )
                            }
                        }
                        expanding - space()
                        secondaryButton - button {
                            specCenteredText("Discard")
                            onClick { draft.cancel() }
                        }
                        primaryButton - button {
                            specCenteredText("Save")
                            onClick {
                                save()
                                expanded.value = false
                            }
                        }
                    }

                    space()
                }

                greySeparator()
            }
        }

        fun remove() {
            cache.remove(_id)
            removeFromParent()
        }

        suspend fun delete() {
            notNullSession().pendingLineItems[_id].delete()
            parentForm.notifyDeleting(this)
            remove()
        }

        suspend fun save() {
            draft.pushChanges(notNullSession().pendingLineItems)
        }

        companion object {
            private val cache = HashMap<UUID, NewLineItemForm>()
            suspend fun newOrUpdated(lineItem: PendingLineItem, parentForm: ChangeOrderForm2): NewLineItemForm =
                cache[lineItem._id]
                    ?.apply { draft.updatePublished(lineItem) }
                    ?: NewLineItemForm(
                        lineItem,
                        parentForm
                    ).also { cache[lineItem._id] = it }
        }
    }
}