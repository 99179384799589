package com.crowpay.views.dialogs

import com.crowpay.actuals.AppDimensions
import com.crowpay.utils.AdjustedLineItem
import com.crowpay.utils.SignalingList
import com.crowpay.utils.contains
import com.crowpay.utils.renderDollars
import com.crowpay.utils.validation.Validator
import com.crowpay.utils.validation.validate
import com.crowpay.views.components.*
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.dialog
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding

class SelectFromItemsDialog(
    val options: List<AdjustedLineItem>,
    val header: String,
    val confirmText: (selectedName: String?) -> String = { name -> name?.let { "Select $it" } ?: "-" },
    val cancelText: String = "Go Back",
    val onSubmit: suspend (AdjustedLineItem)->Unit
) : Screen, Validator() {
    val selectedItem = Property<AdjustedLineItem?>(null).validateNotNull()

    override fun ViewWriter.render() {
        dismissBackground {
            centered - dialog - stack {
                spacing = AppDimensions.fullIndent
                sizeConstraints(minWidth = 35.rem) - col {
                    spacing = AppDimensions.sectionIndent
                    title(header)

                    requiredField("Work Item") {
                        select {
                            bind(
                                edits = selectedItem,
                                data = Constant(listOf(null) + options),
                                render = { it?.name ?: "Select A Work Item" }
                            )
                        }
                    }

                    row {
                        secondaryButton - button {
                            specCenteredText(cancelText)
                            onClick("Cancel") {
                                dialogScreenNavigator.dismiss()
                            }
                        }
                        primaryButton - button {
                            ::enabled { allValid() }
                            specCenteredText {
                                val name = selectedItem()
                                    ?.name
                                    ?.let { "\'$it\'" }

                                confirmText(name)
                            }
                            onClick("Submit selection") {
                                val selection = selectedItem() ?: throw IllegalStateException("Must have selected an item")

                                onSubmit(selection)

                                dialogScreenNavigator.dismiss()
                            }
                        }
                    }
                }
            }
        }
    }
}

class MultiselectFromItemsDialog(
    val options: List<AdjustedLineItem>,
    val header: String,
    val confirmText: (count: Int) -> String = { if (it == 0) "Select Items" else "Select ($it) Items" },
    val cancelText: String = "Go Back",
    val onSubmit: suspend (List<AdjustedLineItem>)->Unit
) : Screen, Validator() {
    val selectedItems = Property<Set<UUID>>(emptySet()).validate { it.isNotEmpty() }

    override fun ViewWriter.render() {
        dismissBackground {
            centered - dialog - stack {
                spacing = AppDimensions.fullIndent
                sizeConstraints(minWidth = 35.rem) - col {
                    spacing = AppDimensions.sectionIndent
                    title(header)

                    requiredField("Work Items") {
                        multiselect(
                            query = { query -> options.filter { it.name.contains(query) } },
                            pull = { id -> options.first { it._id == id } },
                            getId = { it._id },
                            toString = { it.name },
                            items = selectedItems,
                            hintText = "Select one or multiple items to be modified"
                        )
                    }

                    row {
                        secondaryButton - button {
                            specCenteredText(cancelText)
                            onClick("Cancel") {
                                dialogScreenNavigator.dismiss()
                            }
                        }
                        primaryButton - button {
                            ::enabled { allValid() }
                            specCenteredText { confirmText(selectedItems().count()) }
                            onClick("Submit selection") {
                                val ids = selectedItems()
                                val selection = options.filter { it._id in ids }

                                onSubmit(selection)

                                dialogScreenNavigator.dismiss()
                            }
                        }
                    }
                }
            }
        }
    }
}

//private fun ViewWriter.selectLineItem(
//    options: List<AdjustedLineItem>,
//    bind: Writable<UUID?>
//) {
//    col {
//        spacing = 0.5.rem
//        options.forEach { item ->
//            val select = bind.equalTo(item._id)
//            row {
//                centered - radioButton {
//                    checked bind select
//                }
//                centered - expanding - RemoveToggleSemantic.onNext - radioToggleButton {
//                    row {
//                        expanding - subTitle(item.name)
//                        stack {
//                            centered - row {
//                                spacing = 0.2.rem
//                                space(0.8.rem)
//                                centered - body {
//                                    align = Align.Center
//                                    content = item.price.renderDollars()
//                                }
//                                centered - priceArrowIcon { item.totalPriceChange }
//                            }
//                        }
//                    }
//                    checked bind select
//                }
//            }
//            greySeparator()
//        }
//    }
//}
//
//fun ViewWriter.selectMultipleLineItems(
//    options: List<AdjustedLineItem>,
//    bind: Writable<List<AdjustedLineItem>>
//) {
//    col {
//        spacing = 0.5.rem
//        options.forEach { item ->
//            val select: Writable<Boolean> = bind.contains(item)
//            row {
//                centered - checkbox {
//                    checked bind select
//                }
//                centered - expanding - RemoveToggleSemantic.onNext - toggleButton {
//                    row {
//                        expanding - subTitle(item.name)
//                        stack {
//                            centered - row {
//                                spacing = 0.2.rem
//                                space(0.8.rem)
//                                centered - body {
//                                    align = Align.Center
//                                    content = item.price.renderDollars()
//                                }
//                                centered - priceArrowIcon { item.totalPriceChange }
//                            }
//                        }
//                    }
//                    checked bind select
//                }
//            }
//            greySeparator()
//        }
//    }
//}
