package com.crowpay.views.components.project.work.payApps

import com.crowpay.*
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.*
import com.crowpay.utils.tables.trackWeights
import com.crowpay.views.components.leftExpandButton
import com.crowpay.views.components.scopeSetIcon
import com.crowpay.views.screens.common.ProjectLens
import com.crowpay.views.screens.common.ProjectView
import com.crowpay.views.components.sectionIndentCol
import com.crowpay.views.dialogs.GenericConfirmationDialog
import com.crowpay.views.dialogs.MessageType
import com.crowpay.views.dialogs.WaitDrawsDialog
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.datetime.Instant

fun ProjectView.payApps(writer: ViewWriter) = with(writer) {
    col {
        if (lens == ProjectLens.Contractor) atTopEnd - tertiaryButton - button {
            ::enabled {
                !project().state.isProjectLocked() && project().state != ProjectState.Accepted
            }
            specCenteredText {
                if (payApps().any { it.submitted == null }) "Edit Draft" else "+ Draw"
            }
            onClick("Create new Draw") {
                payApps().firstOrNull { it.submitted == null }?.let { screenNavigator.navigate(PayAppForm(it._id)) }
                    ?: screenNavigator.navigate(CreatePayApp(project()._id))
            }
        }

        text {
            existsDefaultFalse {
                project().state == ProjectState.WaitingApproval
            }
            content = "This info will be available once the project is Awarded or In Progress."
        }

        text {
            existsDefaultFalse {
                project().state == ProjectState.Accepted
                        && lens == ProjectLens.Contractor
            }
            content = "You can create Draws after the project is started."
        }

        text {
            existsDefaultFalse {
                project().state == ProjectState.Accepted && lens == ProjectLens.Customer
            }
            content = "When contractor has started and is ready to submit a draw they will appear here."
        }

        col {
            spacing = 0.px
            forEachUpdating(payApps) { payApp ->
                val paymentData = shared {
                    val id = payApp()._id
                    val allItems = payAppItems()
                    val thisPayAppItems = allItems.filter { it.payApp == id }
                    val firstReview = thisPayAppItems
                        .minOfOrNull { it.reviewedTimestamp ?: Instant.DISTANT_FUTURE }
                        ?: Instant.DISTANT_FUTURE

                    lineItems()
                        .mapNotNull { line ->
                            val associated = thisPayAppItems.find { it.lineItem == line._id }
                            val cutoff = associated?.submitted ?: firstReview
                            line
                                .excludeChangesAfter(cutoff)        // "Rewinds" the line item to the provided timestamp. If the line item didn't exist at that point then returns null
                                ?.let { rewound ->
                                    LineItemWithPaymentInfo(
                                        lineItem = rewound,
                                        payApp = associated,
                                        activePayApps = allItems.filter { pay ->
                                            pay._id != associated?._id && pay.lineItem == rewound._id && pay.voided == null &&
                                                    (associated?.submitted == null || pay.submitted.let { it != null && it < cutoff })
                                        }
                                    )
                                }
                        }
                        .sortedBy { it.lineItem.wraps }
                }

                renderPayApp2(
                    payApp,
                    paymentData,
                    this
                )
            }
        }
    }
}

private fun ProjectView.renderPayApp2(
    payApp: Readable<PayApplication>,
    items: Readable<List<LineItemWithPaymentInfo>>,
    writer: ViewWriter,
) = with(writer) {
    val expanded = Property(false)
    val status = shared {
        items().firstNotNullOfOrNull { it.payApp }?.status ?: PayAppStatus.Draft
    }
    val groupedItems = shared {
        val scopes = scopes().associateBy { it._id }
        items()
            .groupBy { it.lineItem.scopeView }
            .mapKeys { (id, _) -> scopes[id] }
            .toList()
            .sortedBy { it.first?._id }
    }

    col {
        spacing = 0.px
        row {
            spacing = 0.5.rem
            sizeConstraints(height = 3.5.rem) - expanding - leftExpandButton(expanded) {
                row {
                    spacing = 8.dp
                    centered - subTitle {
                        ::content { "Draw ${payApp().number}" }
                    }
                    centered - body {
                        ::content { status().displayName }
                        dynamicTheme { ForegroundSemantic(status().color) }
                    }
                }
            }
            stack {
                existsDefaultFalse { status() != PayAppStatus.Draft }
                centered - secondaryButton - button {
                    specCenteredText { "View Details" }
                    spacing = 5.dp
                    onClick {
                        dialogScreenNavigator.navigate(
                            PayAppDetails(
                                payApp(),
                                project(),
                                scopes(),
                                items()
                            )
                        )
                    }
                }
            }
            row {
                existsDefaultFalse { status() == PayAppStatus.Draft }
                centered - buttonTheme - link {
                    spacing = 0.25.rem
                    ::to {
                        { PayAppForm(payApp()._id) }
                    }
                    centered - icon(Icon.edit.resize(1.5.rem), "Edit Change Order")
                }
                compact - centered - deleteButton - button {
                    centered - icon(Icon.trash.resize(1.5.rem), "Delete")
                    onClick {
                        dialogScreenNavigator.navigate(
                            GenericConfirmationDialog(
                                header = "Delete Draft?",
                                message = "You are about to delete this draw draft. Deleted drafts cannot be recovered. Delete this draft?",
                                confirmationText = "Delete Draft",
                                messageType = MessageType.Danger,
                                onSubmit = { confirmed ->
                                    if (confirmed) {
                                        notNullSession().payApplications[payApp()._id].delete()
                                    }
                                }
                            )
                        )
                    }
                }
            }
        }
        onlyWhen { expanded() } - sectionIndentCol {
            trackWeights {
                val rowSpacing = 0.5.rem
                spacing = rowSpacing
                space()
                row {
                    fun ViewWriter.centeredHeader(text: String) = bodyBold { align = Align.Center; content = text }

                    // The mock-ups specify that the tables are different based on who's viewing them, so these are going to be really ugly and hard to read.
                    if (lens == ProjectLens.Contractor) {
                        setColWeight(3f) - atBottomCenter - bodyBold("Work Item")
                        setColWeight(1f) - atBottomCenter - centeredHeader("Work Completed")
                        setColWeight(1f) - atBottomCenter - centeredHeader("Retainage")
                        setColWeight(1f) - atBottomCenter - centeredHeader("Past Payments")
                        setColWeight(1f) - atBottomCenter - centeredHeader("This Draw")
                        setColWeight(1f) - atBottomCenter - centeredHeader("Percent Complete")
                    } else {
                        setColWeight(3f) - atBottomCenter - bodyBold("Work Item")
                        setColWeight(1f) - atBottomCenter - centeredHeader("Approved Amount")
                        setColWeight(1f) - atBottomCenter - row {
                            spacing = 5.dp
                            centered - bodyBold("Work Completed")
                            centered - smallBody {
                                ::content { "(${items().percentComplete()})%" }
                            }
                        }
                        setColWeight(1.1f) - atBottomCenter - centeredHeader("Retainage")
                        setColWeight(1f) - atBottomCenter - centeredHeader("Past Payments")
                        setColWeight(1f) - atBottomCenter - centeredHeader("Amount Due Request")
                    }
                }
                greySeparator()

                col {
                    spacing = rowSpacing

                    fun ViewWriter.money(calculation: ReactiveContext.() -> Long?) = body {
                        align = Align.Center
                        ::content { calculation()?.renderDollars() ?: "-" }
                    }

                    forEachUpdating(groupedItems) { pair ->
                        val scope = shared { pair().first }
                        val lines = shared { pair().second }

                        col {
                            ::exists { scope() != null }
                            space(0.25)
                            compact - lightSection - row {
                                body { ::content { scope()?.scopeTitle ?: "" } }
                                centered - scopeSetIcon(1.rem) {
                                    ::exists { scope()?.scopeSet == true }
                                }
                            }
                        }

                        col {
                            forEachUpdating(lines) { lineItemWithPayment ->
                                row {
                                    dynamicTheme {
                                        if (lineItemWithPayment().lineItem.cancelled) GreyedOutSemantic else null
                                    }

                                    getColWeight - row {
                                        spacing = 5.dp
                                        body {
                                            ::content { lineItemWithPayment().name }
                                        }
                                        centered - smallBody {
                                            ::content { lineItemWithPayment().lineItem.changeType?.postTense ?: "" }
                                        }
                                    }

                                    if (lens == ProjectLens.Contractor) {
                                        getColWeight - money { lineItemWithPayment().workCompleted }
                                        getColWeight - money { lineItemWithPayment().retentionToDate(project().safeRetention) }
                                        getColWeight - money { lineItemWithPayment().previousPayments }
                                        getColWeight - money { lineItemWithPayment().thisPayment }
                                        getColWeight - body {
                                            align = Align.Center
                                            ::content { "${lineItemWithPayment().percentComplete}%" }
                                        }
                                    } else {
                                        getColWeight - money { lineItemWithPayment().price }
                                        getColWeight - stack {
                                            centered - row {
                                                spacing = 5.dp
                                                centered - money { lineItemWithPayment().workCompleted }
                                                centered - smallBody {
                                                    ::content { "(${lineItemWithPayment().percentComplete}%)" }
                                                }
                                            }
                                        }
                                        getColWeight - money {
                                            lineItemWithPayment().retentionToDate(project().safeRetention)
                                        }
                                        getColWeight - money { lineItemWithPayment().previousPayments }
                                        getColWeight - money { lineItemWithPayment().thisPayment }
                                    }
                                }
                                greySeparator()
                            }
                        }
                    }
                }
                row {
                    fun ViewWriter.moneyBold(calculation: ReactiveContext.() -> Long) = bodyBold {
                        align = Align.Center
                        ::content { calculation().renderDollars() }
                    }

                    if (lens == ProjectLens.Contractor) {
                        getColWeight - bodyBold("Totals")
                        getColWeight - moneyBold { items().sumOf { it.workCompleted } }
                        getColWeight - moneyBold { items().retentionToDate(project().safeRetention) }
                        getColWeight - moneyBold { items().sumOf { it.previousPayments } }
                        getColWeight - moneyBold { items().sumOf { it.thisPayment ?: 0 } }
                        getColWeight - bodyBold {
                            align = Align.Center
                            ::content { "${items().percentComplete()}%" }
                        }
                    } else {
                        getColWeight - bodyBold("Totals")
                        getColWeight - moneyBold { items().sumOf { it.price } }
                        getColWeight - stack {
                            centered - row {
                                spacing = 5.dp
                                moneyBold { items().sumOf { it.workCompleted } }
                                smallBody {
                                    ::content { "${items().percentComplete()}%" }
                                }
                            }
                        }
                        getColWeight - moneyBold { items().retentionToDate(project().safeRetention) }
                        getColWeight - moneyBold { items().sumOf { it.previousPayments } }
                        getColWeight - moneyBold { items().sumOf { it.thisPayment ?: 0 } }
                    }
                }
            }

            launch {
                JumpTo.PayApp.newTarget(payApp()._id, this@sectionIndentCol) { expanded.value = true }
            }

            space()
            if (lens == ProjectLens.Contractor) atEnd - dangerButton - button {
                ::exists { status() == PayAppStatus.Pending }
                specCenteredText("Void")
                onClick("Void Pay App") {
                    dialogScreenNavigator.navigate(
                        GenericConfirmationDialog(
                            header = "Void Pay App",
                            message = "Are you sure you want to void this pay app? This cannot be undone.",
                            confirmationText = "Void",
                            messageType = MessageType.Danger,
                            onSubmit = { confirmed ->
                                if (confirmed) {
                                    val payItems = items()
                                    val session = notNullSession()
                                    val endpoints = session.nonCached.payAppItem
                                    coroutineScope {
                                        payItems
                                            .mapNotNull { it.payApp }
                                            .forEach {
                                                launch { endpoints.voidPayApp(it._id) }
                                            }
                                    }
                                    delay(100)
                                    session.payAppItems.totallyInvalidate()
                                }
                            }
                        )
                    )
                }
            }

            if (lens == ProjectLens.Customer) atEnd - row {
                primaryButton - button {
                    ::exists { status() == PayAppStatus.Pending }
                    specCenteredText("Pay Draw")
                    onClick("Pay Draw") {
                        val total = items().sumOf { it.thisPayment ?: 0 }.renderDollars()
                        dialogScreenNavigator.navigate(
                            GenericConfirmationDialog(
                                header = "Pay Draw",
                                message = "Pay $total out of escrow to contractor?",
                                confirmationText = "Pay $total",
                                onSubmit = { confirmed ->
                                    if (confirmed) {
                                        val session = notNullSession()
                                        val payItems = items().mapNotNull { it.payApp?._id }
                                        notNullSession().nonCached.payAppItem.approvePayApps(payItems)
                                        delay(100)
                                        session.payAppItems.totallyInvalidate()
                                        session.projects[projectId].invalidate()
                                    }
                                }
                            )
                        )
                    }
                }
                dangerButton - button {
                    ::exists { status() == PayAppStatus.Pending }
                    specCenteredText("Wait")
                    onClick("Wait Draw") {
                        dialogScreenNavigator.navigate(
                            WaitDrawsDialog(items())
                        )
                    }
                }
            }
            space(2.0)
        }
        greySeparator()
    }
}
