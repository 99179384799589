package com.crowpay.views.screens.common

import com.crowpay.*
import com.crowpay.sdk.notFoundError
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.AdjustedLineItem
import com.crowpay.utils.applyItemChanges
import com.lightningkite.UUID
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.condition
import com.lightningkite.lightningdb.eq
import com.lightningkite.lightningdb.sort

abstract class AuthorizedProjectView(
    lens: ProjectLens,
    override val projectId: UUID
) : ProjectView(lens) {
    private val _project = shared {
        notNullSession().projects[projectId]
    }
    override val project = shared {
        _project()() ?: notFoundError<Project>()
    }
    private val _lineItems = shared {
        notNullSession().lineItems
            .query(Query(
                condition = condition<LineItem> { it.project eq projectId },
                orderBy = sort {
                    it.order.ascending()
                    it.created.ascending()
                }
            ))
    }
    private val _itemChanges = shared {
        notNullSession().itemChanges
            .query(Query(limit = Int.MAX_VALUE) { it.project eq projectId })
    }
    override val lineItems = shared {
        val items = _lineItems()()
        val changes = _itemChanges()()

        items.map { item ->
            AdjustedLineItem(item, changes.filter { it.itemId == item._id })
        }
    }

    private val _contractorNotes = shared {
        notNullSession().contractorNotes.query(
            Query(
                condition { it.project eq projectId },
                orderBy = sort { it.at.ascending() },
                limit = Int.MAX_VALUE
            )
        )
    }
    override val contractorNotes = shared {
        _contractorNotes()()
    }
    private val _client = shared {
        project().client?.let { notNullSession().clients[it] }
    }
    override val client = shared {
        _client()?.invoke() ?: Client(
            contractor = UUID.random(),
            name = "",
            email = "",
            phoneNumber = "",
            address = Address()
        )
    }
    private val _contractor = shared {
        notNullSession().contractors[project().contractor]
    }
    override val contractor = shared {
        _contractor()() ?: Contractor(
            name = "",
            email = "",
            phoneNumber = "",
            address = Address(),
            contactFirstName = "",
            contactLastName = "",
            contactEmail = "",
            contactPhoneNumber = "",
            contactAddress = Address(),
            stateEntityNumber = "",
            ein = "",
        )
    }
    private val _punchListItems = shared {
        notNullSession().punchLists.query(
            Query(
                condition { it.project eq projectId },
                limit = Int.MAX_VALUE
            )
        )
    }
    override val punchListItems = shared {
        _punchListItems()()
    }
    private val _changeOrders = shared {
        notNullSession().changeRequests
            .query(
                Query(
                    condition { it.project eq projectId },
                    sort { it.number.ascending() },
                    limit = Int.MAX_VALUE
                )
            )
    }
    override val changeOrders = shared {
        _changeOrders()()
    }
    private val _disputes = shared {
        notNullSession().disputes
            .query(
                Query(
                    condition { it.project eq projectId },
                    limit = Int.MAX_VALUE
                )
            )
    }
    override val disputes = shared {
        _disputes()()
    }
    private val _scopes = shared {
        notNullSession().scopeViews
            .query(
                Query(
                    condition { it.project eq projectId },
                    orderBy = sort {
                        it.scopeTitle.ascending()
                    },
                    limit = Int.MAX_VALUE
                )
            )
    }
    override val scopes = shared {
        _scopes()()
    }
    private val _pendingItemChanges = shared {
        notNullSession().pendingItemChange
            .query(
                Query(limit = Int.MAX_VALUE) { (it.project eq projectId) }
            )
    }
    override val pendingItemChanges = shared {
        _pendingItemChanges()()
    }
    private val _pendingLineItems = shared {
        notNullSession().pendingLineItems
            .query(
                Query(limit = Int.MAX_VALUE) { it.project eq projectId }
            )
    }
    override val pendingLineItems = shared {
        _pendingLineItems()()
    }
    private val _payApps = shared {
        notNullSession().payApplications.query(
            Query(
                condition { it.project eq projectId },
                orderBy = sort { it.number.ascending() },
                limit = Int.MAX_VALUE
            )
        )
    }
    override val payApps = shared {
        _payApps()()
    }
    private val _payAppItems = shared {
        notNullSession().payAppItems.query(
            Query(
                condition { it.project eq projectId },
                orderBy = sort {
                    it.amount.descending()
                    it._id.ascending()
                },
                limit = Int.MAX_VALUE
            )
        )
    }
    override val payAppItems = shared {
        _payAppItems()()
    }
    private val _lineItemCompletionMessages = shared {
        notNullSession().lineItemCompletionMessages.query(
            Query(
                limit = Int.MAX_VALUE,
                condition = condition { it.project eq projectId },
                orderBy = sort { it.created.ascending() }
            )
        )
    }
    override val lineItemCompletionMessages = shared {
        _lineItemCompletionMessages()()
    }
    private val _incidents = shared {
        notNullSession().incidents.query(
            Query(
                limit = Int.MAX_VALUE,
                condition = condition { it.project eq projectId },
                orderBy = sort { it.state.ascending() }
            )
        )
    }
    override val incidents = shared {
        _incidents()()
    }
    private val _incidentMessages = shared {
        notNullSession().incidentMessages.query(
            Query(
                limit = Int.MAX_VALUE,
                condition = condition { it.project eq projectId },
                orderBy = sort { it.created.ascending() }
            )
        )
    }
    override val incidentMessages = shared {
        _incidentMessages()()
    }
}