package com.crowpay.views.dialogs

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.AdjustedLineItem
import com.crowpay.utils.validation.Validator
import com.crowpay.views.components.files.FileData
import com.crowpay.views.components.files.fileManager
import com.crowpay.views.components.label2
import com.crowpay.views.components.files.resolveProjectAttachments
import com.crowpay.views.components.requiredField
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.ThemeDerivation
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*

private fun ViewWriter.selectLineItem(
    edits: Writable<AdjustedLineItem?>,
    options: List<AdjustedLineItem>
) =
    requiredField("Line Item") {
        select {
            bind(
                edits = edits,
                Constant( listOf(null) + options ),
                render = { it?.name ?: "Select a line item" }
            )
        }
    }


private fun ViewWriter.dialogStack(content: Stack.()->ViewModifiable) =
    dismissBackground {
        dialog - centered - stack {
            spacing = AppDimensions.fullIndent
            col {
                themeChoice = ThemeDerivation(appTheme)
                content()
            }
        }
    }

class RootCreateItemNote(val lineItems: List<AdjustedLineItem>) : Page, Validator() {
    val message = Property("")
    val files = Property(emptyList<FileData>())
    val selectedLine = Property<AdjustedLineItem?>(null)

    override fun ViewWriter.render2(): ViewModifiable {
        return dialogStack {
            col {
                title("New Note")

                selectLineItem(selectedLine, lineItems)

                col {
                    label2("Message") {
                        sizeConstraints(width = 40.rem, minHeight = 10.rem) - fieldTheme - textArea {
                            content bind message
                        }
                    }

                    label2("Files") {
                        sizeConstraints(width = 40.rem) - fieldTheme - fileManager(
                            files,
                            "No Files Uploaded"
                        ) { notNullSession().nonCached.lineItem::uploadFileForRequest }
                    }

                    row {
                        spacing = AppDimensions.buttonRowSpacing
                        primaryButton - button {
                            ::enabled { selectedLine() != null && (message().isNotBlank() || files().isNotEmpty()) }

                            specCenteredText("Create Note")

                            onClick {
                                val item = selectedLine() ?: return@onClick
                                val m = message()
                                val f = files().resolveProjectAttachments()

                                notNullSession().contractorNotes.insert(
                                    ContractorNote(
                                        lineItem = item._id,
                                        project = item.wraps.project,
                                        message = m,
                                        files = f
                                    )
                                )

                                dialogScreenNavigator.dismiss()
                            }
                        }
                        textButton - button {
                            specCenteredText("Go Back")
                            onClick { dialogScreenNavigator.dismiss() }
                        }
                    }
                }
            }
        }
    }
}

class RootCreateItemTask(val lineItems: List<AdjustedLineItem>) : Page, Validator() {
    val name = Property("").validate { it.isNotBlank() }
    val required = Property(false)
    val selectedLine = Property<AdjustedLineItem?>(null)

    override fun ViewWriter.render2(): ViewModifiable {
            return dialogStack {
                sizeConstraints(width = 30.rem) - col {
                    spacing = AppDimensions.sectionIndent
                    titleWithClose("New Task") {
                        dialogScreenNavigator.clear()
                    }

                    requiredField("Destination") {
                        select {
                            bind(
                                edits = selectedLine,
                                Constant(listOf(null) + lineItems),
                                render = { it?.name ?: "Punch Tasks" }
                            )
                        }
                    }

                    col {
                        spacing = 1.rem

                        requiredField("Name") {
                            textInput {
                                hint = "Name"
                                content bind name
                            }
                        }

                        row {
                            centered - checkbox {
                                checked bind required
                            }
                            centered - body("Required")
                        }
                    }

                    row {
                        spacing = AppDimensions.buttonRowSpacing
                        primaryButton - button {
                            ::enabled { allValid() }
                            specCenteredText("Save Task")

                            onClick {
                                val punchItem = selectedLine().let {
                                    PunchListItem(
                                        lineItem = it?._id,
                                        project = it?.wraps?.project ?: lineItems.first().project,
                                        content = name(),
                                        required = required()
                                    )
                                }

                                notNullSession().punchLists.insert(punchItem)

                                screenNavigator.dismiss()
                            }
                        }
                        textButton - button {
                            specCenteredText("Go Back")
                            onClick { dialogScreenNavigator.clear() }
                        }
                    }
                }
            }
    }
}
